// .singleCategoryPlaybooks {
//   background-color: white !important;
//   width: 100%;
//   padding: 8px;
//   .singleCategoryPlaybooks .ant-col:nth-child(1),
//   .singleCategoryPlaybooks .ant-col:nth-child(2) {
//     padding-right: 0px !important;
//     padding-left: 0px !important;
//   }
//   &__proposalsList {
//     padding: 0px;
//   }
//   &__header {
//     background-color: white !important;
//     &__header {
//       width: 100%;
//     }

//     &__text {
//       color: #0f0f60;
//       font-size: 14px;
//       font-weight: bold;
//       margin-top: 8px !important;
//       margin-left: 16px !important;
//       margin-bottom: 0px !important;
//       display: flex;
//       justify-content: space-between;
//     }
//     &__divider {
//       height: 1px !important;
//       background-color: #a7b3d9 !important;
//       width: calc(100%) !important;
//       margin: 8px 0px 0px 0px !important;
//     }
//   }

//   &__textViewAll {
//     color: #07074b;
//     font-size: 14px !important;
//     margin-right: 16px !important;
//     margin-bottom: 0px !important;
//     cursor: pointer;
//   }
//   &__list {
//     margin-left: 16px;
//     margin-right: 16px;
//     width: 100% !important;
//     background-color: white;
//   }

//   &__textViewAll:hover {
//     text-decoration: underline;
//     cursor: pointer;
//   }
//   .Col-1 {
//     padding-left: 0px !important;
//   }
// }

// @media (max-width: 800px) {
//   .singleCategoryPlaybooks {
//     width: unset !important;
//     height: unset !important;
//   }
//   .ant-col-xs-24 {
//     margin-left: -10px !important;
//   }
// }

.singleCategoryPlaybooks {
  &__list {
    margin-left: 16px;
    margin-right: 16px;
    width: 100% !important;
    background-color: white;

    .ant-list-items {
      display: flex !important;
      flex-wrap: wrap !important;
    }
    .ant-list-items .singleCategoryPlaybooks__datas {
      width: 33% !important;
    }
  }
  &__textViewAll {
    color: #07074b;
    font-size: 14px !important;
    margin-right: 6px !important;
    margin-bottom: 0px !important;
    cursor: pointer;
  }

  &__header {
    background-color: white !important;
    &__header {
      width: 100%;
      // position: sticky;
      // top: 60px;
      // z-index: 9;
      padding-top: 1px;
      background-color: white;
    }

    &__text {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
      margin-top: 8px !important;
      margin-left: 16px !important;
      margin-bottom: 0px !important;
      display: flex;
      justify-content: space-between;
      border: 1px solid #eff1fb;
      background-color: #eff1fb;
      margin-right: 16px;
      padding: 8px 0px 8px 8px;
    }
    &__divider {
      height: 1px !important;
      background-color: #a7b3d9 !important;
      width: calc(100%) !important;
      margin: 8px 0px 0px 0px !important;
    }
  }
}

@media (max-width: 650px) {
  .singleCategoryPlaybooks {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .singleCategoryPlaybooks__datas {
        width: 100% !important;
      }
    }
  }
}

// &__header {
//   &__header {
//     width: 98%;
//     margin-left: 9px;
//   }
