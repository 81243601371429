.addPackage {
  &__heading {
    color: #0f0f60;
    font-size: 12px;
    margin-left: 4px !important;
    font-weight: bold;
  }

  &__mainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #a7b3d9;
    border-radius: 4px;
    // margin-left: 200px;
    // margin-right: 200px;

    &__labelColumn {
      flex: 25%;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      &__head {
        font-size: 12px;
        padding-left: 24px;
        padding-top: 24px;
        padding-right: 24px;
        color: #a7b3d9;
        margin: 0px !important;
      }
      &__subHeading {
        font-size: 10px;
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
        color: #a7b3d9;
      }
    }

    &__selectedValueColumn {
      flex: 25%;
      padding: 8px;
      background-color: #ffffff;

      // background-color: #eff2fc;
      // border: 1px solid #a7b3d9;
      // box-shadow: 0px 2px 2px 2px #f0dcdc;
      // border-radius: 4px;
    }

    &__unSelectedValueColumn {
      flex: 25%;
      padding: 8px;
      background-color: #ffffff;
    }

    &__heading {
      color: #0f0f60;
      font-size: 12px;
      margin-top: 16px;
      font-weight: bold;
    }
    &__priceContainer {
      display: flex;
      flex-direction: row;
    }
    &__input1 {
      width: 100% !important;
      border: 1px solid #a7b3d9 !important;
      border-radius: 4px !important;
      font-family: Lato !important;
      color: #0f0f60;
      padding: 4px 8px 4px 8px !important;
      font-size: 12px !important;
    }
  }

  &__list {
    overflow-y: scroll !important;
    width: 100% !important;

    &__evenRow {
      background-color: #ffffff;
    }
    &__oddRow {
      background-color: #eff2fc;
    }

    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      &__rowDivision {
        flex: 25%;
        align-items: center;
        font-size: 12px;
        color: #0f0f60;
        padding: 12px;
        display: flex;
        flex-direction: column;

        &__tick {
          font-size: 20px !important;
          cursor: pointer;
          justify-content: center !important;
          font-weight: bold !important;
        }
        &__unSelected {
          font-size: 20px !important;
          color: #a7b3d9 !important;
          cursor: pointer;
          font-weight: bold !important;
        }
      }
    }
  }
}

.inputAddOnAfter {
  .ant-input-number-group-addon {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 0px !important;
    background-color: white !important;
    padding-bottom: 0px !important;
  }
}
