.playbooks {
  padding-bottom: 12px;
  .ant-list-items {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .ant-list-items .playbooks__datas {
    width: 33% !important;
  }
  &__header {
    margin-left: 16px;
    margin-right: 16px;

    &__textAndButton {
      padding-top: 16px;
      display: flex;
      justify-content: space-between;

      &__heading {
        color: #0f0f60;
        font-size: 16px;
        font-weight: bold;
      }

      &__button {
        background-color: #0f0f60 !important;
        font-size: 13px !important;
        align-items: end !important;
        color: white;
        border-radius: 4px !important;
        border: none !important;
        margin: 0 !important;
        height: 32px !important;
      }
    }

    &__divider {
      height: 1px !important;
      background: #a7b3d9 !important;
      margin: 0px !important;
    }
  }

  &__list {
    margin-left: 16px;
    margin-right: 16px;
    &__listItem {
      margin: 4px 4px 4px 4px !important;
      padding: 8px 0px 4px 0px !important;
      border-radius: 4px !important;
    }
  }
}

@media (max-width: 650px) {
  .playbooks {
      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .playbooks__datas {
        width: 100% !important;
      }
  }
}
