.proposalDetails {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: 100%;

  &__proposalChatMobileDiv{
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__leftdivProposalDetails {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100% !important;
    flex-direction: column;
    background-color: #eff2fc;
    padding-top: 7px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    
  }

  &__iconProposalChatMobile{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 90px;
    right: 30px;
    cursor: pointer;
  }

  &__rightdivProposalDetailsParent {
    display: flex;
    flex-direction: column;
    width: 234px !important;
    height: 100%;
    border-left: 1px solid #EFF2FC;
    float: right;
  }

  &__userImageInfoHeaderDiv {
    display: flex;
    min-height: 60px !important;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid #a7b3d9;
    align-items: center;
    justify-content: flex-start;
  }

  &__userImgRigtChatNav {
    width: 35px !important;
    height: 35px !important;
    margin-left: 14px !important;
    display: flex;
    align-items: center;
  }

  &__nameDescDiv {
    display: flex;
    flex-direction: column;
    height: 100% !important;
    margin-left: 14px !important;
  }

  &__textSellerName {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 15px !important;
    margin-top: 12px !important;
    max-lines: 1;
    color: #0f0f60 !important;
  }

  &__textSellerJobDesc {
    font-size: 11px !important;
    line-height: 12px !important;
    font-weight: 500 !important;
    margin-top: 1px !important;
    padding-right: 10px !important;
    max-lines: 1;
    color: #a7b3d9 !important;
  }

  &__closeChatBtn {
    width: 24px !important;
    height: 24px !important;
    padding: 7px !important;
    cursor: pointer;
  }

  &__rightdivProposalDetails {
    display: flex;
    flex-direction: column;
    width: 234px !important;
    height: calc(100% - 60px) !important;
    border-left: 0px solid #EFF2FC;
    float: right;
  }

  &__userImageInfoHeaderDiv {
    display: flex;
    min-height: 60px !important;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid #a7b3d9;
    align-items: center;
    justify-content: flex-start;
  }

  &__rightdivProposalDetailsLoadingChat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 234px !important;
    height: calc(100% - 24px);
    border-left: 1px solid #EFF2FC;
    color: #0f0f60;
    font-size: 14px !important;
  }

  &__rightdivProposalDetailsLoadingChatMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: calc(100% - 24px);
    border-left: 1px solid #EFF2FC;
    color: #0f0f60;
    font-size: 14px !important;
  }

  &__rightdivProposalDetailsClosed {
    display: flex;
    flex-direction: column;
    width: 75px !important;
    height: 100%;
    background-color: #0f0f60;
    justify-content: flex-end;
    align-items: center;
  }

  &__rightNavChatIcon{
    display: flex;
    width: 45px !important;
    height: 45px !important;
    margin-bottom: 24px;
    cursor: pointer;
  }

  &__leftdivContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100% !important;
    flex-direction: column;
    background-color: #eff2fc;
  }

  &__headerDiv {
    display: flex;
    flex-direction: row;
    min-height: 60px !important;
    border-bottom: 1px solid #a7b3d9;
    align-items: center;
    justify-content: space-between;
  }

  &__headerText {
    display: flex;
    color: #0f0f60;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: bold;
  }

  &__contentContainer {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 60px);
    margin-top: 12px;
    background-color: white;
    border-radius: 8px;
    overflow-y: auto;
    padding: 0px 24px 24px 24px;
  }

  &__carouselContainer {
    width: 100% !important;
  }
  &__centerAlign {
    display: block !important;
    margin-left: auto !important;
    height: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    align-items: center !important;
  }
  &__image {
    margin-top: 16px;
    width: 10%;
    align-items: flex-center !important;
    justify-content: center !important;
  }
  &__playbookTitle {
    margin-top: 8px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px;
  }
  &__welcomeText {
    margin-top: 8px;
    color: #0f0f60;
    font-size: 14px;
  }
  &__positiveBtn {
    align-items: flex-center;
    margin-top: 16px !important;
    width: 160px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    visibility: hidden;
    background-color: #84d830 !important;
  }

  &__aboutHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  &__belowCarouselContent {
    margin-left: 20%;
    margin-right: 20%;
  }
  &__highLights {
    &__text {
      font-size: 14px !important;
      color: #0f0f60;
      margin-left: 16px !important;
    }
  }

  &__description {
    margin-top: 8px;
    font-size: 14px;
    color: #0f0f60;
  }

  &__reviewsInfo {
    color: #fe943b !important;
    font-size: 12px !important;
    font-weight: bold !important;
    &__count {
      color: #a7b3d9 !important;
      font-size: 12px !important;
    }
  }

  &__languageCategory {
    display: flex !important;

    &__heading {
      width: 40%;
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold !important;
      margin-bottom: 0px !important;
    }
    &__value {
      width: 40%;
      font-size: 12px;
      color: #0f0f60;
    }
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  &__comparePackage {
    color: #0f0f60 !important;
    margin-top: 32px;
    font-weight: bold !important;
    font-size: 12px !important;
  }
  .ant-carousel {
    width: 600px !important;
    min-height: 0;
    min-width: 0;
    margin: 0 auto !important;
    margin-top: 8px !important;
  }

  &__customAvatar {
    &__avatar {
      height: 55px !important;
      width: 55px !important;
      font-size: 28px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
}
