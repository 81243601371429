.profile-explorer {
  padding: 20px;
  background-color: #fff;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: -10px 0;
    margin-top: -25px !important;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    &__input {
      width: 300px !important;
      height: 35px !important;
      margin-right: -2px !important;
      border-radius: 16px !important;
      background-color: #f2f2f2 !important;

      .ant-input {
        border-radius: 16px !important;
        background-color: #f2f2f2 !important;
        color: #000000 !important;
      }

      .ant-input-prefix {
        margin-right: 8px !important;
        margin-top: 4px !important;
      }
      .ant-input-placeholder {
        color: #6b7a7e !important;
      }
    }
  }

  &__content {
    margin-top: 10px;

    .ant-table-thead > tr > th {
      background-color: #f5f5f5;
      font-weight: bold;
      border-bottom: none;
    }

    .ant-table-tbody > tr > td {
      padding: 16px;
      font-size: 14px;
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &__avatar {
      margin-right: 8px !important;
    }
  }
}
