.MobileEditNavbar {
    display: flex;
    flex: 1;
    width: 100%;
    height: 85%;
    background-color: white;
    flex-direction: column;
    overflow-y: scroll;
}

.MobilemainHeading {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    margin: 2% 0px 0px 0%;
}

.MobileheaderOptions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.MobiletitleDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 6px 24px 6px 24px;
    background-color: #e6e8f1;
    padding: 8px 18px 8px 18px;
}

.Mobilespan1 {
    font-weight: bold;
}

.MobileiconBellEye {
    font-weight: bold;
    margin-left: 16%;
    height: 22px !important;
}

.MobileiconBellEdit {
    font-weight: bold;
    height: 22px !important;
}

.MobileWidth {
    width: 38%;
}

.MobileSectionIcon {
    margin-right: 5%;
    position: relative;
    bottom: 2px;
}

.MobiledivFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 4%;
    margin-left: 6%;
    margin-right: 13%;
}

.MobiledivFlex2 {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 4%;
    margin-left: 6%;
    margin-right: 13%;
}

.MobilemainText {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #0f0f60;
    width: 80%;
}

.MobilesubText {
    font-size: 13px !important;
    line-height: 17px !important;
    color: #0f0f60;
    margin-left: 0.5rem;
    width: 49.5%;
}

.MobiledivBorder {
    padding-bottom: 5% !important;
    padding-right: 44% !important;
    margin-left: 8%;
    border-bottom: 1px solid #e6e8f1;
}

.MobileiconText {
    width: 53%;
}

.MobilelabelFlex {
    display: flex;
}

.MobilelabelFlex2 {
    display: flex;
    margin-left: 15%;
}

.MobileinputSpace {
    padding: 4px;
    height: 30px;
    border: 1px solid #a7b3d9 !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
}

.MobileeyeIcon {
    margin-top: 6px;
    font-size: 20px;
}

.MobilebuttonsDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 6%;
}

.MobilesaveChangesButton {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 36px !important;
    width: 100px;
    border-radius: 16px !important;
    background-color: #56a5ff !important;
    color: white;
    border: 0px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
}