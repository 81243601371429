.firstColumn {
  &__mainContainer {
    padding-left: 16px !important;
    padding-right: 16px !important;
    width: 100% !important;
  }
  &__heading1 {
    font-size: 12px !important;
    padding-top: 16px !important;
    color: #a7b3d9 !important;
    margin: 0px !important;
  }
  &__subHeading {
    font-size: 10px;
    color: #a7b3d9;
  }
}
