.Google {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20px;
  margin-bottom: 10px;
  // height: 100vh !important;
  border: 1px solid #dcdee3;
  padding: 0px 10% 18% 10%;

  &__Grey {
    margin: 20% 0% 20% 0%;
    border: 20px solid #f9f9f9;
    &__Box {
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      padding: 16px;
    }
  }
  &__Content {
    display: flex;

    &__Para-1 {
      font-weight: bold;
      margin-right: 16px;
      margin-bottom: 0px;
    }
    &__Para-2 {
      margin-bottom: 0px;
      position: absolute;
      top: 158px;
      left: 280px;
      font-size: 20px;
    }
    &__Para-3 {
      margin-right: 2px;
      margin-bottom: 0px;
    }
    &__Para-4 {
      margin-top: 0px;
      color: #6c6b6b;
      margin-bottom: 0px;
      font-size: 12px;
    }
  }
  &__Content-1 {
    font-size: 18px;
    color: rgb(43, 43, 201);
  }
  &__Content-2 {
    color: #6c6b6b;
  }
  &__Create {
    position: absolute !important;
    right: 10%;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px;
    color: white !important;
    border: none !important;
  }
}

// _______________________________________________________________

.GoogleViewbutton {
  // margin-left: 22%;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px;
  padding-top: 20px;
  &__DesktopButton {
    border: none !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__DesktopButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__DesktopButton:hover {
    color: white !important;
  }
  &__MobileButton {
    border: none !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__MobileButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__MobileButton:hover {
    color: white !important;
  }
}

// _______________________________________________________________

.GoogleMobile {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 20px;
  margin-bottom: 10px;
  // height: 100vh !important;
  border: 1px solid #dcdee3;
  padding: 0px 20px 18% 20px;

  &__Grey {
    margin: 20% 0% 20% 0%;
    border: 20px solid #f9f9f9;
    &__Box {
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      padding: 16px;
    }
  }
  &__Content {
    display: flex;

    &__Para-1 {
      font-weight: bold;
      margin-right: 16px;
      margin-bottom: 0px;
    }
    &__Para-2 {
      margin-bottom: 0px;
      position: absolute;
      top: 107px;
      left: 411px;
      font-size: 20px;
    }
    &__Para-3 {
      margin-right: 2px;
      margin-bottom: 0px;
    }
    &__Para-4 {
      margin-top: 0px;
      color: #6c6b6b;
      margin-bottom: 0px;
      font-size: 12px;
    }
  }
  &__Content-1 {
    font-size: 18px;
    color: rgb(43, 43, 201);
  }
  &__Content-2 {
    color: #6c6b6b;
  }
  &__Create {
    position: absolute !important;
    right: 16%;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px;
    color: white !important;
    border: none !important;
  }
}
