.appHomeButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #07074b;
  background-color: white;
  padding: 0px 16px 0px 11px;
  min-height: 60px !important;
  max-height: 60px !important;
  min-width: 233px !important;
  max-width: 233px !important;
  border-bottom-right-radius: 8px !important;
  width: 233px !important;
}

.appHomeButtonMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: 0px 16px 0px 26px;
  min-height: 83px !important;
  max-height: 83px !important;
}

.appAvatar {
  width: 34px !important;
  height: 34px !important;
  min-width: 34px !important;
  min-height: 34px !important;
  max-width: 34px !important;
  max-height: 34px !important;
  margin-right: 14px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.appAvatarMobile {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  max-width: 40px !important;
  max-height: 40px !important;
  margin-left: 36px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.appHomeHeadContainer {
  display: flex;
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  min-width: 130px !important;
  max-width: 130px !important;
  width: 130px !important;
}

.appHomeHeadContainerMobile {
  display: flex;
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 11px;
}

.appHomeMainHead {
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width: 130px !important;
  max-width: 130px !important;
  width: 130px !important;
}

.appHomeMainHeadMobile {
  font-weight: bold;
  color: #07074b;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px !important;
}

.appHomeSubHead {
  font-weight: bold;
  font-size: 12px;
  color: #07074b;
  line-height: 15px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-top: 4px !important;
  min-width: 130px !important;
  max-width: 130px !important;
  width: 130px !important;
}

.appHomeSubHeadMobile {
  font-size: 12px;
  color: #07074b;
  line-height: 15px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px !important;
  margin-top: 6px;
}

.appSettingIcon {
  font-size: 16px;
  cursor: pointer;
  margin-left: 16px;
  color: #070748 !important;
}

.appCloseLeftNavIcon {
  font-size: 16px;
  cursor: pointer;
  margin-right: 16px;
  color: #07074b !important;
}
