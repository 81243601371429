.settingsPage {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  &__normalText {
    height: 32px;
    margin: 16px;

    color: white;
  }
  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__contentMobile {
    min-height: 100%;
    height: 100%;
    overflow-y: scroll;
    background: white;
  }

  &__content {
    min-height: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  &__rightNav {
    background-color: #0f0f60;
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }

  // .ant-layout-header {
  //   background: white;
  // }
  // .ant-menu-item-selected {
  //   background-color: #080748 !important;
  // }

  // .ant-menu > .ant-menu-item:hover,
  // .ant-menu > .ant-menu-submenu:hover,
  // .ant-menu > .ant-menu-item-active,
  // .ant-menu > .ant-menu-submenu-active,
  // .ant-menu > .ant-menu-item-open,
  // .ant-menu > .ant-menu-submenu-open,
  // .ant-menu > .ant-menu-item-selected,
  // .ant-menu > .ant-menu-submenu-selected {
  //   color: white;
  //   border-bottom: 1px solid white;
  // }

  &__menu {
    background: #100f5c;
  }
}

.app-header {
  height: 84px !important;
  background-color: #eff2fc !important;
  font-size: 18px !important;
  color: #0f0f60 !important;
}
