.PrivSinglePlaybook {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 18px;
  padding-right: 8px;
  width: 100%;
  // .ant-card .ant-card-bordered{
  //   box-shadow: 1px 1px 8px 1px grey;
  // }

  &__IMG1 {
    border-radius: 8px;
    width: 100% !important;
    border: 1px solid #a9b3d6;
  }
  &__IMG1:hover {
    cursor: pointer;
  }
  &__flex {
    display: flex;
    margin-bottom: 6px;
  }
  &__P3 {
    padding-left: 16px !important;
    // margin-bottom: 18px !important;
    font-size: 12px !important;
    color: #646c86;
  }
  &__P4 {
    padding-left: 16px !important;
    // margin-bottom: 18px !important;
    font-size: 12px !important;
    color: #646c86;
  }
  &__P5 {
    padding-left: 16px !important;
    // margin-bottom: 18px !important;
    font-size: 12px !important;
    color: #646c86;
  }
  &__appHomeButton {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 18px 16px 10px 16px;
  }
  &__appAvatar {
    width: 60px !important;
    height: 60px !important;
    margin-right: 14px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &__appHomeMainHead {
    font-weight: bold;
    font-size: 18px;
    margin-left: 16px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #07074b;
    margin-right: 10px;
    margin-top: 0px;
    width: 210px;
  }
  &__appHomeMainHead:hover {
    cursor: pointer;
  }

  &__appHomeSubHead {
    font-size: 14px;
    color: #a7b3d9;
    margin-left: 16px;
    line-height: 15px;
    font-size: 14px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 206px;
  }
  &__appHomeSubHead:hover {
    cursor: pointer;
  }

  &__appDotsIcon {
    font-size: 38px;
    cursor: pointer;
    font-weight: bold;
    color: #a29898 !important;
    margin-top: 22px;
  }

  &__P1 {
    padding-left: 17px !important;
    margin-bottom: 18px !important;
    font-size: 15px !important;
    color: #a7b3d9;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 1.2;
    min-height: 35px;
  }
  &__P1:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &__P2 {
    padding-left: 16px !important;
    margin-top: 0px !important;
    padding-top: 4px;
    font-size: 16px !important;
    font-weight: bold;
    color: #07074b;
    background-color: #eff1fb;
    margin-bottom: 0px !important;
    display: flex;
    //   background-color: #eff1fb;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height:45px;
    img {
      margin-right: 8px;
      margin-bottom: 2px;
      vertical-align: middle;
    }
    &__Low {
      margin-left: auto !important;
      margin-right: 12px !important;
      // display: flex;
      margin-bottom: 3px;
      margin-top: 4px;
      font-size: 16px;
      font-weight: bold;
    }
    &__Low:hover {
      transform: scale(1.1); /* Increase the scale for zoom effect */
      // font-weight: bold; /* Make the text bold */
      transition: transform 0.3s ease, font-weight 0.3s ease; /* Add a smooth transition */
    }
    &__Low span{
      // margin-left: auto !important;
      // margin-right: 12px !important;
      // display: flex;
      // margin-bottom: 6px;
      // margin-top: 8px;
      font-size: 18px;
    }
    
  }
  &__H1 {
    margin-left: 16px !important;
    margin-top: 6px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    color: #07074b;
    margin-bottom: 4px !important;
    margin-right: 2px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__H1:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .ant-card {
    width: 100%;
    border-radius: 8px;
    .ant-card-bordered {
      border-radius: 8px !important;

      .ant-card-hoverable {
      }
    }
  }

  .singleCategoryPlaybooks {
    margin: 0px auto !important;
    padding: 0px !important;
  }
  .ant-card-body {
    padding: 0px !important;
    border-radius: 8px !important;
    box-shadow: 0.5px 0.5px 4px 1px rgb(209, 206, 206);
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 0px !important;
  }

  &__customAvatar {
    &__avatar {
      height: 45px !important;
      width: 45px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
}
@media (max-width: 425px) {
  .PrivSinglePlaybook {
    width: 100%;
    .ant-row {
      flex-direction: column !important;
      row-gap: 30 !important;
    }
    .ant-card-cover img {
      height: 400px !important;
      width: 100% !important;
    }
    .ant-card {
      width: 100% !important;
    }
    &__appDotsIcon {
      // padding-left: 135px;
      position: absolute;
      bottom: 20px;
      left: 340px;
    }
  }
}
@media (max-width: 375px) {
  .PrivSinglePlaybook {
    width: 100%;
    &__appDotsIcon {
      // padding-left: 26% !important;
      position: absolute;
      bottom: 20px;
      left: 300px;
    }
  }
}
@media (max-width: 325px) {
  .PrivSinglePlaybook {
    width: 100%;
    margin-right: -48px;
    &__appDotsIcon {
      // padding-left: 15% !important;
      position: absolute;
      border: 20px;
      left: 258px;
    }
  }
}
