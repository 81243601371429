.signupInfoPage {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: calc(100% - 180px);
  margin-top: 0px !important;

  &__container {
    height: 100%;
  }

   &__namesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
 
   }

  &__signinFields {
    width: 273px;
  }

  &--withoutShadow {
    box-shadow: unset;
    padding: unset;
  }

  &__headerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-right: 15px;
  }
  &__logo {
    display: flex;
    justify-content: center;
    width: 80px;
    height: 80px;
  }

  &__headerTitle {
    display: inline-block;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: bold !important;
    text-align: center !important;
    justify-content: center !important;
    color: #000000 !important;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  &__footerText {
    font-weight: bold;
    color: white;
    background: white;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    font-weight: normal;
    justify-content: flex-end;
    width: 100vw;
    background-color: white !important;
    margin-top: 63px;
  }
  &__namesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 8px;
  }

  &__inputField {
    height: 32px;
    min-width: 273px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    border: 1px solid #E4E4E4; /* Add border with primary color */
    border-radius: 8px !important; /* Adjust as needed for rounded corners */

    input{
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    border: 1px solid #E4E4E4; /* Add border with primary color */
    }
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
  }
  &__googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
  }

  &__continueButton {
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #3872CD !important;
    color: white;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    border: 0px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__headerTitle {
    display: flex;
    font-size: 24px !important;
    line-height: 36px !important;
    font-weight: 400 !important;
    letter-spacing: -1.62px !important;
    text-align: center;
    justify-content: center;
    color: #000000 !important;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  &__button {
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    min-width: 128px;
    margin-right: 43px !important;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: white !important;
    border: 1px solid #3872CD !important;
    color: #3872CD !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }

  &__linkButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
  }
  &__googleButton {
    cursor: pointer;
  }
  &__termsConditionDesc {
    color: #000000 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 20px;
  }

  &__termsConditionDescChangeColor {
    color: #4771C8 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: 500 !important;
  }
  &__textAlreadyhaveAccount {
    color: #8B8B8B !important;
    font-size: 14px !important;
    line-height: 15px !important;
    margin-right: 8px;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 8px;
  }

  .ant-btn-primary {
    border: 0px;
  }

  &__errorMessageContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-top: 4px;
    margin-bottom: 16px;
    background-color: #FDECEB;
  }

  &__errorContainerCrossImage{
    width: 20px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
  }

  &__textErrorMessage{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    padding-left: 6px;
  }
  &__authHeaderTitle {
    display: flex;
    font-size: 14px !important;
    font-weight: normal !important;
    text-align: center !important;
    justify-content: center !important;
    color: #8B8B8B !important;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 14px;
  }
}
