.creatorDetails {
  &__rowDivision {
    float: right !important;
    display: flex !important;
    &__leftSide {
      display: flex !important;
      flex-direction: column !important;
      &__row1 {
        color: #0f0f60 !important;
        font-size: 14px !important;
        font-weight: bold !important;
      }
      &__row2 {
        color: #a7b3d9 !important;
        font-size: 12px !important;
        font-weight: bold !important;
      }
      &__row3 {
        color: #fe943b !important;
        font-size: 12px !important;
        // font-weight: bold !important;
        &__count {
          color: #a7b3d9;
        }
        &__star {
          font-size: 13px !important;
        }
      }
      &__row4 {
        color: #0f0f60 !important;
        font-size: 12px !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        text-align: start;
        margin-left: 219px; /* Adjust this value as needed */
        margin-right: -51px; /* Adjust this value as needed */
        -webkit-box-orient: vertical;
      }
      
    }
    &__middle {
      &__avatar {
        margin-left: 16px !important;
        color: #0f0f60 !important;
        text-align: center !important;
        background-color: #a7b3d9 !important;
        font-size: 24px !important;
        display: block !important;
      }
    }
  }
}
