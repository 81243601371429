// Base container for the tabs
.tabs-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.ant-tabs {
  width: 100%;
  margin: 0;
  padding: 0;
}

.ant-tabs-nav {
  padding-left: 20px;
  margin: 0;
}

.ant-tabs-tab {
  padding-left: 0;
  margin-left: 0;
  color: #07074a !important;
}

.tab-content {
  padding: 20px; // Add padding to the tab content
}
