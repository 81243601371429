.messageBox {
  display: flex;
  width: 100%;

  &__container {
    padding: 0 20px;
    margin-top: 15px;
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }

  &__isMeWrapper {
    display: flex;
    flex-direction: row-reverse !important;
    width: 100%;
  }

  &__bubble {
    max-width: 80%;
    width: fit-content;
    background-color: white;
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px;
    box-shadow: 2px 5px 4px 0px #797979;
  }
  &__bubbleMobile {
    max-width: 80%;
    width: fit-content;
    background-color: white;
    padding: 5px 10px;
    border-radius: 6px;
    box-shadow: 0px 1px 1px #797979;
  }

  &__bubbleMe {
    background-color: #0056ff;
    border: 0;
    max-width: 80%;
  }
  &__bubbleMeMobile {
    background-color: #0056ff;
    border: 0;
    max-width: 80%;
  }

  &__image {
    border-radius: 10px;
    cursor: pointer;
  }

  &__image_overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  &__senderContainer {
    // margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  &__time {
    display: flex;
    flex-direction: row-reverse;
    color: #6b7a7e !important;
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 4px;
  }
  &__timeMobile {
    display: flex;
    flex-direction: row-reverse;
    color: #6b7a7e !important;
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 4px;
  }
  &__isMeTime {
    display: flex;
    flex-direction: row-reverse;
    color: #ffffff !important;
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 4px;
    margin-left: auto;
  }

  &__isMeTimeMobile {
    display: flex;
    flex-direction: row-reverse;
    color: #ffffff !important;
    font-size: 12px !important;
    margin-top: 4px;
    margin-left: auto;
  }

  &__textSenderName {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    margin-left: 12px;
    color: #0f0f60 !important;
  }

  &__textSenderNameRightNavChat {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 13px !important;
    margin-left: 12px;
    color: #0f0f60 !important;
  }

  &__textMessage {
    font-size: 14px !important;
    line-height: 17px !important;
    color: #000000 !important;
  }
  &__textMessageIsMe {
    font-size: 14px !important;
    line-height: 17px !important;
    color: #ffffff !important;
  }
  &__textMessageMobile {
    font-size: 14px !important;
    line-height: 17px !important;
    color: #000000 !important;
  }
  &__textMessageIsMeMobile {
    font-size: 13px !important;
    font-weight: bold;
    line-height: 17px !important;
    color: #ffffff !important;
  }
  &__textMessageIsMeRightNavChat {
    font-size: 12px !important;
    line-height: 13px !important;
    color: #ffffff !important;
  }
  &__textMessageRightNavChat {
    font-size: 12px !important;
    line-height: 13px !important;
    color: #000000 !important;
  }
  &__callMeDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    border: 1px solid #a7b3d9;
    border-radius: 10px;
    background: #56a5ff;
  }
  &__callMeDiv:hover {
    border: 1px solid #4b9ef0 !important;
    border-radius: 5px;
  }

  &__callMeButton {
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 32px !important;
    border-radius: 24px !important;
    color: #56a5ff !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: 0px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  .ant-btn {
    color: #56a5ff;
    border-color: #56a5ff;
  }

  &__iconCallMe {
    width: 24px;
    height: 24px;
    padding: 4px;
  }

  &__callMeText {
    display: flex;
    height: 32px;
    width: 130px;
    font-size: 14px;
    align-items: center;
    color: white !important;
    padding-left: 10px;
    padding-right: 3px;
  }
  &__phoneInputDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }
  &__inputField {
    min-width: 300px;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;

    input {
      font-size: 14px !important;
      line-height: 13px !important;
      font-weight: 500 !important;
      color: #0f0f60 !important;
    }
  }
}
