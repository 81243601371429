.identity-profile {
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  font-family: "Lato";

  &__title {
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 8px;
      margin-top: 4px;
    }
  }
  &__table {
    margin-bottom: 16px;
    font-size: 14px !important;
    background-color: white;

    .ant-table {
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      word-wrap: break-word;
    }

    .ant-table-cell-row-hower {
      background-color: #39d50d !important;
    }

    .ant-table-tbody > tr {
      &:last-child {
        td {
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
    .ant-table-thead > tr > th {
      color: #000000;
      font-weight: bold;
      font-size: 14px !important;
      background-color: #f2f2f2;
      border-bottom: none;
    }
    .ant-table-thead > tr > th:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .ant-table-thead > tr > th:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    .ant-table-tbody > tr > td {
      color: #000000;
      border-bottom: none;
      font-size: 14px !important;
    }

  }
  .identity-profile__inputWrapper {
    display: flex;
    justify-content: flex-end; // Push the input to the right side
    width: 100%; // Ensure it takes full width to align properly
  
    .identity-profile__input {
      width: 270px !important; // Keep your existing styles
      height: 36px !important;
      border-radius: 20px !important;
      opacity: 1;
      margin-top: -15px !important;
      margin-bottom: 15px !important;
      background-color: #f2f2f2 !important;
  
      .ant-input {
        border-radius: 16px !important;
        background-color: #f2f2f2 !important;
        color: #000000 !important;
      }
  
      .ant-input-placeholder {
        color: #6b7a7e !important;
      }
    }
  }
}
