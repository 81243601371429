.upload-summary {
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  font-family: "Lato";

  &__title {
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 8px;
      margin-top: 4px;
    }
  }
  &__table {
    margin-bottom: 16px;
    background-color: white;

    .ant-table {
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    .ant-table-cell-row-hower {
      background-color: #39d50d !important;
    }

    .ant-table-tbody > tr {
      &:last-child {
        td {
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
    .ant-table-thead > tr > th {
      color: #000000;
      font-weight: bold;
      background-color: #f2f2f2;
      border-bottom: none;
    }
    .ant-table-thead > tr > th:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .ant-table-thead > tr > th:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    .ant-table-tbody > tr > td {
      color: #07074b;
      border-bottom: none;
    }

    &--summary {
      max-height: 250px;
      overflow-y: scroll;
    }
    &--identities {
      background-color: white;
      margin-bottom: 16px;
      margin-top: 20px;

      .ant-table {
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }

      .ant-table-tbody > tr > td {
        color: #07074b;
        border-bottom: none;
      }

      .ant-table-thead > tr > th {
        color: #000000;
        font-weight: bold;
        background-color: #f2f2f2;
        border-bottom: none;
      }

      .ant-table-thead > tr > th:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      .ant-table-thead > tr > th:last-child {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }
  }

  &__icon-row {
    display: flex;
    color: #07074b;
    align-items: center;
  }

  &__icon {
    margin-right: 8px;
    height: 18px !important;
    width: 20px !important;
  }
  &__icon_rowsCount {
    margin-right: 4px;
    height: 15px !important;
    width: 15px !important;
    margin-bottom: 2px;
  }

  &__ai-name {
    display: flex;
    align-items: center;

    .anticon {
      margin-left: 10px;
    }
  }

  &__time {
    display: flex;
    align-items: center;

    .anticon {
      margin-top: 4px;
      margin-right: 8px;
    }
  }

  &__tag {
    background-color: #e6f7ff;
    color: #1890ff;
    border: 1px solid #91d5ff;
    border-radius: 2px;
    padding: 0 8px;
    text-transform: capitalize;
  }

  &__icon-row {
    display: flex;
    align-items: center;
    color: #07074b;
    font-size: 14px;
  }
}
.upload-summary__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 70vw;
}

.upload-summary__loading h2 {
  color: #07074b;
  font-size: 14px;
}

.upload-summary__loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}
