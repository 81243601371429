.mobilePackageDetails {
  border: 1px solid #ccc;
  width: 100% !important;
  background-color: #ffffff;
  border-radius: 16px;

  &__header1 {
    color: #a7b3d9 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-left: 8px !important;
    margin-top: 8px !important;
  }
  &__parent {
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  &__input1 {
    display: flex;
    flex-direction: column;
    color: #000;
    text-align: center;
    font-size: 12px !important;
  }

  &__list {
    overflow-y: scroll !important;
    width: 100% !important;

    &__evenRow {
      background-color: #ffffff;
      padding: 4px 0px 4px 8px;
      border-top: 0.5px solid #ccc;
      border-bottom: 0.5px solid #ccc;
    }
    &__oddRow {
      background-color: #eff2fc;
      padding: 4px 0px 4px 8px;
      border-top: 0.5px solid #ccc;
      border-bottom: 0.5px solid #ccc;
    }
  }
  &__button {
    margin-top: 16px !important;
    width: 160px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-bottom: 16px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    color: white !important;
    background-color: #84d830 !important;
  }
  .ant-btn {
    margin-left: 36% !important;
  }
}

