.companiesSearch {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__saveContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: -20px;
    margin-bottom: 10px;
    padding-right: 24px;

    &__button {
      width: 127px !important;
      height: 26px !important;
      background-color: #3f97fc !important;
      border-radius: 15px !important;
      color: white !important;
      font-size: 12px !important;
      font-weight: bold !important;
      line-height: 26px !important;
      border: 1px solid #3f97fc !important;
      outline: none !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  &__filterAndResults {
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
    // border-top: 12px solid #F2F2F2;
    width: 100%;
    form {
      height: 100%;
    }

    &__filters {
      width: 250px;
      min-width: 250px;
      background-color: #fff;
      height: 100%;
    }
    &__results {
      flex-grow: 1;
      max-width: calc(100%) !important;
      width: calc(100%) !important;
      height: 100vh;
      overflow-y: auto;
    }
  }
}
