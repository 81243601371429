.field-mapper {
  padding: 16px;
  &__source-field,
  &__matched-fields,
  &__description-column,
  &__identity-fields {
    padding: 16px;
  }

  &__description {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 8px;
      font-size: 16px;
      color: #1890ff; // Set color for the icon (adjust as needed)
    }

    &-text {
      p {
        margin: 0;
        font-weight: bold;
      }

      small {
        display: block;
        color: #8c8c8c;
        font-size: 12px; // Adjust the size for the example text
      }
    }
  }

  &__select {
    width: 100%;
    .ant-select-selector {
      border-radius: 8px;
    }
  }

  &__switch {
    .ant-switch-checked {
      background-color: #1890ff;
    }
  }

  &__table {
    padding: 16px !important;
    margin-bottom: 16px;
    background-color: white;

    .ant-table {
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    .ant-table-cell-row-hower {
      background-color: #39d50d !important;
    }

    .ant-table-tbody > tr {
      &:last-child {
        td {
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
    .ant-table-thead > tr > th {
      color: #000000;
      font-weight: bold;
      background-color: #f2f2f2;
      border-bottom: none;
    }
    .ant-table-thead > tr > th:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .ant-table-thead > tr > th:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    .ant-table-tbody > tr > td {
      color: #07074b;
      border-bottom: none;
    }
  }

  .ant-select {
    width: 150px;
  }
}
