.createChatPopup {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    height: 467px;
    width: 400px;
    justify-content: center;
    overflow-y: scroll;
  }

  &__containerMobile {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0px !important;
    margin: 0px !important;
    justify-content: center;
    overflow-y: scroll;
    .ant-modal {
      max-width: unset;
      margin: unset;
    }
    .ant-modal-centered::before {
      content: unset;
    }
  }

  &__header {
    display: flex;
    width: 400px;
    height: 60px;
    border-bottom: 1px solid #a7b3d9;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
  }

  &__headerMobile {
    display: flex;
    width: 100%;
    min-height: 83px;
    height: 83px;
    border-bottom: 1px solid #a7b3d9;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
  }

  &__headerTitle {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #414c7b;
  }

  &__headerTitleMobile {
    display: flex;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    width: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: #414c7b;
  }

  &__user {
    width: 346px;
    height: 40px;
    padding-left: 1px;
    padding-right: 15px;
    /* background-color: red; */
    display: flex;
    align-items: center;
  }

  &__userMobile {
    width: 302px;
    height: 56px;
    padding-left: 1px;
    padding-right: 15px;
    /* background-color: red; */
    display: flex;
    align-items: center;
  }

  &__selectUsersContainer {
    height: 335px;
    overflow-y: scroll;
    padding-top: 13px;
    margin-bottom: 16px;
  }

  &__selectUsersContainerMobile {
    height: calc(100% - 260px);
    overflow-y: scroll;
    padding-top: 24px;
    margin-bottom: 40px
  }

  &__search {
    display: flex;
    border: 1px solid #a7b3d9;
    border-radius: 27px;
    height: 30px;
    width: 346px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__searchMobile {
    display: flex;
    border: 1px solid #a7b3d9;
    border-radius: 27px;
    height: 40px;
    width: 302px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__searchIcon {
    display: flex;
    width: 12px !important;
    height: 12px !important;
    margin-left: 10px;
    margin-right: 6px;
  }

  &__searchinput {
    width: 346px;
    height: 27px;
    display: flex;
    border: 0px !important;
    margin-left: 2px !important;
    margin-right: 10px !important;
    border-left: 27px !important;
    border-right: 27px !important;
    outline: 0px;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;

    input {
      font-size: 12px !important;
      line-height: 13px !important;
      font-weight: 500 !important;
      color: #0f0f60 !important;
    }
  }

  &__fullName {
    margin-left: 16px;
    color: #414c7b;
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: bold !important;
  }

  &__createBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 322px;
  }

  &__cameraIcon {
    display: flex;
    margin-top: 60px;
    width: 77px !important;
    height: 77px !important;
    cursor: pointer;
  }

  &__cameraIconMobile {
    display: flex;
    margin-top: 100px;
    width: 116px !important;
    height: 116px !important;
    cursor: pointer;
  }

  &__groupNameInput {
    display: flex;
    margin-top: 0px !important;
    width: 280px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-align: center;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;

  }

  &__groupNameInputMobile {
    display: flex;
    margin-top: 0px !important;
    width: calc(100% - 140px) !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    input {
      font-size: 18px !important;
      line-height: 22px !important;
      font-weight: 500 !important;
      color: #0f0f60 !important;
    }

  }

  &__addGroupButton {
    display: inline-flex;
    flex-direction: column;
    width: 137px !important;
    height: 32px !important;
    position: absolute;
    margin-bottom: 16px;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;

  }

  &__addGroupButtonMobile {
    display: inline-flex;
    flex-direction: row;
    width: 191px !important;
    height: 40px !important;
    position: absolute;
    margin-bottom: 80px;
    justify-content: center;
    align-items: center;
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;

  }

  &__newGroupNameDiv {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    width: 400px !important;
    height: 407px !important;
  }

  &__newGroupNameDivMobile {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
  }

  &__selectUserDiv {
    display: flex;
    flex-direction: column;
    width: 400px !important;
    height: 407px !important;
    margin-top: 6px;
    justify-content: center;
    align-items: center;
  }

  &__selectUserDivMobile {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: calc(100% - 84px) !important;
    margin-top: 0px;
    align-items: center;
  }

  &__searchUsersDiv {
    display: flex;
    flex-direction: row;
    width: 346px;
    height: 68px;
    margin-top: 6px;
    justify-content: flex-start !important;
    overflow-x: scroll;
  }

  &__searchUsersDivMobile {
    display: flex;
    flex-direction: row;
    width: 302px;
    height: 73px;
    margin-top: 24px;
    justify-content: flex-start !important;
    overflow-x: scroll;
  }

  &__searchedUsersParentDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 54px;
    height: 54px;
    margin-right: 6px;
  }

  &__searchAvatarDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 46px;
    width: 46px;
    margin-top: 2px;
  }

  &__searchedUserNameText {
    font-size: 9px !important;
    line-height: 12px !important;
    font-weight: bold !important;
    margin-top: 2px;
  }

  &__searchedUserNameTextMobile {
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: bold !important;
    margin-top: 2px;
  }

  &__userProfileIcon {
    width: 36px;
    height: 36px;
  }

  &__searchCrosIcon {
    width: 16px;
    height: 16px;
    margin-left: -10px;
    margin-top: -14px;
    cursor: pointer;
  }

  .ant-modal-content {
    border-radius: 8px !important;
  }

  .ant-modal-close {
    visibility: hidden;
  }
  
}