.datasetsListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;

  th.ant-table-cell {
    background: #f2f2f2 !important;
    font-weight: bold !important;
    color: #000000 !important;
  }
  &__headerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__p {
    display: flex;
    color: #07074a !important;
    font-size: 15px;
    font-weight: bold;
  }
  &__small {
    display: flex;
    color: #07074a !important;
    font-size: 14px;
  }

  &__datasetInfo {
    display: flex;
    flex-direction: column;
  }

  &__newDatasetBtn {
    margin-top: -30px !important;
    color: white !important;
    background-color: #1c74d4 !important;
    width: 146px;
    height: 36px !important;
    text-align: center !important;
    border-radius: 20px !important;
    font-size: 11pt !important;
    font-family: "Lato", sans-serif !important;
    font-weight: bold !important;
  }

  &__h1Text {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #07074a !important;
  }
  &__h1Description {
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    color: #07074a !important;
  }

  .icon-facebook {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPLzPj0lBrKZ_lemEDk9DUnfMCWE8EcYjmwA&s");
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: cover;
  }

  .icon-search {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzrzmQyShtZ8DjX7YUl6R0OT6V1ZDdURrt6g&s");
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: cover;
  }
}

.datasetsListContainer .ant-spin-dot-item {
  background-color: #3872cd !important; /* Set the desired color */
}
.datasetsListContainer .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40% !important;
}
.bold-title {
  font-weight: 600; /* Adjust as needed, e.g., 500 for even lighter */
  color: #07074a !important;
}

/* In your CSS file */
.table-row {
  // background-color: #f0f5fb; /* Adjust as needed */
}

.ant-table-tbody > tr:hover > td {
  background-color: #e8eff7 !important; /* Remove default Ant Design hover color */
}

.datasetsListContainer__status-filter {
  width: 120px !important;
  border-radius: 18px !important;
  background-color: #f2f2f2 !important;
  margin-right: 474px !important;
  margin-left: 34px !important;

  .ant-select-selector {
    height: 36px !important;
    border-radius: 18px !important;
    background-color: #f2f2f2 !important;
    border: 1px solid #d9d9d9 !important;

    .ant-select-selection-item {
      color: #000000 !important;
      margin-top: 0.5px !important;
    }
  }

  .ant-select-dropdown {
    border-radius: 16px !important;
    background-color: #f2f2f2 !important;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }
  .Status {
    display: inline-block;
    width: 13px !important;
    height: 13px !important;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 10px !important;
    text-align: center;
    line-height: 16px;
    font-size: 10px;
    color: white !important;
  }

  .all-icon {
    background-color: #007bff !important;
    position: relative;

    &::before {
      content: "A";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white !important;
      font-size: 9px;
      font-weight: bold;
    }
  }

  .enabled-icon {
    background-color: #84d830 !important;
  }

  .disabled-icon {
    background-color: #cccccc !important;
  }
}

.datasetsListContainer__filter {
  margin-top: -20px !important;
  margin-bottom: 20px !important;
  margin-left: -35px !important;
}

.datasetsListContainer__input {
  width: 270px !important; /* Set the width */
  height: 36px !important; /* Set the height */
  margin-top: -30px !important;
  margin-left: 350px !important;
  margin-right: 10px !important;
  border-radius: 20px !important; /* Set the border-radius */
  opacity: 1;
  background-color: #f2f2f2 !important; /* Set the background color */

  .ant-input {
    border-radius: 16px !important; /* Ensure the border-radius is applied to the input field */
    background-color: #f2f2f2 !important; /* Ensure the background color matches */
    color: #000000 !important; /* Set text color */
  }

  .ant-input-placeholder {
    color: #6b7a7e !important; /* Set placeholder text color */
  }
}
