.findWorkspaces {
 // background-color: #eff2fc !important;
  height: calc(100vh - 200px);

  &__searchContainer {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 16px 24px 14px 24px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &__searchField {
    display: flex;
    margin-right: 24px;
    width: 100%;
    background-color: white;
    margin-bottom: 12px;
    align-items: center;
    border: 1px solid #a7b3d9;
    border-radius: 15px;

    &__addIcon {
      cursor: pointer;
      margin-left: 12px !important;
      height: 26px !important;
      width: 26px !important;
    }
  }

  &__workspacesList {
    display: flex;
    flex-direction: row;
    margin-top: 3 !important;
    //background-color: #eff2fc;
    height: 100% !important;

    &__filtersSection {
      overflow-y: scroll;
      width: 350px;
      max-height: 100% !important;
      background-color: white;
      margin-right: 16px !important;
    }

    &__list {
      overflow-y: scroll;
      &__heading {
        &__row {
          margin-top: 0px;
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: -13px;
          display: flex;
          border: 1px solid #eff1fb;
          background-color: #eff1fb;
          justify-content: space-between;
          &__text {
            color: #0f0f60;
            font-family: Lato !important;
            font-size: 14px;
            margin-bottom: 8px;
            margin-top: 8px;
            margin-left: 12px;
            font-weight: bold;
          }
        }
      }
      background-color: white;
      width: 100%;

      &__listItem {
        margin: 4px 4px 4px 4px !important;
        padding: 8px 0px 4px 0px !important;
      }
    }
  }
}
