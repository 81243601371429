.chatInput{
    display: flex;
    width: 100%;
    

    &__container{
        width: 95.9%;
        align-items: center;
        height: 50px;
        padding: 8px 8px;
        margin-bottom: 10px;
        background-color: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &__wrapper {
        width: 100%;
        height: 100%;
        border: 1px solid #a7b3d9;
        border-radius: 31px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
      }
      &__textarea {
        border: none;
        overflow: auto;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none; /*remove the resize handle on the bottom right*/
        height: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        padding-top: 4px;
        font-size: 14px !important;
        color: #0f0f60 !important;
      }

      &__textareaRightNavChat {
        border: none;
        overflow: auto;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none; /*remove the resize handle on the bottom right*/
        height: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        padding-top: 4px;
        font-size: 13px !important;
        color: #0f0f60 !important;
      }

      &__toolsContainer {
        display: flex;
        align-items: center;
      }

      &__tool {
        width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      &__toolRightNavChat {
        width: 26px;
        // height: calc(100% - 40px);
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      &__smsIconDiv{
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
      }
}