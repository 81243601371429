.notifSettings {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: scroll;
  
    &__header {
      display: flex;
      flex-direction: column;
      border-radius: 8px !important;
      background-color: white;
      height: 417px !important;
    }

    &__headerMobile {
      display: flex;
      flex-direction: column;
      border-radius: 8px !important;
      background-color: white;
      height: 417px !important;
      border: 1px solid #a7b3d9 !important;
      margin: 20px;
    }

    &__titleDiv{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 27px;
      margin-left: 25px;
      margin-right: 95px;
    }
  
    &__headerText {
      font-size: 14px !important;
      font-weight: bold !important;
      line-height: 17px !important;
      color: #0f0f60;
    }

    &__iconBell{
      width: 19px !important;
      height: 22px !important;
    }

    &__notifItemContainer{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 11px;
      margin-left: 16px;
      margin-right: 16px;
      height: 36px;
      background-color: #EFF2FC;
    }

    &__notifItemContainerWhite{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 11px;
      margin-left: 16px;
      margin-right: 16px;
      height: 36px;
      background-color: white;
    }

    &__switch{
      margin-right: 74px !important;
      
    }
    
    &__profileText {
      font-size: 12px !important;
      font-weight: bold !important;
      line-height: 15px !important;
      color: #A7B3D9 !important;
    }
  
    &__regularTextBlue {
      font-size: 12px !important;
      line-height: 15px !important;
      color: #0F0F60 !important;
      padding-left: 9px;
    }
  
    &__regularTextLightBlue {
      font-size: 12px !important;
      line-height: 15px !important;
      color: #56A5FF !important;
      cursor: pointer;
    }
  
    &__regularTextGrey {
      font-size: 12px !important;
      line-height: 15px !important;
      color: #A7B3D9 !important;
      margin-top: 4px !important;
    }

    .ant-switch-checked {
      background-color: #84D830;
  }

  .ant-switch-small {
    min-width: 28px;
    height: 16px;
    line-height: 16px;
}

&__buttonsDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
  margin-right: 16px;
}

&__buttonsDivMobile {
  display: flex;
  justify-content: center;
  margin-top: 44px;
  
}

&__saveChangesButtonMobile {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  height: 40px !important;
  width: 190px;
  border-radius: 25px !important;
  background-color: #56A5FF !important;
  color: white;
  border: 0px !important;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 17px !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
}

&__saveChangesButton {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  height: 30px !important;
  width: 110px;
  border-radius: 16px !important;
  background-color: #56A5FF !important;
  color: white;
  border: 0px !important;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 17px !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
}
&__cancelButton {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  height: 30px !important;
  width: 110px;
  border-radius: 16px !important;
  background-color: white !important;
  color: #56A5FF !important;
  margin-right: 10px;
  border: 1px solid #56A5FF !important;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 17px !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;;
}
    
  }
  