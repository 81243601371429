.profilePage {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;

  .ant-btn {
    border-radius: 15px !important;
  }
  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  &__headerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    min-height: 60px !important;
    max-height: 60px !important;
    padding-left: 24px;
    padding-right: 16px;
    border-bottom: 1px solid #a7b3d9 !important;
  }
  &__header {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    height: 310px !important;
  }

  &__headerMobile {
    display: flex;
    flex-direction: column;
    border: 1px solid #a7b3d9 !important;
    margin: 20px;
    border-radius: 8px !important;
    background-color: white;
    height: 310px !important;
  }

  &__headerNameInfoDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 135px !important;
    border-bottom: 1px solid #a7b3d9 !important;
    padding-left: 24px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    margin-top: 8px;
  }

  &__footerMobile {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid #a7b3d9 !important;
    margin: -8px 20px 20px 20px;
  }
  &__footerInfoDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-left: 24px;
  }
  &__ctoParentDiv {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
    padding-right: 17px;
  }
  &__workProfileEditDiv {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
    padding-right: 17px;
    margin-bottom: 20px;
  }
  &__ctoDiv {
    display: flex;
    flex-direction: column;
    min-height: 100px !important;
    padding-left: 24px;
  }

  &__verifyPhoneDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__phoneTitleValueDiv {
    display: flex;
    flex-direction: column;
  }

  &__verifyTextDiv {
    display: flex;
    align-items: flex-end;
    height: 42px;
  }

  &__verifyText {
    font-size: 12px !important;
    line-height: 15px !important;
    color: #84d830 !important;
    cursor: pointer;
  }

  &__noteDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px !important;
    background-color: #fdf8db !important;
    height: 39px;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  &__headerImgDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 56px;
    height: 56px;
  }
  &__headerImg {
    float: right !important;
    height: 35px !important;
    width: 35px !important;
    cursor: pointer;
  }

  &__workImg {
    height: 50px !important;
    width: 50px !important;
    cursor: pointer;
  }
  &__cameraIcon {
    width: 24px;
    height: 24px;
    margin-left: -6px;
    margin-bottom: -20px;
    cursor: pointer;
  }
  &__headerText {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #0f0f60;
  }
  &__inputField {
    display: flex;
    padding: 0px !important;
    height: 15px;
    width: 200px !important;
    border: 0px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
  }

  &__inputFieldWithMargin {
    display: flex;
    padding-left: 6px !important;
    height: 30px;
    width: calc(100% - 20px) !important;
    border: 1px solid #a7b3d9 !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
  }

  &__inputFieldDesc {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    padding-left: 6px !important;
    height: 60px;
    width: calc(100% - 20px) !important;
    border: 1px solid #a7b3d9 !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
  }

  &__sellerImgsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
  }
  &__normalTextBoldFirst {
    font-size: 12px !important;
    font-weight: bold;
    line-height: 15px !important;
    margin-top: 12px;
    color: #a7b3d9 !important;
  }

  &__normalTextWorkLogos {
    font-size: 12px !important;
    font-weight: bold;
    line-height: 15px !important;
    margin-right: 12px;
    color: #a7b3d9 !important;
  }
  &__normalTextBold {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 15px !important;
    color: #a7b3d9 !important;
    margin-top: 10px;
  }
  &__normalText {
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
  }
  &__noteText {
    font-size: 9px !important;
    line-height: 12px !important;
    color: #f2a965 !important;
    padding-left: 14px;
    padding-right: 14px;
  }
  &__iconEdit {
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
  }
  &__iconClose {
    width: 10px !important;
    height: 10px !important;
    margin-right: 10px;
    cursor: pointer;
  }
  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__content {
    min-height: "100%";
    height: "100%";
    background-color: #ffffff;
    overflow-y: scroll;
  }
  &__rightNav {
    background-color: #0f0f60;
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }

  &__menu {
    background: #100f5c;
  }
  &__divider {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  &__deleteAccountButtonBuyer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 120px;
    margin-top: 20px !important;
    margin-bottom: 0px;
  }

  &__deleteAccountButtonSeller {
    display: flex;
    align-self: flex-end;
    width: 120px;
    margin-top: 30px !important;
    margin-bottom: 0px;
  }

  &__deleteAccountButtonBuyerMobile {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 120px;
    margin-top: 10px !important;
    margin-right: 20px !important;
    margin-bottom: 0px;
  }

  &__deleteAccountButtonSellerMobile {
    display: flex;
    align-self: flex-end;
    width: 120px;
    margin-top: 10px !important;
    margin-right: 20px !important;
    margin-bottom: 36px;
  }

  &__buttonsDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px !important;
  }
  &__saveChangesButton {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 130px;
    border-radius: 16px !important;
    background-color: #84d830 !important;
    color: white;
    border: 0px !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__cancelButton {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 110px;
    border-radius: 16px !important;
    background-color: white !important;
    color: #84d830 !important;
    margin-right: 10px;
    border: 1px solid #84d830 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 54px !important;
    height: 54px !important;
  }
}
