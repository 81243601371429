.quizFlow{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;

    &__headerLogo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

    &__quizLogo{
        width: 89px;
        height: 72px;
        margin-top: 40px;
    }

    &__quizTitles{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__quizOptionsParentDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__quizButtonsDiv{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }

    &__quizflowOptionsParentDiv{
        display: grid;
        grid-template-columns: repeat(3,0fr);
        align-items: center;
        justify-items: center;
        justify-content: center;
    }

    &__quizflowDropdownOptionsParentDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__quizflowOptionsDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 120px;
        border: 1px solid #DCDEE3;
        border-radius: 5px;
        margin: 8px;
        cursor: pointer;
    }
    &__quizflowOptionsDiv:hover{
        border: 1px solid #4B9EF0;
    }
    &__selectedQuizflowOptionsDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #F2F9FF;
        width: 200px;
        height: 120px;
        border: 1px solid #619CEA;
        border-radius: 4px;
        margin: 8px;
        cursor: pointer;
    }
    &__tickBadge {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        
      }

      &__container{
        position: relative;
      }

    &__quizflowDropdownOptionsDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 120px;
        border: 1px solid #DCDEE3;
        margin: 8px;
    }

    &__quizOptionImage{
        width: 40px;
        height: 40px;
    }

    &__quizTitleText {
        display: flex;
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        color: #233034 !important;
        margin-top: 60px;
    }

    &__quizSubHeadingText {
        display: flex;
        width: 100%;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        color: #62727A !important;
        margin-top: 11px;
        margin-bottom: 12px;
    }

    &__quizOptionsText {
        display: flex;
        width: 100%;
        font-size: 17px;
        font-weight:500;
        justify-content: center;
        align-items: center;
        color: #1B242A !important;
        margin-top: 11px;
        margin-bottom: 12px;
    }
    &__selectedQuizOptionsText {
        display: flex;
        width: 100%;
        font-size: 17px;
        font-weight:500;
        justify-content: center;
        align-items: center;
        color: #3191D2 !important;
        margin-top: 11px;
        margin-bottom: 12px;
    }
    
    &__backButton {
        display: inline-flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 40px !important;
        background-color: white !important;
        color: black !important;
        font-size: 16px !important;
        font-weight: bold !important;
        border: 1px solid #DCDEE3;
        border-radius: 5px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;

      }
      

    &__submitButton {
        display: inline-flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 40px !important;
        margin-left: 10px !important;
        background-color: #4B9EF0 !important;
        color: white !important;
        font-size: 16px !important;
        font-weight: bold !important;
        border: 1px solid #DCDEE3;
        border-radius: 5px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;

      }
      &__dropDownParentDiv{
        margin-right: 10px;
        margin-left: 10px;
      }
      &__dropDownDiv{
        width: 280px;
        cursor: pointer;
      }

}