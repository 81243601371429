.UploadTable {
  margin-top: -20px !important;
 
  th.ant-table-cell {
    background: #F2F2F2 !important;
    font-weight: bold !important;
    color: #000000 !important;
    font-size: 14px !important;
  }
  td.ant-table-cell {
    font-size: 14px !important; /* Row font size */
    color: #000000 !important; /* Row font color */
  }
  &__headerText {
    margin: 0% 0% 2% 2%;
    font-size: 15px;
    font-weight: bold;
    padding-top: 2%;
  }
  &__title {
    margin-left: 9px;
    margin-bottom: -18px;
    margin-top: 30px;
    padding: 0px;
    color: rgb(71, 77, 102);
    font-size: 1rem;
    line-height: 2.75rem;
    font-weight: 600;
    letter-spacing: -0.02em;
  }
  &__sourceText {
    margin-left: 35px;
    margin-bottom: -18px;
    margin-top: 25px;
    padding: 0px;
    color: rgb(71, 77, 102);
    font-size: 1.4rem;
    line-height: 2.75rem;
    font-weight: 600;
    letter-spacing: -0.02em;
  }
  &__bar {
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
    margin-top: 15px;
  }
  // &__input {
  //   width: 230px !important;
  //   height: 36px !important;
  //   margin-right: 1% !important;
  //   border-radius: 16px !important; /* Adjust the border-radius as needed */
  //   background-color: #f2f2f2 !important; /* Set your desired background color */
  // }
  &__button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 36px !important;
    width: 134px !important;
    border-radius: 20px !important;
    opacity: 1;
    background-color: #1c74d4 !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__tableDiv {
    margin: 4% 4% 0% 3%;
    height: calc(100% - 100px) !important;
  }
  &__btnImage {
    margin-left: -28%;
  }

  &__details-button {
    cursor: pointer;
  }
  &__details-button,
  &__details-button:active,
  &__details-button:hover,
  &__details-button:focus {
    border: none !important;
    height: 30px !important;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    float: left !important;
    margin-right: 0px !important;
    color: white !important;
    background-color: #98d650 !important;
  }
  &__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100% !important;
    height: 300px !important;
  }
}
.UploadTable .ant-spin-dot-item {
  background-color: #3872cd !important; /* Set the desired color */
}
.statusFlex {
  display: flex;
}
.Status {
  width: 11px !important;
  height: 11px !important;
  background-color: #84d830;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%;
}
.InActiveStatus {
  width: 11px !important;
  height: 11px !important;
  background-color: #cccccc !important;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%;
}
.nameFlex {
  display: flex;
  justify-content: space-between;
}
.nameText {
  width: 75%;
}

.UploadTable__bar {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: -25px;
}

.UploadTable__status-filter {
  width: 70px; /* Adjust the width as needed */
  border-radius: 18px !important; /* Adjust the border-radius as needed */
  background-color: #f2f2f2 !important; /* Set your desired background color */
  margin-right: 474px !important; // Adjust this value if needed
  margin-left: 34px !important;
  .ant-select-selector {
    height: 36px !important;
    border-radius: 18px !important; /* Ensure the border-radius is applied to the select box */
    background-color: #f2f2f2 !important; /* Match the background color */
    border: 1px solid #d9d9d9 !important; /* Optional: add border to match the default Ant Design styling */
    .ant-select-selection-item {
      color: #000000; /* Text color inside the select */
      margin-top: 0.5px !important;
    }
  }
  .ant-select-dropdown {
    border-radius: 16px !important; /* Apply border-radius to the dropdown */
    background-color: #f2f2f2; /* Match the background color of the dropdown */
  }
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }

  .Status {
    display: inline-block;
    width: 13px !important; /* Adjust width as needed */
    height: 13px !important; /* Adjust height as needed */
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 10px !important;
    text-align: center; /* Center the text horizontally */
    line-height: 16px; /* Center the text vertically */
    font-size: 10px; /* Adjust font size as needed */
    color: white; /* White text color for better contrast */
  }
  
  /* Styling for each status */
  .all-icon {
    background-color: #007bff !important; /* Blue background for All */
    position: relative; /* Needed for the pseudo-element */
  }
  
  /* Pseudo-element to show "A" inside the circle */
  .all-icon::before {
    content: "A"; /* Display the letter "A" */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the text */
    color: white; /* White text color */
    font-size: 9px; /* Adjust font size as needed */
    font-weight: bold; /* Make the letter bold */
  }

  .enabled-icon {
    background-color: #84d830 !important; /* Green color for Enabled */
  }

  .disabled-icon {
    background-color: #cccccc !important; /* Grey color for Disabled */
  }

  .in-progress-icon {
    background-color: #ffc107 !important; /* Yellow color for In Progress */
  }

  .no-recent-data-icon {
    background-color: #dc3545 !important; /* Red color for No Recent Data */
  }
}

/* Ensure the colors and styles are shown in the dropdown options */
.ant-select-dropdown .ant-select-item-option-content .Status {
  display: inline-block;
  width: 13px !important; /* Adjust width as needed */
  height: 13px !important; /* Adjust height as needed */
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 10px !important;
  text-align: center; /* Center the text horizontally */
  line-height: 16px; /* Center the text vertically */
  font-size: 10px; /* Adjust font size as needed */
  color: white; /* White text color for better contrast */
}

.ant-select-dropdown .ant-select-item-option-content .all-icon {
  background-color: #007bff !important; /* Blue background for All */
  position: relative;
}

.ant-select-dropdown .ant-select-item-option-content .all-icon::before {
  content: "A";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 9px;
  font-weight: bold;
}

.ant-select-dropdown .ant-select-item-option-content .enabled-icon {
  background-color: #84d830  !important;
}

.ant-select-dropdown .ant-select-item-option-content .disabled-icon {
  background-color: #cccccc!important;
}

.ant-select-dropdown .ant-select-item-option-content .in-progress-icon {
  background-color: #ffc107 !important;
}

.ant-select-dropdown .ant-select-item-option-content .no-recent-data-icon {
  background-color: #dc3545 !important;
}

// .UploadTable__input {
//   flex: 1; // This will make the input grow to take up available space
//   margin-right: 16px; // Space between input and button
// }

.UploadTable__button {
  margin-left: 16px; // Space between input and button
}

.UploadTable__input {
  width: 270px !important; /* Set the width */
  height: 36px !important; /* Set the height */
  margin-right: 1% !important; /* Add margin as needed */
  border-radius: 20px !important; /* Set the border-radius */
  opacity: 1;
  background-color: #f2f2f2 !important; /* Set the background color */
  
  .ant-input {
    border-radius: 16px !important; /* Ensure the border-radius is applied to the input field */
    background-color: #f2f2f2 !important; /* Ensure the background color matches */
    color: #000000 !important; /* Set text color */
  }

  .ant-input-placeholder {
    color: #6b7a7e !important; /* Set placeholder text color */
  }
}