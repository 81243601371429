.scheduleCall{
    display: flex;
    flex-direction: row;

    &__scheduleMeetingDiv{
      display: flex;
      flex-direction: row;
      justify-content: center;
      cursor: pointer;
      // margin-left: 28px;
    }

    &__scheduleMeetingDiv:hover{
      border: 1px solid #4B9EF0 !important;
      border-radius: 5px;
    }

    &__scheduleMeetingDivDesktop{
      display: flex;
      flex-direction: row;
      justify-content: center;
      cursor: pointer;
    }

    &__scheduleMeetingDivDesktop:hover{
      border: 1px solid #4B9EF0 !important;
      border-radius: 5px;
    }
    
    &__scheduleButton {
        display: inline-flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 32px !important;
        // border-radius: 24px !important;
        color: #56A5FF !important;
        font-size: 14px !important;
        font-weight: bold !important;
        // border: 0px;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
      }
      .ant-btn {
        color: #56A5FF;
        // border-color: #56A5FF;
        
    }

    &__iconScheduleMeeting{
      width: 24px;
      height: 24px;
      padding: 0px;
    }

    &__scheduleMeetingText {
      display: flex;
      height: 32px;
      width: 130px;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      color: #A7B3D9 !important;
      padding-left: 3px;
      padding-right: 3px;
  }
}