.ProcessingFields {
    &__wrapper {
        margin-left: 35px!important;
        //width: 100% !important;
        display: flex !important;
        flex-wrap: wrap !important;
    }

    &__input {
        width: 410px !important;
        padding: 10px !important;
        border-radius: 5px !important;
        color:#A9B3D6;
    }

    &__heading {
        text-align: start;
        font-size: 14px;
        margin-bottom: 6px;
        margin-top: 1px;
        font-weight: bold;
        color: #0f0f60
    }

    &__div {
        margin: 3px 20px 10px 0px;
        color: #0f0f60
    }

    &__para {
        margin-top: 0px;
        font-size: 12px;
        text-align: start;
        color: gray;
        width: 350px;
    }

    &__error {
        margin-top: 0px;
        color: red;
    }

    &__mainHeading {
        margin-left: 35px;
        margin-bottom: 30px !important;
        margin-top: 25px;
        padding: 0px;
        color: rgb(71, 77, 102);
        font-size: 1.4rem;
        line-height: 2.75rem;
        font-weight: 600;
        letter-spacing: -0.02em;
    }
    &__mainHeading2 {
        font-size: 15px;
        margin: 2% 0px 2% 3%;
        font-weight: bold;
    }
    &__component{
        width: 100% !important;
        border-bottom: 10px solid #EFF2FC;
        border-top: 10px solid #EFF2FC;
    }
    &__buttonsDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 2%;
        margin-left: 35px;
        //padding-bottom: 4%;
    }
    &__button {
        height:40px !important;
        width:128px !important;
        border-radius: 6px !important;
        background-color: #3972CE !important;
        color: white !important;
        border: 0px !important;
        font-size: 14px !important;
        font-weight: bold !important;
        line-height: 17px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    }
}
.fixedBar{
    margin: 10px 0% 1% 3% !important;
}
.fixedBar span {
    margin-right: 15px; /* Adjust the value as needed */
  }
.Fixed{
    width: 100% !important;
    height: 12% !important;
    background-color: white;
    padding: 12px 0px 0px 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    position: sticky !important;
    //top: % !important;
    z-index: 99;
    margin-bottom: 12px;
    border-bottom: 10px solid #EFF2FC;
}
.Title{
    font-weight: bold;
    font-size: 15px;
    margin-left: -4px;
    color:#0f0f60
}

.ant-spin .ant-spin-dot-item {
    background-color: #3872cd !important; /* Force the color of the spinning dots */
  }
.loading-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 70% !important;
  }

.anticon svg {
    display: inline-block;
    margin-bottom: 3px;
}