.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: #F8FBFF;
  height: 87px;

  &__footerText{
    font-size: 10px !important;
    line-height: 12px !important;
    font-weight: bold !important;
    color: #000000 !important;
    margin-right: 16px !important;
  }
}
