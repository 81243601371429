.buildList {
  padding: 16px;

  &__subList {
    background-color: #f0f5fb;
    border-top-left-radius: 0px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-top: 0px;
    padding: 8px;
    padding-bottom: 24px;
    position: relative;
    z-index: 1;
  }

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }

    &-image {
      margin-right: 4px !important;
      width: 20px !important;
      height: 20px !important;
    }

    &-mainHeading {
      flex: 1;
      font-family: Lato;
      font-weight: bold;
      font-size: 14pt;
      text-align: left;
      margin: 0px 0px 0px 8px;
    }
  }

  &__customCardContent {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 0px !important;
    margin-top: 16px !important;
    margin-left: 32px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }

    &-image {
      margin-right: 4px !important;
      width: 20px !important;
      height: 20px !important;
    }

    &-mainHeading {
      flex: 1;
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0px 0px 0px 8px;
    }
  }
}

.ant-drawer-body {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  overflow: hidden !important;
}
.ant-drawer-wrapper-body {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  overflow: hidden !important;
}
.ant-drawer-content {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  overflow: hidden !important;
}
.ant-drawer-content-wrapper {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  overflow: hidden !important;
}
