.PeopleNav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__unreadCountContainer {
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
        border-radius: 100%;
        background-color: #56a5ff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
      }
      &__unreadCount {
        color: #fff;
        font-weight: 500;
        font-size: 10px;
      }
}