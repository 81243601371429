.tasks {
  width: 80% !important;

  &__scrollable {
    max-height: 82vh;
    overflow-y: auto;
  }

  &__panel {
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &--active {
      box-shadow: none;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #3872cd;
      color: #ffffff;

      &--active {
        background-color: #3a6793;
      }

      &--selected {
        background-color: green;
      }
    }

    &__heading {
      font-size: 15px;
      font-weight: bold;
      color: #394399;

      &--active,
      &--selected {
        color: #ffffff;
      }
    }

    &__subheading {
      font-size: 13px;
      color: #394399;

      &--active,
      &--selected {
        color: #ffffff;
      }
    }
  }
}
