.model {
  padding: 20px;
  background-color: #fff;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    &__input {
      width: 300px !important;
      height: 35px !important;
      margin-right: 1% !important;
      border-radius: 16px !important;
      background-color: #f2f2f2 !important;

      .ant-input {
        border-radius: 16px !important;
        background-color: #f2f2f2 !important;
        color: #000000 !important;
      }

      .ant-input-prefix {
        margin-right: 8px !important;
        margin-top: 4px !important;
      }
      .ant-input-placeholder {
        color: #6b7a7e !important;
      }
    }
  }

  &__content {
    margin-top: 10px;

    .ant-table-thead > tr > th {
      background-color: #f5f5f5;
      font-weight: bold;
      border-bottom: none;
    }

    .ant-table-tbody > tr > td {
      padding: 16px;
      font-size: 14px;
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &__avatar {
      margin-right: 8px !important;
    }
  }
  .model__status-filter {
    width: 70px; /* Adjust the width as needed */
    border-radius: 18px !important; /* Adjust the border-radius as needed */
    background-color: #f2f2f2 !important; /* Set your desired background color */
    margin-right: 474px !important; // Adjust this value if needed
    .ant-select-selector {
      height: 36px !important;
      border-radius: 18px !important; /* Ensure the border-radius is applied to the select box */
      background-color: #f2f2f2 !important; /* Match the background color */
      border: 1px solid #d9d9d9 !important; /* Optional: add border to match the default Ant Design styling */
      .ant-select-selection-item {
        color: #000000; /* Text color inside the select */
        margin-top: 0.5px !important;
      }
    }
    .ant-select-dropdown {
      border-radius: 16px !important; /* Apply border-radius to the dropdown */
      background-color: #f2f2f2; /* Match the background color of the dropdown */
    }
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  
    .Status {
      display: inline-block;
      width: 13px !important; /* Adjust width as needed */
      height: 13px !important; /* Adjust height as needed */
      border-radius: 50%;
      margin-right: 8px;
      margin-top: 10px !important;
      text-align: center; /* Center the text horizontally */
      line-height: 16px; /* Center the text vertically */
      font-size: 10px; /* Adjust font size as needed */
      color: white; /* White text color for better contrast */
    }
    
    /* Styling for each status */
    .all-icon {
      background-color: #007bff !important; /* Blue background for All */
      position: relative; /* Needed for the pseudo-element */
    }
    
    /* Pseudo-element to show "A" inside the circle */
    .all-icon::before {
      content: "A"; /* Display the letter "A" */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the text */
      color: white; /* White text color */
      font-size: 9px; /* Adjust font size as needed */
      font-weight: bold; /* Make the letter bold */
    }
  
    .enabled-icon {
      background-color: #84d830 !important; /* Green color for Enabled */
    }
  
    .disabled-icon {
      background-color: #cccccc !important; /* Grey color for Disabled */
    }
  
    .in-progress-icon {
      background-color: #ffc107 !important; /* Yellow color for In Progress */
    }
  
    .no-recent-data-icon {
      background-color: #dc3545 !important; /* Red color for No Recent Data */
    }
  }
  .model_statusFlex {
    display: flex;
  }
  .model_Status {
    width: 11px !important;
    height: 11px !important;
    background-color: #84d830;
    border-radius: 50%;
    margin-top: 3%;
    margin-right: 7%;
  }
  .model_InActiveStatus {
    width: 11px !important;
    height: 11px !important;
    background-color: #ffc107 !important;
    border-radius: 50%;
    margin-top: 3%;
    margin-right: 7%;
  }
  
}
