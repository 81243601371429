.forgotPassword {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: calc(100% - 180px);

  &__container {
    height: 100%;
  }

  &__signinFields {
    width: 273px;
  }

  &--withoutShadow {
    box-shadow: unset;
    padding: unset;
  }

  &__headerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  &__logo {
    display: flex;
    width: 80px;
    height: 80px;
  }

  &__headerTitle {
    display: flex;
    font-size: 30px !important;
    line-height: 36px !important;
    font-weight: 500 !important;
    text-align: center;
    justify-content: center;
    color: #0f0f60 !important;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  &__footerText {
    font-weight: bold;
    color: white;
    background: white;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    font-weight: normal;
    justify-content: flex-end;
    width: 100vw;
    background-color: white !important;
    margin-top: 63px;
  }
  &__namesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 8px;
  }
  &__footer {
    display: flex;
    align-items: center;
    font-weight: normal;
    justify-content: center;
    width: 100vw;
  }
  &__inputField {
    min-width: 273px;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;

    input{
      font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    }
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
  }
  &__googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40;
  }

  &__continueButton {
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #84d830 !important;
    color: white;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__button {
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    min-width: 128px;
    margin-right: 43px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #56a5ff !important;
    color: white;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__footer {
    font-weight: normal;
  }
  &__linkButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
  }
  &__googleButton {
    cursor: pointer;
  }
  &__textCodeNotReveived {
    display: flex;
    justify-content: center;
    color: #0f0f60 !important;
    height: 15px !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }

  &__textSendAgain {
    height: 15px;
    color: #56a5ff !important;
    font-size: 12px !important;
    line-height: 15px !important;
    margin-left: 6px;
    cursor: pointer;
  }

  &__textDonthaveAccount {
    color: #a7b3d9 !important;
    font-size: 14px !important;
    line-height: 15px !important;
    margin-right: 8px;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 8px;
  }

  .ant-btn-primary {
    border: 0px;
  }
  &__sendAgainDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  &__errorMessageContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-top: 4px;
    margin-bottom: 16px;
    background-color: #FDECEB;
  }

  &__errorContainerCrossImage{
    width: 20px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
  }

  &__textErrorMessage{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    padding-left: 6px;
  }
}
