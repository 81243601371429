.newPlaybooks {
  display: flex;
  width: 100%;
  flex-direction: column;
  //background-color: hsl(226, 68%, 96%) !important;
  overflow-y: scroll;
  // height: calc(100vh - 120px);
  .ant-picker-input > input {
    font-size: 12px !important;
  }

  &__Margin {
    width: 100%;
    margin-top: -19px;
  }

  &__chevron {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  &__searchContainer {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    background-color: white;
    padding: 16px 0px 0px 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    position: sticky !important;
    top: 0;
    z-index: 99;
    margin-bottom: 12px;

    &__addIcon {
      cursor: pointer;
      margin-right: 24px !important;
      height: 26px !important;
      width: 26px !important;
      visibility: invisible !important;
    }
  }

  &__filterAndResults {
    display: flex;
    flex-direction: row;
    background-color: white;
    // ########## UnComment #############
    // background-color: white;
    // ########## UnComment #############

    height: calc(100% - 60px) !important;
    // border-top: 12px solid #eff2fc !important;
    width: 100%;

    form {
      height: 100%;
    }

    &__filters {
      width: 250px;
      min-width: 250px;
      background-color: #fff !important;
      // ################# Comment #####################
      border-right: 12px solid #eff2fc !important;
      // ################# Comment #####################
      height: 100%;
    }
    &__container {
      width: 100%;
    }
  }
}
@media (max-width: 650px) {
  .newPlaybooks {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .proposals__datas {
        width: 100% !important;
      }
    }
    &__filterAndResults {
      &__filters {
        position: absolute;
        z-index: 99;
        width: 100%;
        box-shadow: 0px 0px 10px grey;
        border-right: 0px solid #eff2fc;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
      }
    }
  }
}
