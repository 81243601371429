.share-modal {
  
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &__input {
    margin-bottom: 20px;
    border-radius: 24px !important;
    padding: 12px 16px;
    border: 1px solid #d9d9d9;
    margin-bottom: 16px !important;
  }

  &__user {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
  }

  &__user-name {
    font-weight: 500;
    font-size: 14px;
  }

  &__user-email {
    font-size: 12px;
    color: #888;
  }

  &__status {
    font-size: 14px !important;
    color: #1d74d4 !important;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .share-modal__link {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      border: 0px !important;
      visibility: hidden;
    }

    .share-modal__done {
      background-color: #1d74d4;
      font-size: 14px;
      border-radius: 16px !important;
    }
  }
}

.share-modal__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  font-size: 16px;
  color: #8c8c8c; 
  background-color: #f9f9f9;
  border-radius: 8px; 
}

.share-modal__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; 
}

.share-modal__input-container {
  display: flex;
  gap: 10px;
  align-items: center;

  .share-modal__input {
    flex: 1;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    padding: 0 12px;
  }

  .share-modal__share-button {
    flex-shrink: 0;
    height: 40px;
    border-radius: 16px;
    margin-top: -16px;
    padding: 0 16px;
    font-weight: 500;
  }
}

