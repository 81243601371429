.ConnectorSettings {
  &__headerText {
    font-size: 15px;
    font-weight: bold;
    margin-left: 13px !important;
  }

  &__title {
    margin-top: 4px;
    margin-left: 1%;
    color: #a7b3d9;
  }
  &__titleSelected {
    margin-top: 4px;
    color: #001f3f;
    margin-left: 1%;
  }
  &__wrapper {
    margin: 30px 3% !important;
    width: 878px;
    display: flex !important;
    flex-wrap: wrap !important;

    @media (max-width: 768px) {
      width: 100%;
      margin: 20px 2%;
    }
  }

  &__input {
    width: 60% !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 5px !important;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__input2 {
    width: 60% !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 5px !important;
    pointer-events: none;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__heading {
    text-align: start;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: -3px !important;
    font-weight: bold;
  }

  &__topHeading {
    text-align: start;
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 8px !important;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__form {
    margin-left: 30px;
    margin-top: 30px;
    margin-right: 160px;

    @media (max-width: 768px) {
      margin: 20px;
    }
  }

  &__field {
    margin-top: 30px !important;

    @media (max-width: 768px) {
      margin-top: 20px !important;
    }
  }

  &__para {
    margin-top: 0px;
    margin-bottom: 4px;
    font-size: 12px;
    text-align: start;
    color: gray;
  }

  &__buttonsDiv {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    padding-bottom: 4%;
    margin-left: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
  }

  &__saveButton,
  &__deleteButton {
    font-size: 13px;
    height: 36px !important;
    width: 140px;
    font-weight: bold !important;
    border-radius: 6px !important;
    @media (max-width: 768px) {
      width: 40%;
    }
  }
}

.fixedBar {
  margin: 0% 0px 1% 3%;

  @media (max-width: 768px) {
    margin: 0;
  }
}

.ConnectorSettings__input.empty-input {
  border: 1px solid red;
}

.Fixed {
  width: 100% !important;
  background-color: white;
  padding: 12px 0px 0px 0px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  position: sticky !important;
  top: 0;
  z-index: 99;
  margin-bottom: 12px;
  border-bottom: 10px solid #eff2fc;

  @media (max-width: 768px) {
    padding: 10px 0;
  }

  &__title {
    margin-right: 1%;
    color: #a7b3d9;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  &__mainTitle {
    margin-left: 1%;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.Title {
  font-weight: bold;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
}
