.CSVOverviewCatalog {
  &__list {
    height: calc(100% - 4px);
    overflow-y: auto;
    margin-left: 35px !important;
    margin-top: 10px !important;
    margin-right: 42px !important;
    margin-bottom: 10px !important;

    &__row {
      // background-color: #eff2fc;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 67px;
      padding: 12px;
      border-bottom: 1px solid #a7b3d9;
      &--roundedTop {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &--roundedBottom {
        border-bottom-left-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-bottom: none;
      }

      &__icon {
        margin-right: 15px;
      }

      &__leftContent {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__fileName {
          font-size: 14px;
          font-weight: bold;
          color: #5c99df;
          margin-bottom: 0px;
        }
        &__description {
          font-size: 12px;
          color: #eeee;
          margin-bottom: 0px;

          &__blueLink {
            color: #56a5ff;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 3px;
            margin-right: 3px;
          }
          &__orangeLink {
            color: #fe943b;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 3px;
            margin-right: 3px;
          }
        }
      }

      &__status {
        min-width: 170px;
        max-width: 170px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        &__link {
          display: flex;
          font-size: 12px;
          font-weight: bold;
          color: #56a5ff;
          text-decoration: underline;
          cursor: pointer;
        }
        &__text {
          display: flex;
          font-size: 12px;
          color: #0f0f60;
          max-width: 170px;
          width: 170px;
          min-width: 170px;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          align-items: center;
          &__error {
            max-width: 140px;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
          &__tooltip {
            margin-left: 4px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 17px;
    margin-bottom: 12px;
    margin-top: 27px;

    &--first {
      margin-top: 0px;
    }

    &__title {
      color:#212728;
      font-size: 16px;
      font-weight: bold;
    }
    &__count {
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold;
      margin-left: 6px;
    }
    &__status {
      width: 182px;
      color:#212728 !important;
      font-size: 16px !important;
      font-weight: bold;
    }
  }

  &__headerText {
    margin: 1% 0% 2% 3%;
    font-size: 15px;
    font-weight: bold;
  }

  &__title {
    margin-left: 1%;
    margin-right: 1%;
    color: #a7b3d9;
  }

  &__slash {
    color: #a7b3d9;
  }

  &__mainTitle {
    margin-left: 1%;
    color: #0f0f60;
  }
  &__sourceText {
    margin-left: 30px;
    margin-bottom: -18px;
    margin-top: 35px;
    padding: 0px;
    color: rgb(71, 77, 102);
    font-size: 1.4rem;
    line-height: 2.75rem;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  &__bar {
    display: flex;
    justify-content: flex-end; /* Aligns items to the left */
    align-items: center; /* Vertically aligns the buttons */
    gap: 10px; /* Adjust spacing between the buttons */
    margin-right: 2% !important;
  }
  
  &__leftSection {
    display: flex;
    align-items: center;
    gap: 15px; /* Adjust space between buttons */
  }
  &__input {
    width: 230px !important;
    border-radius: 5px !important;
    padding: 6px 10px !important;
  }

  &__button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 35px !important;
    width: 120px;
    border-radius: 30px !important;
    background-color: #3972ce !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    margin-right: 20px !important;
  }

  &__newSource {
    margin: 1% -6% 1% 3%;
    font-size: 15px;
    font-weight: bold;
  }

  &__newText {
    border-radius: 6px !important;
    background-color: #e3e3e3 !important;
    color: #6b7a7e !important;
    border: none !important;
    width: 75px !important;
    font-size: 12px !important;
  }

  &__exploreBar {
    display: flex;
    justify-content: space-between;
  }

  &__exploreText {
    margin: 0% 0% 0% 3%;
    font-size: 14px;
    font-weight: bold;
    color: #6e7a7d;
  }
  &__bottomBar {
    height: 40px;
    background: #fafafa !important;
    margin-top: 2%;
  }

  &__mainContainer {
    background-color: #fafafa !important;
    padding: 2% 4% 4% 3%;
    height: 450px !important;
  }
  &__flexDiv {
    display: flex;
    background-color: white;
    border-radius: 8px;
    padding: 28px;
  }

  &__container1 {
    width: 45%;
  }

  &__container2 {
    width: 55%;
    //margin-top: 5%;
  }

  &__cardHeading1 {
    font-size: 15px;
    font-weight: bold;
  }

  &__cardHeading2 {
    font-size: 15px;
    font-weight: bold;
    margin-left: 3%;
  }

  &__textFlex {
    display: flex;
    margin-top: 3%;
  }

  &__cardText {
    font-size: 14px;
    margin-top: 1%;
    margin-left: 2%;
  }

  &__rawText {
    font-size: 14px;
    margin-top: 2%;
  }

  &__anchorTag {
    color: #1d74d4;
    border-bottom: 1px solid #1d74d4;
    margin-left: 2px;
  }

  &__setText1 {
    width: 100%;
    font-size: 14px;
    margin-left: 3%;
    margin-top: 3%;
  }

  &__line {
    border-left: 1px solid #c5d0d3;
    height: 108px;
  }

  &__containFlex {
    display: flex;
  }

  &__flexDiv1 {
    background-color: white;
    border-radius: 8px;
    padding: 22px;
    height: 400px;
  }

  &__container3 {
    width: 100%;
  }

  &__overviewHeading1 {
    font-size: 15px;
    font-weight: bold;
  }

  &__overviewText {
    font-size: 14px;
    margin-top: 2%;
    //margin-left: 4%;
    width: 88%;
  }

  &__Fixed {
    width: 100% !important;
    background-color: white;
    padding: 12px 0px 0px 0px !important;
    position: sticky !important;
    top: 0;
    z-index: 99;
  }

  &__createsourceDiv {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  &__inprogressUploads {
    margin-left: 20px !important; /* Adjust this value for more/less space on the left */
    margin-right: 20px !important; /* Adjust this value for more/less space on the right */
  }
}

.unprocessedFiles {
  height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 17px;
    margin-bottom: 12px;
    margin-top: 27px;

    &--first {
      margin-top: 0px;
    }

    &__title {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
    }
    &__count {
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold;
      margin-left: 6px;
    }
    &__status {
      width: 182px;
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__list {
    height: calc(100% - 4px);
    overflow-y: auto;

    &__continueRow {
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 50px;
      padding: 12px;
      border-bottom: 1px solid #ffffff;
      &__button {
        width: 120px !important;
        border: none !important;
        height: 32px !important;
        text-align: center !important;
        border-radius: 15px !important;
        font-size: 14px !important;
        font-weight: bold !important;
        float: right !important;
        margin-right: 50px !important;
        color: white !important;
        background-color: #98d650 !important;
        display: flex;
        align-items: center;
        justify-content: center; // This centers the text horizontally
      }
    }

    &__row {
      background-color: #eff2fc;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 67px;
      padding: 12px;
      border-bottom: 1px solid #a7b3d9;
      &--roundedTop {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &--roundedBottom {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-bottom: none;
      }

      &__icon {
        margin-right: 15px;

        &__step {
          width: 32px;
          height: 32px;
          background-color: #0f0f60;
          border-radius: 16px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__leftContent {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__fileName {
          font-size: 14px;
          font-weight: bold;
          color: #5c99df;
          margin-bottom: 0px;
        }
        &__description {
          font-size: 12px;
          color: #0f0f60;
          margin-bottom: 0px;

          &__blueLink {
            color: #56a5ff;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 3px;
            margin-right: 3px;
          }
          &__orangeLink {
            color: #fe943b;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 3px;
            margin-right: 3px;
          }
        }
      }

      &__continueBtn {
        background-color: #98d650 !important;
        width: 127px !important;
        height: 30px !important;
        border: none !important;
        text-align: center;
        border-radius: 15px !important;
        font-size: 12px !important;
        font-weight: bold !important;
        float: right;
        color: white !important;
        outline: none !important;
        margin-top: 8px;
      }
      &__status {
        min-width: 170px;
        max-width: 170px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        &__link {
          display: flex;
          font-size: 12px;
          font-weight: bold;
          color: #56a5ff;
          text-decoration: underline;
          cursor: pointer;
        }
        &__text {
          display: flex;
          font-size: 12px;
          color: #0f0f60;
          max-width: 170px;
          width: 170px;
          min-width: 170px;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          align-items: center;
          &__error {
            max-width: 140px;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
          &__tooltip {
            margin-left: 4px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

