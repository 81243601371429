.EditNavbar {
    display: flex;
    flex: 1;
    width: 100%;
    height: 85%;
    background-color: white;
    flex-direction: column;
    overflow-y: scroll;
}

.mainHeading {
    font-size: 18px;
    font-weight: bold;
    margin: 1% 0px 0px 3%;
}

.headerOptions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.titleDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 6px 36px 6px 30px;
    background-color: #e6e8f1;
    padding: 8px 46px 8px 19px;
}

.span1 {
    font-weight: bold;
}

.iconBellEye {
    font-weight: bold;
    margin-left: 16%;
    height: 22px !important;
}

.iconBellEdit {
    font-weight: bold;
    height: 22px !important;
}

.Width {
    width: 51%;
}

.SectionIcon {
    margin-right: 4%;
    position: relative;
    bottom: 2px;
}

.divFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    margin-left: 50px;
    margin-right: 95px;
}

.divFlex2 {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    /* margin-left: 9%;
    margin-right: 10.8%; */
    margin-left: 50px;
    margin-right: 95px;
}

.mainText {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #0f0f60;
    width: 51%;
}

.subText {
    font-size: 13px !important;
    line-height: 17px !important;
    color: #0f0f60;
    margin-left: -1.5rem;
    width: 49.5%;
}

.divBorder {
    padding-bottom: 5% !important;
    padding-right: 44% !important;
    margin-left: 8%;
    border-bottom: 1px solid #e6e8f1;
}

.iconText {
    width: 51%;
}

.labelFlex {
    display: flex;
}

.labelFlex2 {
    display: flex;
    margin-left: 15%;
}

.inputSpace {
    width: 200px;
    padding: 4px;
    height: 30px;
    border: 1px solid #a7b3d9 !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
}

.eyeIcon {
    margin-top: 6px;
    font-size: 20px;
}

.buttonsDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 16px;
}

.saveChangesButton {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 36px !important;
    width: 130px;
    border-radius: 16px !important;
    background-color: #56a5ff !important;
    color: white;
    border: 0px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
}