.SalesforceDestinationDetails {
    &__headerText {
        margin: 1% 0% 2% 3%;
        font-size: 15px;
        font-weight: bold;
    }

    &__title {
        margin-left: 1%;
        margin-right: 1%;
        color: #A7B3D9;
    }

    &__slash {
        color: #A7B3D9;
    }

    &__mainTitle {
        margin-left: 1%;
        color:#0f0f60
      }
    &__sourceText {
        margin-left: 30px;
        margin-bottom: -18px;
        margin-top: 35px;
        padding: 0px;
        color: rgb(71, 77, 102);
        font-size: 1.4rem;
        line-height: 2.75rem;
        font-weight: 600;
        letter-spacing: -0.02em;
      }

    &__bar {
        display: flex;
        justify-content: flex-end;
        margin-right: 4%;
    }

    &__input {
        width: 230px !important;
        border-radius: 5px !important;
        padding: 6px 10px !important;
    }

    &__button {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center !important;
        height: 35px !important;
        border-radius: 30px !important;
        background-color: #3972CE !important;
        color: white !important;
        border: 0px !important;
        font-size: 14px !important;
        font-weight: bold !important;
        line-height: 17px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    }

    &__newSource {
        margin: 1% 0% 1% 3%;
        font-size: 15px;
        font-weight: bold;
    }

    &__newText {
        border-radius: 6px !important;
        background-color: #E3E3E3 !important;
        color: #6B7A7E !important;
        border: none !important;
        width: 90px !important;
        font-size: 12px !important;
    }

    &__exploreBar {
        display: flex;
        justify-content: space-between;
    }

    &__exploreText {
        margin: 0% 0% 0% 3%;
        font-size: 14px;
        font-weight: bold;
        color: #6E7A7D;
    }

    &__bottomBar {
        height: 40px;
        background: #FAFAFA !important;
        margin-top: 2%;
      }
    
      &__mainContainer {
        background-color: #FAFAFA !important;
        padding: 2% 4% 4% 3%;
      }

    &__flexDiv {
        display: flex;
        background-color: white;
        border-radius: 8px;
        padding: 22px;
    }

    &__container1 {
        width: 45%;
    }

    &__container2 {
        width: 55%;
        //margin-top: 5%;
    }

    &__cardHeading1 {
        font-size: 15px;
        font-weight: bold;
    }

    &__cardHeading2 {
        font-size: 15px;
        font-weight: bold;
        margin-left: 3%;
    }

    &__textFlex {
        display: flex;
        margin-top: 3%;
    }

    &__cardText {
        font-size: 14px;
        margin-top: 1%;
        margin-left: 2%;
    }

    &__rawText {
        font-size: 14px;
        margin-top: 2%;
    }

    &__anchorTag {
        color: #1D74D4;
        border-bottom: 1px solid #1D74D4;
        margin-left: 2px;
    }

    &__setText1 {
        font-size: 14px;
        margin-left: 3%;
        margin-top: 3%;
    }

    &__line {
        border-left: 1px solid #c5d0d3;
        height: 108px;
    }

    &__containFlex {
        display: flex;
    }

    &__flexDiv1 {
        background-color: white;
        border-radius: 8px;
        padding: 22px;
        margin-top: 6%;
    }

    &__container3 {
        width: 100%;
    }

    &__overviewHeading1 {
        font-size: 15px;
        font-weight: bold;
    }

    &__overviewText {
        font-size: 14px;
        margin-top: 2%;
        //margin-left: 4%;
        width: 88%;
    }

    &__Fixed {
        width: 100% !important;
        background-color: white;
        padding: 12px 0px 0px 0px !important;
        position: sticky !important;
        top: 0;
        z-index: 99;
    }

}