.AvailableFlows {
  // .th.ant-table-cell {
  //     font-weight: bold !important;
  //     // background-color: #E5F9FE !important;
  // }
  th.ant-table-cell {
    background: #e5f9fe !important;
    font-weight: bold !important;
  }

  &__headerText {
    margin: 0% 0% 2% 2%;
    font-size: 15px;
    font-weight: bold;
    padding-top: 2%;
  }

  &__title {
    margin-left: 2%;
  }

  &__sourceText {
    margin: 3% 0% 2% 3%;
    font-size: 15px;
    font-weight: bold;
  }

  &__bar {
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
  }

  &__input {
    width: 230px !important;
    border-radius: 5px !important;
    margin-right: 1% !important;
  }

  &__button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 35px !important;
    border-radius: 6px !important;
    background-color: #1c74d4 !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }

  &__tableDiv {
    margin: 4% 4% 0% 3%;
  }

  &__btnImage {
    margin-left: -28%;
  }

  &__inactive-button {
    //display: flex;
    align-items: center;
    cursor: pointer;
    border: none !important;
    height: 30px !important;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    margin-right: 0px !important;
    color: #6b7a7e !important;
    background-color: #e3e3e3 !important;
    //float: left;
    width: 90px;
  }
  &__active-button {
    //display: flex;
    align-items: center;
    cursor: pointer;
    border: none !important;
    height: 30px !important;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    margin-right: 0px !important;
    color: #1d74d4 !important;
    background-color: #e5f9fe !important;
    //float: left;
    width: 90px;
  }
}

.ant-col-24.ant-form-item-label {
  padding: 0px !important;
}

.rounded-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-col-24.ant-form-item-label > label {
  font-weight: bold !important;
  color: #07074b !important;
  font-size: 14px !important;
}

.ant-modal-title {
  font-weight: 700 !important;
  color: #07074b !important;
  font-size: 18px !important;
}

.statusFlex {
  // display: flex;
  width: 90px;
}

.Status {
  width: 12px;
  height: 12px;
  background-color: #84d830;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%;
}

.InActiveStatus {
  width: 12px;
  height: 12px;
  background-color: #c14e49;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%;
}

.nameFlex {
  display: flex;
  justify-content: space-between;
}

.nameText {
  width: 75%;
}
