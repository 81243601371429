.sourceName {
  height: 100%;

  &__heading {
    color: #0f0f60;
    font-size: 14px;
    font-weight: bold;
    line-height: 23px;
    background-color: #eff2fc;
    padding: 19px 16px;
    border-radius: 8px;
    margin-bottom: 0px;
  }

  &__radioGroup {
    display: flex !important;
    flex-direction: column !important;

    .ant-radio-wrapper {
      display: flex !important;
      align-items: center !important;
      width: fit-content !important;
    }
    .ant-radio {
      margin-top: -5px !important;
    }
    span {
      font-size: 12px !important;
      font-weight: bold !important;
      color: #0f0f60 !important;
    }
    .ant-radio-inner {
      border-color: #0f0f60 !important ;
    }
    .ant-radio-checked .ant-radio-inner:after {
      background-color: #0f0f60;
    }

    &__image {
      height: 32px !important;
      width: 32px !important;
      padding: 8px !important;
    }
  }
  &__eventsType {
    margin-top: -8px !important;
    margin-left: 16px !important;
    margin-bottom: 18px !important;

    .ant-select-selector {
      border: 1px solid #0f0f5c !important;
      width: 300px !important;
      border-radius: 0px !important;
      background-color: white !important;
      color: #0f0f60 !important;
      font-size: 13px !important;
      font-weight: bold !important;
      .ant-select-selection-item {
        font-size: 13px !important;
        font-weight: bold !important;
      }
    }
  }
  &__divider {
    background-color: #a6b3d9 !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .ant-form-item {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 16px;
  }
  .ant-form-item-label {
    min-width: 120px;
    text-align: left;
    label {
      font-size: 12px;
      font-weight: 500;
      color: #0f0f60 !important;
    }
  }
  .ant-input {
    height: 32px !important;
    border: 1px solid #0f0f5c !important;
    border-radius: 0px !important;
    max-width: 300px !important;
    font-size: 12px !important;
    font-weight: bold !important;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    &__error {
      color: #ff0000;
      font-size: 12px;
      line-height: 15px;
    }
    &__continueBtn {
      background-color: #98d650 !important;
      width: 127px !important;
      height: 30px !important;
      border: none !important;
      text-align: center;
      border-radius: 15px !important;
      font-size: 12px !important;
      font-weight: bold !important;
      float: right;
      color: white !important;
      outline: none !important;
    }
  }
}
