.filterSingleSelectionDropdown {
  margin-bottom: 12px !important;

  .ant-select-selector {
    border-color: #a7b3d9 !important;
    color: #0f0f60 !important;
    font-size: 12px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #a7b3d9 !important;
  }
  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
    width: 117px !important;
  }
  .ant-select-selection-item {
    background-color: white !important;
    color: #0f0f60 !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }
}
