.mynewsinglePlaybook {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;

  &__IMG1 {
    border-radius: 8px;
    width: 100% !important;
    border: 1px solid #a9b3d6;
  }
  &__IMG1:hover {
    cursor: pointer;
  }

  &__appHomeButton {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 16px 4px 16px;
  }
  &__appAvatar {
    width: 60px !important;
    height: 60px !important;
    margin-right: 14px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &__appHomeMainHead {
    font-weight: bold;
    font-size: 20px;
    margin-left: 16px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #07074b;
    margin-right: 10px;
    margin-top: 0px;
    width: 192px;
  }
  &__appHomeMainHead:hover {
    cursor: pointer;
  }

  &__appHomeSubHead {
    font-size: 14px;
    color: #a7b3d9;
    margin-left: 16px;
    line-height: 15px;
    font-size: 14px;
    width: 200px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__appHomeSubHead:hover {
    cursor: pointer;
  }

  &__appDotsIcon {
    font-size: 24px;
    cursor: pointer;
    // margin-left: 100px;
    font-weight: bold;
    color: black !important;
    margin-top: 0px;
    padding-left: 100px;
  }

  &__P1 {
    padding-left: 16px !important;
    margin-bottom: 8px !important;
    font-size: 13px !important;
    color: #a7b3d9;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__P2 {
    padding-left: 16px !important;
    margin-top: 0px !important;
    font-size: 16px !important;
    font-weight: bold;
    color: #07074b;
    margin-bottom: 0px !important;
    display: flex;
    img {
      margin-right: 8px;
      margin-bottom: 2px;
      vertical-align: middle;
    }
    &__Icon {
      margin-right: 4px;
      margin-top: 4px;
    }
    &__Low {
      margin-left: auto !important;
      margin-right: 12px !important;
    }
  }
  &__H1 {
    margin-left: 16px !important;
    margin-top: 6px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #07074b;
    margin-bottom: 0px !important;
    margin-right: 2px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__H1:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &__proposalDetails__reviewsInfo {
    color: #fe943b !important;
    font-size: 16px !important;
    margin-left: 16px;
    font-weight: bold !important;
    &__count {
      color: #a7b3d9 !important;
      font-size: 16px !important;
      margin-left: 4px;
    }
  }

  .ant-card {
    width: 100%;
    border-radius: 8px;
    .ant-card-bordered {
      border-radius: 8px !important;
      .ant-card-hoverable {
        //   border-radius: 16px !importante;
      }
    }
  }

  .singleCategoryPlaybooks {
    margin: 0px auto !important;
    padding: 0px !important;
  }
  .ant-card-body {
    padding: 0px !important;
    border-radius: 8px !important;
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  &__customAvatar {
    &__avatar {
      height: 45px !important;
      width: 45px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
}
@media (max-width: 425px) {
  .mynewsinglePlaybook {
    width: 100%;
    .ant-row {
      flex-direction: column !important;
      row-gap: 30 !important;
    }
    .ant-card-cover img {
      height: 400px !important;
      width: 100% !important;
    }
    .ant-card {
      width: 100% !important;
    }
    &__appDotsIcon {
      padding-left: 135px;
    }
  }
}
@media (max-width: 375px) {
  .mynewsinglePlaybook {
    width: 100%;
    &__appDotsIcon {
      padding-left: 26% !important;
    }
  }
}
@media (max-width: 325px) {
  .mynewsinglePlaybook {
    width: 100%;
    &__appDotsIcon {
      padding-left: 15% !important;
    }
  }
}
