.sideFilters {
  height: 100%;

  &__heading {
    background-color: #F2F2F2;
    border-radius: 8px !important;
    margin: 8px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    &__label {
      color: #000000 !important;
      font-size: 12px;
      font-weight: bold;
    }
    &__cross {
      cursor: pointer;
    }
  }
  &__scrollableContent {
    height: calc(100% - 120px);
    margin: 8px !important;
    border-radius: 8px !important;
    border: 1px solid #DFDFDF ;
    overflow-y: scroll;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #DFDFDF;
    height: 60px;

    &__resetBtn {
      width: 127px !important;
      height: 30px !important;
      background-color: #3f97fc !important;
      border-radius: 15px !important;
      color: white !important;
      font-size: 12px !important;
      font-weight: bold !important;
      line-height: 26px !important;
      border: 1px solid #3f97fc !important;
      outline: none !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
}
