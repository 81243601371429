.selectSource {
  width: 100% !important;

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }

    &-image {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    &-mainHeading {
      flex: 1;
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0px 0px 0px 8px;
    }
    &-subHeading {
      flex: 1;
      font-family: Lato;
      font-weight: normal;
      font-size: 10pt;
      text-align: left;
      margin: 0px 0px 0px 8px;
    }
  }

  &__list-item {
    padding: 0;
  }

  &__card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 12px;
    border-radius: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 80px;
  }

  &__card-content {
    display: flex;
    align-items: center;
    width: 100%;

    &__radio {
      margin-left: 16px !important;
      border-color: #56a5ff;

      .ant-radio-inner {
        &::after {
          background-color: #56a5ff;
        }
      }
    }

    &__image {
      margin-left: 8px;
      width: 30px;
      height: 30px;
    }

    &__text {
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;

      &--center {
        justify-content: center;
      }

      &__primary {
        margin: 0;
        font-family: "Lato, sans-serif";
        font-weight: 400;
        font-size: 14pt;
        color: #212121;
      }

      &__secondary {
        margin: 0;
        font-family: "Lato, sans-serif";
        font-weight: 300;
        font-size: 12pt;
        color: #07074b;
      }
    }
  }

  .ant-collapse-header {
    padding-right: 32px !important;
  }

  .ant-collapse-arrow {
    display: none !important; // Hide the expand icon
  }
}
