.filterRatingDropdown {
  margin-bottom: 12px !important;

  .ant-select-selector {
    border-color: #cecece !important;
    color: #000000 !important;
    font-size: 12px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #a7b3d9 !important;
  }
  label {
    color: #000000 !important;
    font-size: 12px !important;
    width: 117px !important;
  }
  .ant-select-selection-item {
    background-color: white !important;
    color: #000000 !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &__stars {
      .ant-rate-star {
        color: #f2a841 !important;
        font-size: 14px !important;
      }
    }
    &__text {
      font-size: 12px !important;
      color: #0f0f60 !important;
      text-align: center !important;
      margin-top: 4px;
      margin-left: 8px;
      font-weight: bold;
    }
  }
}
