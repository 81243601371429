.createPlaybook {
  height: 100%;
  overflow-y: scroll;
  background-color: #eff2fc !important;

  input {
    border-radius: 4px !important;
    color: #0f0f60 !important;
    font-size: 14px !important;
    height: 28px !important;
  }

  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
    width: 100% !important;
  }

  .ant-input-affix-wrapper {
    height: 34px !important;
    max-height: 34px !important;
    border-radius: 4px !important;
    color: #0f0f60 !important;
    border-color: #a7b3d9;
    font-size: 14px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: 24px !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    border-color: #a7b3d9 !important;
    color: white !important;
    font-size: 14px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #56a5ff !important;
  }

  .ant-select-selection-item {
    background-color: #56a5ff !important;
    color: white !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    min-width: 120px;
    text-align: left;
    label {
      font-size: 12px;
      color: #0f0f60 !important;
    }
  }
  .ant-input {
    height: 34px !important;
    border: 1px solid #a7b3d9;
    border-radius: 4px;
    max-width: 100%;
  }

  &__mainContainer {
    background-color: #eff2fc;
  }

  &__addGeneralDetails {
    background-color: white !important;
    padding: 16px;
    border-radius: 8px;
    &__heading {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
    }

    &__subHeading {
      color: #a7b3d9;
      font-size: 12px;
    }
    &__divider {
      height: 1px !important;
      background: #eff2fc !important;
      margin-top: 12px !important;
    }

    &__tagsContainer {
      display: flex !important;
      &__primary {
        width: 10%;
      }

      &__custom {
        margin-left: 20px !important;
        width: 90% !important;
      }
    }

    &__categoryLanguageContainer {
      display: flex !important;

      &__category {
        width: 100% !important;
      }
      &__category {
        width: 100% !important;
      }
      &__location {
        width: 100% !important;
        margin-left: 20px !important;
      }

      &__language {
        width: 100% !important;
        margin-left: 20px !important;
      }
    }
  }

  &__addDescriptionContainer {
    background-color: white !important;
    margin-top: 16px !important;
    padding: 16px;
    border-radius: 8px;
    font-weight: bold !important;
  }

  &__addHighlights {
    background-color: white !important;
    margin-top: 16px !important;
    padding: 16px;
    border-radius: 8px;
    font-weight: bold !important;
    &__singleRow {
      display: flex !important;
      &__leftSide {
        width: 100% !important;
      }
      &__rightSide {
        width: 100% !important;
        margin-left: 20px !important;
      }
    }
  }

  &__addPackageContainer {
    background-color: white !important;
    margin-top: 16px !important;
    padding: 16px;
    border-radius: 8px;
  }

  &__uploadPromotionalMediaContainer {
    background-color: white !important;
    margin-top: 16px !important;
    padding: 16px;
    // display: none !important;
    border-radius: 8px;
  }

  &__uploadPromotionalMedia {
    background-color: #eff2fc;
    padding: 8px;
    display: flex;
    &__leftSide {
      width: 90%;

      &__heading {
        color: #0f0f60;
        font-size: 14px;
        font-weight: bold;
      }

      &__subHeading {
        margin-top: 10px;
        color: #a7b3d9;
        font-size: 14px;
      }
    }
    &__rightSide {
      width: 10%;
      &__image {
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: block;
      }
    }
  }

  &__saveSourceButton {
    width: 120px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-top: 24px;
    margin-right: 20px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    float: right;
    color: white !important;
    background-color: #84d830 !important;
  }
}

.dropdown {
  width: 100%;
  margin: 10px 0px 0px !important;

  .ant-select-selector {
    border-radius: 2px !important;
    border-color: #a7b3d9 !important;
    color: #0f0f60 !important;
    font-size: 12px !important;
  }
  .ant-select-arrow {
    color: #0f0f60 !important;
  }

  .ant-select-selection-item {
    color: #0f0f60 !important;
  }

  .ant-select-selection-item-remove {
    color: #0f0f60 !important;
  }
  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
  }
}

.primaryTag {
  .ant-select-selection-item {
    background-color: white !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }

  .ant-select-selector {
    border-radius: 4px !important;
    border-color: #a7b3d9 !important;
    font-size: 12px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #0f0f60 !important;
  }
}

.categoryDropdown {
  margin-top: 10px !important;
  .ant-select-selector {
    border-color: #a7b3d9 !important;
    color: #0f0f60 !important;
    font-size: 14px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #0f0f60 !important;
  }
  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
  }
  .ant-select-selection-item {
    background-color: white !important;
    color: #0f0f60 !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }
}
.customcategoryDropdown{
  margin-top: 10px !important;
  &__tagsContainer {
    display: flex !important;
    &__primary {
      width: 10%;
    }

    &__custom {
      margin-left: 20px !important;
      width: 90% !important;
    }
  } 
}
:root {
  --dynamicColor: black;
}
$dynamicColor: var(--dynamicColor);

.primaryInput {
  border-radius: 4px !important;
  font-size: 14px !important;
  height: 28px !important;

  .ant-input {
    color: $dynamicColor !important;
  }
}
