.chatRightNav {
  display: flex;
  flex-direction: column;
  width: 234px !important;
  height: calc(100% - 8px)!important;
  float: right;

  &__rightNavChatUIDiv {
    display: flex;
    width: 100%;
    height: calc(100% - 12px) !important;
    margin-top: 4px;
    background-color: white;
    border-radius: 8px;
  }
}
