.signupPhone {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: calc(100% - 180px);

  &__container {
    height: 100%;
  }

  &--withoutShadow {
    box-shadow: unset;
    padding: unset;
  }

  &__signinFields {
    width: 304px;
  }

  &__headerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  &__logo {
    display: flex;
    width: 80px;
    height: 80px;
  }

  &__headerTitle {
    display: flex;
    font-size: 24px;
    line-height: 36px;
    word-spacing: -1.74px !important;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    color: #000000 !important;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__footerText {
    font-weight: bold;
    color: white;
    background: white;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    font-weight: normal;
    justify-content: flex-end;
    width: 100vw;
    background-color: white !important;
    margin-top: 63px;
  }
  &__footer {
    display: flex;
    align-items: center;
    font-weight: normal;
    justify-content: center;
    width: 100vw;
  }
  &__inputField {
    min-width: 300px;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    border: 1px solid #E4E4E4 !important; /* Add border with primary color */
    border-radius: 8px !important; /* Adjust as needed for rounded corners */
    padding: 4px; /* Space between the border and input */

    input{
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    }
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
  }
  &__googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 32px;
  }

  &__continueButton {
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 38px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #3872CD !important;
    color: white;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__button {
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    min-width: 128px;
    margin-right: 43px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: white !important;
    border: 1px solid #3872CD !important;
    color: #3872CD !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__footer {
    font-weight: normal;
  }
  &__linkButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
  }
  &__googleButton {
    cursor: pointer;
  }
  &__textDonthaveAccount {
    color: #8B8B8B !important;
    font-size: 14px !important;
    line-height: 15px !important;
    margin-right: 8px;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 0px;
  }

  .ant-btn-primary {
    border: 0px;
  }

  &__errorMessageContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-top: 4px;
    margin-bottom: 16px;
    background-color: #FDECEB;
  }

  &__errorContainerCrossImage{
    width: 20px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
  }

  &__textErrorMessage{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    padding-left: 6px;
  }
}

.signupPhone__inputField {
  min-width: 300px;
  height: 32px;
  font-size: 14px !important;
  line-height: 13px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  border: 1px solid #E4E4E4 !important; /* Border color */
  border-radius: 8px !important; /* Rounded corners */
  display: flex;
  align-items: center;
  padding: 0 8px; /* Padding for spacing inside */

  /* Style the input field inside PhoneInput */
  input {
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    border: none; /* Remove default border */
    outline: none; /* Remove focus outline */
    width: 100%; /* Full width */
  }

  /* Style the dropdown (flag selector) */
  .flag-dropdown {
    background-color: #f5f5f5;
    border-right: 1px solid #E4E4E4; /* Same border color */
    padding-right: 8px;
    border-radius: 8px 0 0 8px; /* Match radius for rounded border */
  }

  /* Placeholder styling for input field */
  input::placeholder {
    color: #a0a0c0 !important; /* Light gray placeholder color */
    opacity: 0.8;
  }
}


