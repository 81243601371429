.MobilePreviewCard {
  padding-top: 12px !important;
  padding-bottom: 0px !important;
  // padding-left: 8px;
  // padding-right: 8px;
  width: 100%;

  .ant-avatar-image {
    // margin-top: 12px;
  }

  .anticon svg {
    color: #a7b3d9;
  }
  &__Row {
    // width: 33%;
    // margin-left: 32%;
    width: 98%;
    margin-left: 1%;
    // height: 181px;
  }
  &__Dollar {
    text-align: center;
    font-size: 22px;
  }
  &__Row-2 {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  &__Col {
    width: 100%;
    // height: 106px;
  }
  &__IMG1 {
    border-radius: 8px;
    width: 100% !important;
    margin-left: 14px;
    border: 1px solid #a9b3d6;
  }
  &__IMG1:hover {
    cursor: pointer;
  }

  &__appHomeButton {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 16px 4px 16px;
  }
  &__appAvatar {
    width: 60px !important;
    height: 60px !important;
    margin-right: 14px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &__appHomeMainHead {
    font-weight: bold;
    font-size: 20px;
    margin-left: 16px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #07074b;
    margin-right: 10px;
    margin-top: 0px;
    width: 176px;
  }
  &__appHomeMainHead:hover {
    cursor: pointer;
  }

  &__appHomeSubHead {
    font-size: 14px;
    color: #a7b3d9;
    margin-left: 16px;
    line-height: 15px;
    font-size: 14px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__appHomeSubHead:hover {
    cursor: pointer;
  }

  &__P1 {
    //   padding-left: 16px !important;
    margin-bottom: 8px !important;
    font-size: 12px !important;
    color: #a7b3d9;
    line-height: normal;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  &__P2 {
    padding-left: 16px !important;
    margin-top: 0px !important;
    font-size: 16px !important;
    font-weight: bold;
    color: #07074b;
    margin-bottom: 0px !important;
    display: flex;
    &__Icon {
      margin-right: 4px;
      margin-top: 4px;
    }
    &__Low {
      // margin-left: auto !important;
      // margin-right: 12px !important;
      // text-align: end;
      // position: absolute;
      // top: 14px;
      // left: 78px;
      // line-height: normal;
      &__As {
        font-size: 10px;
        /* margin-right: -24px; */
        position: absolute;
        font-weight: lighter;
        top: 38px;
        left: 34px;
      }
      &__Price {
        // font-size: 20px;
        display: flex;
        position: absolute;
        top: 27px;
        left: 88px;
        font-size: 20px;
        font-weight: normal;

        &__appDotsIcon {
          position: absolute;
          bottom: 4px;
          left: 50px;
          font-size: 32px;
          font-weight: bold;
          // color: #A7B3D9;
        }
      }
    }
    &__Read {
      // margin-top: 12px;
      margin-left: auto !important;
      margin-right: 8px;
      margin-top: 0px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      background-color: #eff2fc;
      padding: 6px;
      border-top-left-radius: 16px;
    }
  }
  &__H1 {
    margin-left: 0px !important;
    line-height: normal;
    font-size: 16px !important;
    // font-weight: bold !important;
    color: #07074b;
    margin-bottom: 0px !important;
    margin-right: 2px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  &__H1:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &__proposalDetails__reviewsInfo {
    color: #fe943b !important;
    font-size: 14px !important;
    margin-left: 0px;
    font-weight: bold !important;
    // margin-top: -4px;
    position: absolute;
    top: 82px;
    &__count {
      color: #a7b3d9 !important;
      font-size: 14px !important;
      margin-left: 4px;
    }
    .anticon svg {
      font-size: 16px;
    }
  }

  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 6px !important;
  }

  &__customAvatar {
    &__avatar {
      height: 45px !important;
      width: 45px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      margin-top: 12px;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
}

@media (max-width: 425px) {
  .MobilePreviewCard {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .MobilePreviewCard {
    width: 100%;
    .ant-avatar-image {
      margin-top: 0px;
    }
    &__proposalDetails__reviewsInfo {
      position: absolute;
      top: 85px;
    }
    &__P2 {
      &__Read {
        margin-top: 6px;
      }
      &__Low {
        // position: absolute;
        // top: 14px;
        // left: 58px;
        &__As {
          font-size: 10px;
          /* margin-right: -24px; */
          position: absolute;
          font-weight: lighter;
          top: 38px;
          left: 22px;
        }
        &__Price {
          display: flex;
          position: absolute;
          top: 27px;
          left: 76px;
          font-size: 20px;
          font-weight: normal;
          &__appDotsIcon {
            position: absolute;
            bottom: 4px;
            left: 48px;
            font-size: 32px;
            font-weight: bold;
            // color: #A7B3D9;
          }
        }
      }
    }
    // &__appDotsIcon {
    //   padding-left: 26% !important;
    // }
    &__appHomeMainHead {
      width: 160px;
      margin-top: 0px;
    }
  }
}

@media (max-width: 325px) {
  .MobilePreviewCard {
    width: 100%;
    .ant-avatar-image {
      // margin-top: -18px;
    }
    &__P2 {
      &__Read {
        // margin-top: 6px;
      }
      &__Low {
        // position: absolute;
        // top: 14px;
        // left: 35px;
        &__As {
          font-size: 10px;
          /* margin-right: -24px; */
          position: absolute;
          font-weight: lighter;
          top: 38px;
          left: 5px;
        }
        &__Price {
          display: flex;
          position: absolute;
          top: 27px;
          left: 58px;
          font-size: 20px;
          font-weight: normal;
        }
      }
    }
    &__H1 {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    &__appHomeSubHead {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      width: 100px !important;
    }
    &__proposalDetails__reviewsInfo {
      // margin-top: 18%;
      position: absolute;
      top: 66px;
    }
    &__appHomeMainHead {
      width: 100px;
      // margin-top: -20px;
    }
  }
}
