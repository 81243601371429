.Alysongreetings {
  height: 236px !important;
  width: 355px !important;
  margin-top: 8px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 6px #00000042 !important;
  background-color: #fafafa !important;
}

.Alysongreetingscardtop {
  display: flex !important;
  width: 100% !important;
}
.Greetingsimage {
  height: 66px !important;
  width: 66px !important;
  margin-top: 26px !important;
  margin-left: 16px !important;
}
.Greetingsheading {
  font-size: 24px !important;
  font-family: "Lato";
  color: #07074b;
  margin-left: 16px;
  margin-top: 26px;
}
.Alysongreetingcardbottom {
  display: flex;
  flex-direction: column;
}
.Greetingspara {
  // margin-top: 8px;
  margin-right: 20px !important;
  margin-left: 98px !important;
  font-size: 13px !important;
}
.greetingsdismissbutton {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  height: 35px !important;
  width: 315px !important;
  border-radius: 18px !important;
  background-color: #1c74d4 !important;
  color: white !important;
  border: 0px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 17px !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  margin-left: 20px;
  margin-top: 8px;
}
