.share-source-modal {
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &__input {
    margin-bottom: 20px;
    border-radius: 24px !important;
    padding: 12px 16px;
    border: 1px solid #d9d9d9;
    margin-bottom: 16px !important;
  }

  &__user {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
  }

  &__user-name {
    font-weight: 500;
    font-size: 14px;
  }

  &__user-email {
    font-size: 12px;
    color: #888;
  }

  &__status {
    font-size: 12px;
    color: #000;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .share-source-modal__link {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      border: 0px !important;
    }

    .share-source-modal__done {
      background-color: #1d74d4;
      font-size: 14px;
      border-radius: 16px !important;
    }
  }
}
