.mySellerDetails {
  &__rowDivision {
    float: right !important;
    display: flex !important;
    &__leftSide {
      display: flex !important;
      flex-direction: column !important;
      margin-right: 16px !important;
      &__row1 {
        color: #0f0f60 !important;
        font-size: 12px !important;
        font-weight: bold !important;
      }
      &__row2 {
        color: #a7b3d9 !important;
        font-size: 12px !important;
      }
      &__row3 {
        color: #fe943b !important;
        font-size: 12px !important;
        font-weight: bold !important;
        &__count {
          margin-left: 4px !important;
          color: #a7b3d9;
        }
      }
      &__row4 {
        color: #0f0f60 !important;
        font-size: 12px !important;
      }
    }
    &__middle {
      &__image {
        margin-left: 16px !important;
        height: 52px !important;
        width: 52px !important;
        display: block !important;
      }
    }
  }
}
