.appLeftNav {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2 !important;
  height: 100vh;

  .app-home-button-divider {
    background: #a7b3d9;
    margin: 0 !important;
  }

  .marketplace-nav-container {
    margin-top: 8px !important;
    display: flex;
    height: calc(100vh - 72px);
  }

  &__marketplace {
    width: 60px;
    border-right: 1px #a7b3d9 solid;
    background: #ffffff;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Hidden-Icon {
    color: black;
    position: relative;
    top: 460px;
    left: 0px;
    cursor: pointer;
  }
  .Hide {
    display: none;
  }
  .Show-Icon {
    color: black;
    position: relative;
    top: -50px;
    left: 0px;
    cursor: pointer;
  }

  .nav-menu {
    width: 240px;
  }
}
