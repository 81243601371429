.email-table {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; // Align items to the top
  }

  &__emails {
    flex: 1;

    .email-table__emails-list-title {
      font-weight: bold;
      color: #000;
    }

    .email-table__email-item {
      color: #999;
      margin-bottom: 4px;
    }
  }

  &__last-email,
  &__updated {
    flex-basis: 20%;
  }

  &__last-email {
    display: flex;
    align-items: flex-start; // Aligns text at the top
  }

  &__copy-icon {
    margin-left: 8px;
    color: #000;
    cursor: pointer;
  }

  &__updated {
    color: #999;
  }

  // Customize Ant Design table header
  .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    border-radius: 16px 16px 0 0;
    font-weight: bold;
    color: #000;
  }

  .ant-table {
    background-color: #fff;
    border-radius: 16px;
  }
}
