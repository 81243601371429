.steps {
  width: 100% !important;
  margin-top: 10px !important;

  &__scrollable {
    max-height: 80vh; // Adjust the height as needed
    overflow-y: auto;
  }

  &__panel-header {
    display: flex !important;
    align-items: center !important;
    padding: 8px !important;
    border-radius: 0px 8px 8px 8px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    cursor: pointer !important;

    &--active {
      background-color: #394399;
    }

    &--inactive {
      background-color: white !important;
    }

    &--completed {
      background-color: #52b150 !important;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: #ffffff;
      margin-right: 8px;

      &--active {
        background-color: #3a6793;
      }

      &--inactive {
        background-color: #3872cd;
      }

      &--completed {
        background-color: #3f933b;
        color: #ffffff;
      }
    }

    &__text {
      &__main-heading {
        font-size: 14px !important;
        font-weight: bold;

        &--active {
          color: #ffffff !important;
        }

        &--inactive {
          color: #394399 !important;
        }

        &--completed {
          color: #ffffff !important;
        }
      }

      &__sub-heading {
        font-size: 12px !important;

        &--active {
          color: #ffffff !important;
        }

        &--inactive {
          color: #394399 !important;
        }

        &--completed {
          color: #ffffff !important;
        }
      }
    }
  }

  &__card-content {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    margin-top: 16px !important;
    cursor: pointer !important;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;

      &__image {
        margin-right: 8px !important;
        width: 20px !important;
        height: 20px !important;
      }

      &__main-heading {
        flex: 1 !important;
        font-family: Lato !important;
        font-weight: bold !important;
        font-size: 14px;
        text-align: left !important;
        margin: 2px 0px 0px 0px !important;
      }

      &__greeting-message {
        margin-right: 24px !important;
        width: 16px !important;
        height: 16px !important;
      }
    }

    &__sub-heading {
      font-family: Lato;
      font-weight: normal;
      font-size: 10px;
      text-align: left;
      margin-top: 4px 0px 4px 0px !important;
    }
  }
}

.ant-collapse-content-active {
  border-radius: 8px !important;
  background-color: #f0f5fb !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: -32px !important;
  z-index: -999px !important;
}

.ant-card-bordered {
  box-shadow: rgba(0, 0, 0, 0) 0px 4px 8px !important;
  border-radius: 8px !important;
  padding: 0px !important;
  margin-top: 16px !important;
}

.ant-card-body {
  padding: 8px !important;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 0px !important;
}

.ant-collapse {
  border: 0px !important;
  background-color: white !important;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transition: transform 0.24s;
  color: white !important;
  margin-right: 40px;
}

.custom-drawer .ant-drawer-content {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.custom-drawer .ant-drawer-wrapper-body {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  overflow: hidden;
}
