.mymobilenewsinglePlaybook {
  padding-top: 16px !important;
  padding-bottom: 12px !important;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;

  .anticon svg {
    margin-bottom: 4px;
  }
  &__Row {
    width: 100%;
  }
  &__Dollar {
    text-align: center;
    font-size: 22px;
  }
  &__Row-2 {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  &__Col {
    width: 100%;
  }
  &__IMG1 {
    border-radius: 8px;
    width: 100% !important;
    // height: 106px !important;
    border: 1px solid #a9b3d6;
    margin-left: 14px;
  }
  &__IMG1:hover {
    cursor: pointer;
  }

  &__appHomeButton {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 6px 16px 4px 16px;
  }
  &__appAvatar {
    width: 60px !important;
    height: 60px !important;
    margin-right: 14px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &__appHomeMainHead {
    font-weight: bold;
    font-size: 18px;
    margin-left: 8px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #07074b;
    margin-right: 10px;
    margin-top: 0px;
    width: 174px;
  }
  &__appHomeMainHead:hover {
    cursor: pointer;
  }

  &__appHomeSubHead {
    font-size: 16px;
    color: #a7b3d9;
    margin-left: 8px;
    line-height: 15px;
    font-size: 16px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 170px;
  }
  &__appHomeSubHead:hover {
    cursor: pointer;
  }

  &__P1 {
    margin-left: 14px !important;
    margin-bottom: 0px !important;
    font-size: 14px !important;
    color: #a7b3d9;
    line-height: normal;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  &__P2 {
    margin-left: 16px !important;
    margin-top: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    color: #07074b;
    margin-bottom: 0px !important;
    display: flex;
    &__Icon {
      margin-right: 4px;
      margin-top: 4px;
    }
    &__Low {
      margin-left: auto !important;
      margin-right: 12px !important;
      text-align: end;
      position: absolute;
      top: 14px;
      left: 78px;
      line-height: normal;
      &__As {
        font-size: 12px;
      }
      &__Price {
        font-size: 20px;
      }
    }
    &__Read {
      margin-left: auto !important;
      margin-right: 8px;
      margin-top: 0px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
  &__H1 {
    margin-left: 14px !important;
    font-size: 18px !important;
    line-height: normal;
    font-weight: bold !important;
    color: #07074b;
    margin-bottom: 0px !important;
    margin-right: 2px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  &__H1:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &__proposalDetails__reviewsInfo {
    color: #fe943b !important;
    font-size: 14px !important;
    margin-left: 14px;
    font-weight: bold !important;
    position: absolute;
    top: 100px;
    &__count {
      color: #a7b3d9 !important;
      font-size: 14px !important;
      margin-left: 4px;
    }
    .anticon svg {
      font-size: 16px;
    }
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .ant-avatar > img {
    width: 30px;
    height: 30px;
  }
  &__customAvatar {
    &__avatar {
      margin-top: 6px !important;
      height: 30px !important;
      width: 30px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
}
@media (max-width: 425px) {
  .mymobilenewsinglePlaybook {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .mymobilenewsinglePlaybook {
    width: 100%;
    &__proposalDetails__reviewsInfo {
      position: absolute;
      top: 86px;
    }
    &__P2 {
      &__Low {
        position: absolute;
        top: 14px;
        left: 58px;
      }
    }
  }
}

@media (max-width: 325px) {
  .mymobilenewsinglePlaybook {
    width: 100%;
    &__P2 {
      &__Low {
        position: absolute;
        top: 14px;
        left: 35px;
      }
    }
    &__H1 {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    &__appHomeSubHead {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      width: 106px !important;
    }
    &__proposalDetails__reviewsInfo {
      // margin-top: 18%;
      position: absolute;
      top: 70px;
    }
    &__appHomeMainHead {
      width: 110px;
    }
  }
}
