.PlaybookDetailsPreview {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 6% 12% 10px 12%;
  border: 1px solid #dcdee3;

  .ant-carousel .slick-slide img {
    // height: 430px !important;
    // width: 723px !important;
    border: 1px solid #a7b3d9 !important;
  }
  &__image {
    width: 10%;
    align-items: flex-center !important;
    justify-content: center !important;
  }
  &__playbookTitle {
    margin-top: 8px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px;
  }
  &__playbookDes {
    margin-bottom: 24px;
  }
  &__welcomeText {
    margin-top: 8px;
    color: #0f0f60;
    font-size: 14px;
  }
  &__positiveBtn {
    align-items: flex-center;
    margin-top: 16px !important;
    width: 160px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    background-color: #84d830 !important;
  }
  &__aboutHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  &__highLights {
    &__text {
      font-size: 14px !important;
      color: #0f0f60;
      margin-left: 16px !important;
    }
  }
  &__description {
    margin-top: 8px;
    font-size: 14px;
    color: #0f0f60;
  }
  &__reviewsInfo {
    color: #fe943b !important;
    font-size: 12px !important;
    font-weight: bold !important;
    &__count {
      color: #a7b3d9 !important;
      font-size: 14px !important;
    }
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  &__languageCategory {
    display: flex !important;

    &__heading {
      width: 40%;
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold !important;
      margin-bottom: 0px !important;
    }
    &__value {
      width: 40%;
      font-size: 12px;
      color: #0f0f60;
    }
  }
  &__tagsHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  &__tagsdescription {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    &__tagsAndActions {
      &__left {
        &__eachPill {
          // border: 1px solid #a7b3d9 !important;
          cursor: pointer;
          text-align: center !important;
          border-radius: 15px;
          font-size: 12px !important;
          color: white !important;
          margin-right: 8px;
          padding: 4px 16px 4px 16px !important;
          display: inline-block !important;
          background-color: #56a5ff !important;
          margin-top: 6px;
        }
      }
    }
  }
  &__searchContainer {
    &__addIcon {
      text-align: end;
      margin-right: 16px;
      margin-top: 16px;
    }
  }
  &__centerAlign {
    display: block !important;
    margin-left: auto !important;
    height: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    align-items: center !important;
  }
  &__customAvatar {
    &__avatar {
      height: 55px !important;
      width: 55px !important;
      font-size: 28px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
  &__Button {
    background-color: #84d830 !important;
    color: white !important;
    border-radius: 20px !important;
    border: none !important;
    margin-top: 10% !important;
    margin-bottom: 10px !important;
  }
  &__Create {
    position: absolute !important;
    right: 15% !important;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px !important;
    color: white !important;
    border: none !important;
  }

  //   .ant-carousel {
  //     width: 600px !important;
  //     min-height: 0;
  //     min-width: 0;
  //     margin: 0 auto !important;
  //     margin-top: 8px !important;
  //   }

  //   &__carouselContainer {
  //     width: 100% !important;
  //   }
}

// ______________________________________________________________

.PlaybookDetailsPreviewViewbutton {
  // margin-left: 22%;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  &__DesktopButton {
    border: none !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__DesktopButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__DesktopButton:hover {
    color: white !important;
  }
  &__MobileButton {
    border: none !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__MobileButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__MobileButton:hover {
    color: white !important;
  }
}

// ______________________________________________________________

.PlaybookDetailsPreviewMobile {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 6% 2% 10px 2%;
  border: 1px solid #dcdee3;

  .ant-carousel .slick-slide img {
    // height: 430px !important;
    // width: 723px !important;
    border: 1px solid #a7b3d9 !important;
  }
  &__image {
    width: 10%;
    align-items: flex-center !important;
    justify-content: center !important;
  }
  &__playbookTitle {
    margin-top: 8px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px;
  }
  &__playbookDes {
    margin-bottom: 24px;
  }
  &__welcomeText {
    margin-top: 8px;
    color: #0f0f60;
    font-size: 14px;
  }
  &__positiveBtn {
    align-items: flex-center;
    margin-top: 16px !important;
    width: 160px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    background-color: #84d830 !important;
  }
  &__aboutHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  &__highLights {
    &__text {
      font-size: 14px !important;
      color: #0f0f60;
      margin-left: 16px !important;
    }
  }
  &__description {
    margin-top: 8px;
    font-size: 14px;
    color: #0f0f60;
  }
  &__reviewsInfo {
    color: #fe943b !important;
    font-size: 12px !important;
    font-weight: bold !important;
    &__count {
      color: #a7b3d9 !important;
      font-size: 14px !important;
    }
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  &__languageCategory {
    display: flex !important;

    &__heading {
      width: 40%;
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold !important;
      margin-bottom: 0px !important;
    }
    &__value {
      width: 40%;
      font-size: 12px;
      color: #0f0f60;
    }
  }
  &__tagsHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  &__tagsdescription {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    &__tagsAndActions {
      &__left {
        &__eachPill {
          // border: 1px solid #a7b3d9 !important;
          cursor: pointer;
          text-align: center !important;
          border-radius: 15px;
          font-size: 12px !important;
          color: white !important;
          margin-right: 8px;
          padding: 4px 16px 4px 16px !important;
          display: inline-block !important;
          background-color: #56a5ff !important;
          margin-top: 6px;
        }
      }
    }
  }
  &__searchContainer {
    &__addIcon {
      text-align: end;
      margin-right: 16px;
      margin-top: 16px;
    }
  }
  &__centerAlign {
    display: block !important;
    margin-left: auto !important;
    height: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    align-items: center !important;
  }
  &__customAvatar {
    &__avatar {
      height: 55px !important;
      width: 55px !important;
      font-size: 28px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
  &__Button {
    background-color: #84d830 !important;
    color: white !important;
    border-radius: 20px !important;
    border: none !important;
    margin-top: 10% !important;
    margin-bottom: 10px !important;
  }
  &__Create {
    position: absolute !important;
    right: 18% !important;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px !important;
    color: white !important;
    border: none !important;
  }

  //   .ant-carousel {
  //     width: 600px !important;
  //     min-height: 0;
  //     min-width: 0;
  //     margin: 0 auto !important;
  //     margin-top: 8px !important;
  //   }

  //   &__carouselContainer {
  //     width: 100% !important;
  //   }
}
