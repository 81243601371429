.authForm {
  display: flex;
  justify-content: center;
  width: 100vw;

  &--withoutShadow {
    box-shadow: unset;
    padding: unset;
  }

  &__header {
    font-weight: normal;
  }
  &__inputField {
    min-width: 304px;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    border: 1px solid #E4E4E4; /* Add border with primary color */
    border-radius: 8px !important; /* Adjust as needed for rounded corners */
    padding: 4px; /* Space between the border and input */

    input{
      font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    
    }
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
  }
  &__button {
    min-width: 120px;
  }
  &__footer {
    font-weight: normal;
  }
  &__linkButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
  }
  &__googleButton {
    cursor: pointer;
    height: 50px;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 14px !important;
    line-height: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 4px !important;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 0px !important;
  }

  .ant-btn-primary {
    border: 0px;
  }
}
