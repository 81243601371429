.desktop-select-source{
  width: 100% !important;
  background-color: #eff2fc !important;
}
.desktop-select-source .custom-radio .ant-radio-inner {
  margin-left: 16px;
  border-color: #56a5ff;
}
.desktop-select-source .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.desktop-select-source .ant-spin-dot-item {
  background-color: #3872cd !important; /* Set the desired color */
}
.custom-radio .ant-radio-inner::after {
}

.redirectButton{
  margin: 16px 0 0 0 !important;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  background-color: #3872cd !important;
  width: 108px !important;
  color: #f0f5fb !important;
  font-size: 11pt !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  // margin-top: 12px !important;
  // float: right !important;
  border: none !important;
  height: 32px !important;
}