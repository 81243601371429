.menu-list {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .ant-menu {
    background: #ffffff !important;
    color: #07074b !important;
    border: none !important;
    .ant-menu-title-content,
    .ant-upload {
      height: 40px;
      font-size: 14px !important;
      font-weight: bold;
      color: #07074b;
    }
  }
  .ant-menu-item {
    color: black !important;
    height: 40px !important;
  }
  .ant-menu-submenu-title {
    font-weight: bold !important;
  }

  .ant-menu-submenu-title {
    color: black !important;
    height: 40px !important;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    opacity: 1;
    color: #ffffff !important;
  }

  .menu-item-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
    margin-top: -3px;
  }
  .ant-menu-submenu-arrow {
    color: white !important;
    display: none !important;
  }

  &__link {
    color: black !important;
    font-size: 12px !important;
    font-weight: bold;
    &:hover {
      color: #3872cd !important;
      .menu-item-icon {
        filter: grayscale(0%);
        color: #3872cd !important;
      }
    }
  }
  &__selected-link {
    font-size: 12px !important;
    color: #3872cd !important;
  }

  &__icon {
    width: 10px !important;
    height: 10px !important;
  }
}
.submenu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chevron-icon img {
  width: 20px;
}
