.callMe{
    display: flex;
    flex-direction: row;

    &__callMeDiv{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
      border: 1px solid #a7b3d9;
      border-radius: 10px;
    }
    &__callMeDiv:hover{
      border: 1px solid #4B9EF0 !important;
      border-radius: 5px;
    }
    
    &__callMeButton {
      display: inline-flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 32px !important;
      border-radius: 24px !important;
      color: #56A5FF !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border: 0px;
      text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
      box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    }
    .ant-btn {
      color: #56A5FF;
      border-color: #56A5FF;
      
  }

  &__iconCallMe{
    width: 24px;
    height: 24px;
    padding: 4px;
  }

  &__callMeText {
    display: flex;
      height: 32px;
      width: 130px;
      font-size: 14px;
      align-items: center;
      color: #6AA7F7 !important;
      padding-left: 10px;
      padding-right: 3px;
}
}