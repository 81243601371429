.ThankYouPage {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid #dcdee3;
  // height: 100vh !important;

  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    border: none;
    padding: 0px 10px 0px 10px;
    color: #93adcc;
  }

  .anticon svg {
    margin-top: -6px;
  }
  .ant-image-img {
    // width: 161.5%;
    height: 60px;
    width: 60px;
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-image.ThankYouPage__customAvatar__avatar {
    width: 45px !important;
    height: 45px !important;
    //   margin-right: 8px;
  }
  // [type="button"]:not(:disabled),
  // [type="reset"]:not(:disabled),
  // [type="submit"]:not(:disabled),
  // button:not(:disabled) {
  //   cursor: pointer;
  //   border-radius: 20px;
  //   background-color: #4b9ef0;
  //   border: none;
  //   color: white;
  //   font-weight: bold;
  // }

  &__Border {
    border: 1px solid black;
    margin: 4%;
  }
  &__Image {
    background-color: #959595;
    width: 100%;
    height: 500px;
  }
  &__Logo {
    position: absolute;
    top: 64px;
    left: 226px;
  }
  &__Paragraphs {
    position: absolute;
    top: 230px;
    left: 226px;
    &__Para-1 {
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: bold;
      color: white;
    }
    &__Para-2 {
      margin-bottom: 0px;
      font-size: 12px;
      color: white;
      width: 318px;
    }
    &__Input {
      background-color: white;
    }
  }
  &__LowerContent {
    display: flex;
    justify-content: space-around;
    margin: 5% 0px 4px 0px;
    &__Head {
      font-weight: bold;
      font-size: 18px;
      // margin-right: 26px;
      margin-left: 25%;
    }
    &__Button {
      // margin-left: 20px;
      // position: absolute !important;
      // right: 130px;
      // bottom: 0px;
      background-color: #4b9ef0 !important;
      border-radius: 14px !important;
      color: white !important;
      font-weight: bold !important;
    }
  }
  &__Form {
    border: 1px solid white;
    padding: 10px !important;
    background-color: white;
    border-radius: 6px;
    margin-top: 26px !important;
    &__Switch {
      color: black !important;
      margin-left: 26px !important;
      // border-radius: 6px !important;
      padding: 4px 12px !important;
      cursor: pointer !important;
      border-radius: 6px !important;
      background-color: #66b4f8 !important;
      border: none !important;
      color: white !important;
      font-weight: bold !important;
    }
  }
  &__Create {
    position: absolute !important;
    right: 5%;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px;
    color: white !important;
    border: none !important;
  }
}

.ThankYouPageViewbutton {
  // margin-left: 22%;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px;
  padding-top: 20px;
  &__DesktopButton {
    border: none !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__DesktopButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__DesktopButton:hover {
    color: white !important;
  }
  &__MobileButton {
    border: none !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__MobileButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__MobileButton:hover {
    color: white !important;
  }
}

.ThankYouPageMobile {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid #dcdee3;
  padding: 0px 0px 26px 0px;

  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    border: none;
    padding: 0px 10px 0px 10px;
    color: #93adcc;
  }

  .anticon svg {
    margin-top: -6px;
  }
  .ant-image-img {
    height: 60px;
    width: 60px;
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-image.ThankYouPage__customAvatar__avatar {
    width: 45px !important;
    height: 45px !important;
  }

  &__Border {
    border: 1px solid black;
    margin: 4%;
  }
  &__Image {
    background-color: #959595;
    width: 100%;
    height: 500px;
  }
  &__Logo {
    position: absolute;
    top: 64px;
    left: 520px;
  }
  &__Paragraphs {
    position: absolute;
    top: 230px;
    left: 357px;
    &__Para-1 {
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: bold;
      color: white;
    }
    &__Para-2 {
      margin-bottom: 0px;
      font-size: 12px;
      color: white;
      width: 318px;
    }
    &__Input {
      background-color: white;
    }
  }
  &__LowerContent {
    display: flex;
    justify-content: center;
    margin: 5% 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    &__Head {
      font-weight: bold;
      font-size: 18px;
      margin-right: 26px;
    }
    &__Button {
      margin-bottom: 10px !important;
      cursor: pointer !important;
      border-radius: 20px !important;
      background-color: #4b9ef0 !important;
      border: none !important;
      color: white !important;
      font-weight: bold !important;
      margin-bottom: 10px !important;
    }
  }
  &__Form {
    margin-top: 26px !important;
    margin-top: 26px !important;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    &__Switch {
      color: black;
      border-radius: 6px !important;
      padding: 8px 12px !important;
      cursor: pointer !important;
      background-color: #66b4f8 !important;
      border: none !important;
      color: white !important;
      font-weight: bold !important;
      width: 383px !important;
      font-size: 16px;
    }
    &__Input {
      border: none !important;
      padding: 12px 12px !important;
      border-radius: 6px;
    }
  }
  &__Create {
    position: absolute !important;
    right: 18%;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px;
    color: white !important;
    border: none !important;
  }
}
