.listOfDatasets {
  &__mainDiv {
    display: flex;
    margin-top: 8px;
    width: 100%;
    justify-content: space-between;
  }

  &__leftdiv {
    display: flex;
    flex-direction: column;
    float: left;
    margin-left: 16px;
  }

  &__rightdiv {
    display: flex;
    flex-direction: column;
    float: right;
    margin-right: 16px;
  }

  &__allBtn {
    width: 85px !important;
    height: 20px;
    color: #6aa3f8;
    font-size: 12px;
    border: 1px solid #e2e2e2 !important;
    margin-left: 0px;
    text-align: center !important;
    margin-top: 8px !important;
    border-radius: 16px !important;
    background-color: #f2f2f2 !important;
  }

  &__createNewBtn {
    width: 90px !important;
    height: 28px !important;
    background-color: white !important;
    border-radius: 15px !important;
    border-width: 1px;
    border-color: #3f97fc !important;
    color: #3f97fc !important;
    font-size: 13px !important;
    font-weight: bold !important;
    margin-top: 8px !important;
    line-height: 22px !important;
    outline: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &__detailsColumn {
    display: flex;
    align-items: center;
  }

  &__detailsContent {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: bold;
    font-family: "Lato", sans-serif;
  }

  &__createdDate,
  &__updatedDate {
    font-size: 12px;
    color: #555;
  }

  &__tableContainer {
    height: calc(
      100vh - 150px
    ); /* Adjust this value according to your layout */
    overflow-y: auto;
  }
}

.status-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-circle.offline {
  background-color: grey !important;
}

.status-circle.active {
  background-color: green !important;
}

.status-circle.unavailable {
  background-color: red !important;
}

.icon-facebook,
.icon-search {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
}

.icon-facebook {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPLzPj0lBrKZ_lemEDk9DUnfMCWE8EcYjmwA&s");
}

.icon-search {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzrzmQyShtZ8DjX7YUl6R0OT6V1ZDdURrt6g&s");
}

.datasetsListContainer .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  margin-top: 24px;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  background-color: #f2f2f2;
}

.ant-table {
  background-color: #f0f5fb;
}

.table {
  margin-top: 10px !important;
}

.ant-avatar {
  border: 1px solid #ccc;
}
