.selectSource {
  width: 100% !important;

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2px;
    }

    &-image {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    &-headingContainer {
      margin-left: 8px !important;
      display: flex;
      flex-direction: column;
    }

    &-mainHeading {
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0;
    }

    &-subHeading {
      font-family: Lato;
      font-weight: normal;
      font-size: 10pt;
      text-align: left;
      margin-top: 8px !important;
      margin: 0 0 0 -36px;
    }
  }

  &__list-item {
    padding: 0 !important;
  }

  &__card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    margin-top: 12px !important;
    border-radius: 8px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    min-height: 80px !important;
  }

  &__card-content {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
  }

  &__radio .ant-radio-inner {
    margin-left: 16px !important;
    border-color: #56a5ff !important;

    &::after {
      background-color: #56a5ff !important;
    }
  }

  &__image {
    margin-left: 8px !important;
  }

  &__text-container {
    margin-left: 32px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    height: 100% !important;

    &--centered {
      justify-content: center !important;
    }
  }

  &__primary-text {
    margin: 0;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14pt;
    color: #212121;
  }

  &__secondary-text {
    margin: 0;
    font-family: Lato, sans-serif;
    font-weight: 300;
    font-size: 12pt;
    color: #07074b;
  }

  .ant-collapse-header {
    padding-right: 32px !important;
  }

  .ant-collapse-arrow {
    display: none !important; // Hide the expand icon
  }
}
