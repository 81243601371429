.proposals {
  display: flex;
  width: 100%;
  flex-direction: column;
  // padding-bottom: 12px;
  background-color: #eff2fc !important;
  // height: calc(100vh - 120px);

  &__searchContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    background-color: white;
    padding: 16px 0px 0px 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &__list {
    // margin-left: 16px;
    margin-right: 16px;
    width: 100% !important;
    background-color: white;

    .ant-list-items {
      display: flex !important;
      flex-wrap: wrap !important;
    }
    .ant-list-items .proposals__datas {
      width: 33% !important;
    }
  }

  &__filterAndResults {
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
    // border-top: 12px solid #eff2fc;
    width: 100%;

    form {
      height: 100%;
    }

    &__filters {
      width: 250px;
      min-width: 250px;
      background-color: #fff;
      border-right: 12px solid #eff2fc;
      height: 100%;
    }

    &__proposalsList {
      display: flex;
      flex-direction: column;
      background-color: #eff2fc;
      height: 100%;
      overflow-y: scroll;
      width: 100% !important;

      &__header {
        background-color: white !important;
        height: 60px;

        &__text {
          color: #0f0f60;
          font-size: 14px;
          font-weight: bold;
          //margin-top: 8px !important;
          margin-left: 16px !important;
          margin-bottom: 0px !important;
          display: flex;
          justify-content: space-between;
          border: 1px solid #eff1fb;
          background-color: #eff1fb;
          margin-right: 16px;
          padding: 8px 0px 8px 8px;
        }
        &__divider {
          height: 1px !important;
          background-color: #a7b3d9 !important;
          width: calc(100%) !important;
          margin: 8px 0px 8px 0px !important;
        }
      }

      &__list {
        &__listItem {
          margin: 4px 4px 4px 4px !important;
          padding: 8px 0px 4px 0px !important;
          border-radius: 4px !important;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .proposals {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .proposals__datas {
        width: 100% !important;
      }
    }
    &__filterAndResults {
      &__filters {
        position: absolute;
        z-index: 99;
        width: 100%;
        box-shadow: 0px 0px 10px grey;
        border-right: 0px solid #eff2fc;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
      }
    }
  }
}
