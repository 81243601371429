.PlaybookCategoriesPreview {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: hsl(226, 68%, 96%) !important;
  overflow-y: scroll;
  // border: 1px solid #dcdee3;
  // padding: 10px 10% 34px 10%;
  // height: calc(100vh - 120px);
  .ant-picker-input > input {
    font-size: 12px !important;
  }

  .CategoriesPreview__list
    .ant-list-items
    .CategoriesPreview__datas:first-child {
    color: #56a5ff;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }

  .CategoriesPreviewMobile__list
    .ant-list-items
    .CategoriesPreviewMobile__datas:first-child {
    color: #56a5ff;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }

  .CategoriesPreview__list .ant-list-items .CategoriesPreview__datas {
    filter: blur(2px);
    -webkit-filter: blur(3px);
  }

  .CategoriesPreviewMobile__list
    .ant-list-items
    .CategoriesPreviewMobile__datas {
    filter: blur(2px);
    -webkit-filter: blur(3px);
  }

  &__Margin {
    width: 100%;
  }

  &__chevron {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  &__searchContainer {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    background-color: white;
    padding: 16px 0px 0px 0px !important;
    //   border-top-left-radius: 8px !important;
    //   border-top-right-radius: 8px !important;
    position: sticky !important;
    top: 0;
    // z-index: 99;
    margin-bottom: 12px;

    &__addIcon {
      cursor: pointer;
      margin-right: 24px !important;
      height: 26px !important;
      width: 26px !important;
      visibility: invisible !important;
    }
  }

  &__filterAndResults {
    display: flex;
    flex-direction: row;

    // ########## UnComment #############
    // background-color: white;
    // ########## UnComment #############

    height: calc(100% - 60px) !important;
    // border-top: 12px solid #eff2fc !important;
    width: 100%;

    form {
      height: 100%;
    }

    &__filters {
      width: 250px;
      min-width: 250px;
      background-color: #fff !important;
      // ################# Comment #####################
      border-right: 12px solid #eff2fc !important;
      // ################# Comment #####################
      height: 100%;
    }
  }
  //   &__Create {
  //     position: absolute !important;
  //     right: 15% !important;
  //     background-color: #56a5ff !important;
  //     border-radius: 20px !important;
  //     width: 120px !important;
  //     color: white !important;
  //     border: none !important;
  //   }
}

// .PlaybookCategoriesPreviewViewbutton {
//   // margin-left: 22%;
//   display: flex !important;
//   justify-content: center !important;
//   padding-bottom: 20px !important;
//   padding-top: 20px !important;
//   &__DesktopButton {
//     border: none !important;
//     border-bottom-left-radius: 6px !important;
//     border-top-left-radius: 6px !important;
//     padding: 6px 10px 6px 10px !important;
//     background-color: #e2e3e3 !important;
//   }
//   &__DesktopButton:focus {
//     background-color: #737373 !important;
//     color: white !important;
//   }
//   &__DesktopButton:hover {
//     color: white !important;
//   }
//   &__MobileButton {
//     border: none !important;
//     border-bottom-right-radius: 6px !important;
//     border-top-right-radius: 6px !important;
//     padding: 6px 10px 6px 10px !important;
//     background-color: #e2e3e3 !important;
//   }
//   &__MobileButton:focus {
//     background-color: #737373 !important;
//     color: white !important;
//   }
//   &__MobileButton:hover {
//     color: white !important;
//   }
// }

@media (max-width: 650px) {
  .PlaybookCategoriesPreview {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .proposals__datas {
        width: 100% !important;
      }
    }
    &__filterAndResults {
      &__filters {
        position: absolute;
        z-index: 99;
        width: 100%;
        box-shadow: 0px 0px 10px grey;
        border-right: 0px solid #eff2fc;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
      }
    }
  }
}

// .PlaybookCategoriesPreviewMobile {
//     &__Create {
//         position: absolute !important;
//         right: 15% !important;
//         background-color: #56a5ff !important;
//         border-radius: 20px !important;
//         width: 120px !important;
//         color: white !important;
//         border: none !important;
//       }
// }
