.DestinationSettings {
  &__headerText {
    margin: 2% 0% 2% 3%;
    margin-left: 15px;
    font-size: 15px;
    font-weight: bold;
  }

  &__title {
    margin-top: 4px;
    margin-left: 1%;
    color: #a7b3d9;
  }
  &__titleSelected {
    margin-top: 4px;
    color: #001f3f; /* Change to the desired color */
    margin-left: 1%;
  }
  &__wrapper {
    margin: 30px 3% !important;
    width: 878px;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  &__input {
    width: 60% !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 5px !important;
  }

  &__input2 {
    width: 60% !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 5px !important;
    pointer-events: none;
  }

  &__heading {
    text-align: start;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: -3px !important;
    font-weight: bold;
  }

  &__topHeading {
    text-align: start;
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 8px !important;
    font-weight: bold;
  }

  &__form {
    // margin-left: 30px;
    margin-top: 30px;
    margin-right: 160px;
  }

  &__div {
    margin: 0px 20px 0px 0px;
  }
  &__field {
    margin-top: 30px !important;
  }

  &__para {
    margin-top: 0px;
    margin-bottom: 4px;
    font-size: 12px;
    text-align: start;
    color: gray;
  }

  &__error {
    margin-top: 0px;
    color: red;
  }

  &__mainHeading {
    font-size: 15px;
    margin: 2% 0px 0px 3%;
    font-weight: bold;
  }

  &__mainHeading2 {
    font-size: 15px;
    margin: 2% 0px 2% 3%;
    font-weight: bold;
  }

  &__component {
    width: 100% !important;
    border-bottom: 10px solid #eff2fc;
    border-top: 10px solid #eff2fc;
  }

  &__buttonsDiv {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    padding-bottom: 4%;
    // margin-left: 30px;
  }

  &__saveButton {
    font-size: 13px;
    height: 36px !important;
    width: 140px;
    font-weight: bold !important;
    border-radius: 6px !important;
    background-color: #1c74d4 !important;
    color: white;
    border: 0px !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__deleteButton {
    font-size: 13px;
    height: 36px !important;
    width: 165px;
    font-weight: bold !important;
    border-radius: 6px !important;
  }
}

.fixedBar {
  margin: 0% 0px 1% 3%;
}
.ConnectorSettings__input.empty-input {
  border: 1px solid red;
}

.Fixed {
  width: 100% !important;
  background-color: white;
  padding: 12px 0px 0px 0px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  position: sticky !important;
  top: 0;
  z-index: 99;
  margin-bottom: 12px;
  border-bottom: 10px solid #eff2fc;

  &__title {
    margin-right: 1%;
    color: #a7b3d9;
  }

  &__slash {
    color: #a7b3d9;
  }

  &__mainTitle {
    margin-left: 1%;
  }
}

.ApiConnector__revokeButton .anticon {
  margin-right: 5px;
}

.ApiConnector__copyButton .anticon {
  margin-right: 5px;
}

.Title {
  font-weight: bold;
  font-size: 15px;
}
