.tabsContainer {
  height: 100%;

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    /* position: relative; */
    background-color: white;
    position: fixed;
    opacity: 100%;
    width: 55.33%;
    z-index: 1;
  }

  .ant-avatar-image {
    height: 28px !important;
    width: 30px !important;
  }

  .ant-tabs-tab-btn {
    color: #0f0f60 !important;
    font-size: 12px !important;
    padding-left: 16px;
    padding-right: 16px;
  }
  .ant-tabs-tab {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border: none !important;
    border-top: 6px solid #fafafa !important;
    height: 34px !important;
  }
  .ant-tabs-tab-active {
    border-top: 6px solid #0f0f60 !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  .ant-tabs-nav {
    margin-bottom: 33px !important;
  }
  .ant-tabs-tabpane {
    padding: 0px 16px;
  }
  .ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100% !important;
  }
  .ant-tabs-tabpane {
    padding: 0px !important;
  }
  .ant-tabs-content-holder {
    height: calc(100% - 67px) !important;
  }
  &__View {
    position: absolute;
    top: 67px;
    right: 161px;
    font-size: 22px;
    z-index: 1;
    //     overflow: hidden !important;
    // text-overflow: ellipsis !important;
  }
  &__Avatar {
    position: absolute;
    top: 74px;
    right: 111px;
    z-index: 1;
    //     overflow: hidden !important;
    // text-overflow: ellipsis !important;
  }
  // &__Button {
  //   display: flex;
  //   justify-content: flex-end;
  //   margin-top: 12.5%;
  //   margin-right: 15%;
  //   &__Create {
  //     position: absolute !important;
  //     right: 17% !important;
  //     bottom: 44px;
  //     background-color: #56a5ff !important;
  //     border-radius: 20px !important;
  //     width: 120px !important;
  //     color: white !important;
  //     border: none !important;
  //   }
  // }
}
