.chatUI {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  // align-items: center;

  &__parentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 10px);
    justify-content: space-between !important;
  }
  &__chatHeaderMobileDiv {
    width: 100%;
  }

  &__parentDivMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 8px);
    padding-top: 84px;
    padding-bottom: 44px;
    justify-content: space-between !important;
  }
  &__parentDivMobileProposal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // height: calc(100% - 118px);
    // margin-top: 154px;
    // padding-bottom: 94px;

    // height: calc(100% - 122px);
    margin-top: 90px;
    margin-bottom: 44px;
    justify-content: space-between !important;
    overflow-y: scroll;
  }

  &__chatHeaderMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 84px;
    max-height: 84px;
    position: absolute;
    top: 0;
    z-index: 99;
    background: #0056ff;
  }
  &__chatUserIconDiv {
    margin-left: 8px;
  }

  &__chatHeaderMobileProposal {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 144px;
    max-height: 144px;
    position: absolute;
    top: 0;
    z-index: 99;
    background: #eff1fb;
  }

  &__chatHeaderMobileProposalNew {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 83px;
    max-height: 83px;
    position: absolute;
    top: 0;
    z-index: 99;
    background: #0056ff;
  }

  &__headerElementsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 52px;
    align-items: center;
    margin-left: 25px;
    margin-right: 36px;
    cursor: pointer;
  }

  &__headerElementsContainerProposal {
    display: flex;
    flex-direction: row;
    height: 52px;
    align-items: center;
    margin-left: 25px;
    margin-top: 29px;
    margin-right: 36px;
    cursor: pointer;
  }

  &__headerElementsContainerProposalNew {
    display: flex;
    flex-direction: row;
    height: 32px;
    align-items: center;
    margin-left: 25px;
    margin-top: 29px;
    margin-right: 36px;
    cursor: pointer;
  }

  &__headerElementsSecondaryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
  }

  &__replyRateDiv {
    display: flex;
    flex-direction: column;
  }

  &__replyRateTimeDiv {
    display: flex;
    flex-direction: row;
    height: 20px;
    align-items: center;
  }
  &__replyRateTimeDivNew {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__meetingAndCallDiv {
    display: flex;
    flex-direction: row;
    width: 90px;
    height: 44px;
    justify-content: space-between;
    align-items: center;
  }
  &__iconScheduleMeeting {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
  &__iconScheduleMeetingNew {
    width: 24px;
    height: 24px;
    padding: 1px;
  }
  &__iconCallMe {
    width: 24px;
    height: 24px;
    padding: 1px;
    margin-left: 10px;
  }

  &__iconClockTimer {
    width: 12px;
    margin-left: 13px;
  }

  &__iconChatHome {
    width: 26px;
    height: 24;
    margin-left: 1px;
  }

  &__backArrowMobile {
    width: 31px;
    padding: 7px;
  }
  &__backArrowMobileNew {
    width: 31px;
    height: 31px;
    padding: 7px;
  }

  &__headElementsDivProposal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__headElementsDivProposalNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__headerNameText {
    display: flex;
    height: 32px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: white !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__replyRateText {
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #a7b3d9 !important;
    padding-left: 12px;
  }

  &__replyRateTextBlue {
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #ffffff !important;
    padding-left: 2px;
  }

  &__headerNameTextProposal {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #0f0f60 !important;
    padding-left: 20px;
    padding-right: 6px;
  }
  &__headerNameTextProposalNew {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff !important;
    padding-left: 8px;
    padding-right: 6px;
  }

  &__headerH2NameText {
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #a7b3d9 !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__headerH2NameTextProposal {
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #a7b3d9 !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__appSettingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-size: 30px;
    cursor: pointer;
    color: #a7b3d9 !important;
  }

  &__messageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: calc(100% - 45px);
    overflow-y: scroll;
  }

  &__messageContainerDesktopProposal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 88px);
  }

  &__chatBottomOptionsDiv {
    display: flex;
    flex-direction: column;
    // height: 125px !important;
    justify-content: space-between;
  }
  &__chatBottomOptionsRightNavDiv {
    display: flex;
    flex-direction: column;
    height: 100px !important;
    margin-bottom: 50px !important;
    justify-content: space-between;
  }
  &__chatBottomOptionsParentDiv {
    display: flex;
    flex-direction: column;
    // height: 50px !important;
  }

  &__messageContainerMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 20px);
    overflow-y: scroll;
  }

  &__messageContainerMobileProposal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 40px);
  }

  &__scrollDiv {
    height: 1px;
  }

  &__messageDiv {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 8px;
    width: 100%;
    // height: calc(100% + 24px);
    position: relative;
    overflow-y: scroll;
    padding-bottom: 10px;
  }

  &__messageDivMobile {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 8px;
    padding-bottom: 8px;
    width: 100%;
    overflow-y: auto;
  }

  &__noMessageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    margin-top: 10px;
    color: #a7b3d9 !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }

  &__noMessageDivRightNav {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    color: #a7b3d9 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  &__chatInputDiv {
    display: flex;
    width: calc(100% - 516px);
    position: absolute;
    bottom: 32px;
  }

  &__chatInputDivRightNav {
    display: flex;
    width: 234px;
    position: absolute;
    bottom: 8px;
  }

  &__chatInputDivMobile {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  &__chatQuizDivMobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30px;
    right: 20px;
    z-index: 999;
  }
  &__chatQuizDivRightNavChat {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: right;
    width: 225px;
    position: absolute;
    bottom: 35px;
    z-index: 999;
  }
  &__chatQuizDivDesktop {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: right;
    margin-right: 20px;
    z-index: 999;
  }

  &__scheduleCallDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    width: 250px;
  }
  &__scheduleCallDivDesktopProposal {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  &__scheduleCallDivProposal {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    // margin-left: 28px;
    position: absolute;
    top: 144px;
  }

  &__quizOptionDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 4px;
    border: 1px solid #a7b3d9;
    border-radius: 10px;
    background-color: #e7eefd;
  }

  &__quizOptionText {
    display: flex;
    height: 32px;
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #1b438b !important;
  }
}
