.playbookDetails {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 30px;
  margin-bottom: 30px;

  &__searchContainer {
    &__addIcon {
      text-align: end;
      margin-right: 16px;
      margin-top: 16px;
      cursor: pointer;
    }
  }
  &__tagsdescription {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    &__tagsAndActions {
      &__left {
        &__eachPill {
          // border: 1px solid #a7b3d9 !important;
          cursor: pointer;
          text-align: center !important;
          border-radius: 15px;
          font-size: 12px !important;
          color: white !important;
          margin-right: 8px;
          padding: 4px 16px 4px 16px !important;
          display: inline-block !important;
          background-color: #56a5ff !important;
          margin-top: 6px;
        }
      }
    }
  }
  &__carouselContainer {
    width: 100% !important;
  }
  &__tagsHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  &__centerAlign {
    display: block !important;
    margin-left: auto !important;
    height: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    align-items: center !important;
  }
  &__image {
    width: 10%;
    align-items: flex-center !important;
    justify-content: center !important;
  }
  &__playbookTitle {
    margin-top: 8px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px;
  }
  &__welcomeText {
    margin-top: 8px;
    color: #0f0f60;
    font-size: 14px;
  }
  &__positiveBtn {
    align-items: flex-center;
    margin-top: 16px !important;
    width: 160px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    background-color: #84d830 !important;
  }

  &__aboutHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  &__highLights {
    &__text {
      font-size: 14px !important;
      color: #0f0f60;
      margin-left: 16px !important;
    }
  }

  &__description {
    margin-top: 8px;
    font-size: 14px;
    color: #0f0f60;
  }

  &__reviewsInfo {
    color: #fe943b !important;
    font-size: 12px !important;
    font-weight: bold !important;
    &__count {
      color: #a7b3d9 !important;
      font-size: 12px !important;
    }
  }

  &__languageCategory {
    display: flex !important;

    &__heading {
      width: 40%;
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold !important;
      margin-bottom: 0px !important;
    }
    &__value {
      width: 40%;
      font-size: 12px;
      color: #0f0f60;
    }
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  &__recommendedHeading {
    text-align: center;
    margin-top: 16px;
    font-size: 22px;
    font-weight: bold;
  }
  &__recommend {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  &__list {
    overflow-x: auto !important;
    width: 100% !important;
    background-color: white;

    .ant-list-items {
      display: flex !important;
      // flex-wrap: nowrap;
      // width: 47%;
    }
    .ant-list-items .playbookDetails__datas {
      width: 33.33% !important;
    }
  }
  &__divList {
    width: 662px !important;
    margin: auto;
    // margin: 0px;
    // margin-right: 8px;
    // margin-left: 8px;
  }
  &__comparePackage {
    color: #0f0f60 !important;
    margin-top: 32px;
    font-weight: bold !important;
    font-size: 12px !important;
  }
  .ant-carousel {
    width: 600px !important;
    min-height: 0;
    min-width: 0;
    margin: 0 auto !important;
    margin-top: 8px !important;
  }

  &__customAvatar {
    &__avatar {
      height: 55px !important;
      width: 55px !important;
      font-size: 28px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }
}
