.customAvatar {
  &__avatar {
    height: 30px !important;
    width: 30px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border: 1px solid #a7b3a7 !important;
    color: white !important;
    background-color: rgb(77, 28, 237);
  }

  &__avatarMobile {
    height: 40px !important;
    width: 40px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border: 1px solid #a7b3a7 !important;
    color: white !important;
    background-color: rgb(77, 28, 237);
  }
}
