.pasteQuery {
  width: 100% !important;

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2px;
    }

    &-image {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    &-headingContainer {
      display: flex;
      flex-direction: column;
      margin-left: 8px !important;
    }

    &-mainHeading {
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0;
    }

    &-subHeading {
      font-family: Lato;
      font-weight: normal;
      font-size: 10pt;
      text-align: left;
      margin-top: 8px !important;
      margin: 0 0 0 -36px;
    }
  }

  &__customCardContent {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    margin-top: 16px !important;
    margin-left: 8px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }

    &-image {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    &-mainHeading {
      flex: 1;
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 8px 0px 0px 8px;
    }

    &-textInput {
      border-radius: 8px !important;
      width: 100% !important;
      height: 200px !important;
      padding: 8px !important;
      margin-top: 8px !important;
      font-family: "Lato" !important;
      padding: 16px !important;
      font-size: 14px !important;
      color: #000000 !important;
      background-color: #eff4fa !important;
      border: 1px #e4e4e4 solid;
      resize: none;

      &::placeholder {
        color: #717171 !important;
      }
    }

    &-saveButton {
      background-color: #3872cd !important;
      color: #f0f5fb !important;
      font-size: 10pt !important;
      font-family: "Lato", sans-serif !important;
      font-weight: bold !important;
      border-radius: 14px !important;
      margin-top: 16px !important;
      float: right !important;
      border: none !important;
      height: 30px !important;
    }
  }

  .ant-collapse-header {
    padding-right: 32px !important;
  }

  .ant-collapse-arrow {
    display: none !important; // Hide the expand icon
  }
}
