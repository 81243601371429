.createplaybookDetails {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 30px;
  margin-bottom: 30px;

  // .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
  // object-fit: cover;
  // aspect-ratio: 1/2;
  // }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    position: absolute;
    left: 0px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 400px !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: #0f0f60;
  }
  // .ant-carousel .slick-dots li button::after {
  //   background-color: black;
  //   border-radius: 10px;
  //   margin-top: 4px;
  //   margin-bottom: 2px;
  // }
  .carousel-indicators [data-bs-target] {
    background-color: #0f0f60 !important;
  }
  .ant-upload-list-picture-card-container {
    width: 100% !important;
    height: 400px !important;
  }
  .carousel-indicators {
    margin: auto;
    background-color: #eff2fc;
  }

  &__rightAlign {
    text-align: end;
  }
  &__cameraAddPhoto {
    margin: 12px 0px;
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
  &__uploadPromotionalMediaContainer {
    background-color: white !important;
    margin-top: 16px !important;
    padding: 16px;
    // display: none !important;
    border-radius: 8px;
  }
  &__uploadPromotionalMedia {
    background-color: #eff2fc;
    padding: 8px;
    display: flex;
    &__leftSide {
      width: 90%;

      &__heading {
        color: #0f0f60;
        font-size: 14px;
        font-weight: bold;
      }

      &__subHeading {
        margin-top: 10px;
        color: #a7b3d9;
        font-size: 14px;
      }
    }
    &__rightSide {
      width: 10%;
      &__image {
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: block;
      }
    }
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: #56a5ff !important;
  }
  .ant-input-affix-wrapper {
    width: 95%;
    border-radius: 4px;
  }

  &__addGeneralDetails {
    background-color: white !important;
    padding: 16px;
    border-radius: 8px;

    &__tagsContainer {
      display: flex !important;
      &__primary {
        width: 10%;
      }
    }
  }

  &__saveSourceButton-1 {
    width: 120px;
    // border: none !important;
    height: 34px !important;
    text-align: center !important;
    border-radius: 20px !important;
    margin-top: 24px;
    // margin-bottom: 24px;
    margin-right: 20px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    // float: right;
    color: #56a5ff !important;
    border: 1px solid #56a5ff !important;
  }
  &__saveSourceButton-2 {
    width: 160px;
    border: none !important;
    height: 34px !important;
    text-align: center !important;
    border-radius: 20px !important;
    margin-top: 24px;
    // margin-bottom: 24px;
    // margin-right: 20px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    // float: right;
    color: white !important;
    background-color: #56a5ff !important;
  }
  &__Buttons {
    display: flex;
    justify-content: center;
  }
  &__Or {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    margin-left: 22%;
    &__Pispace-2 {
      color: #f0f2fb;
    }
    &__Pispace-1 {
      color: #f0f2fb;
    }
    &__Pi {
      color: #56a5ff;
    }
  }

  &__Buttons-2 {
    margin-top: 24px;
    color: #56a5ff;
    &__Skip {
      margin-left: 22%;
      cursor: pointer;
      border: none;
      background-color: white;
    }
  }

  // &__carouselContainer {
  //   width: 100% !important;
  // }
  &__centerAlign {
    display: block !important;
    margin-left: auto !important;
    height: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    align-items: center !important;
  }
  &__image {
    width: 10%;
    align-items: flex-center !important;
    justify-content: center !important;
  }
  &__playbookTitle {
    margin-top: 8px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px;
    width: 50%;
    &__label {
      display: flex;
      flex-direction: column;
    }
  }
  &__welcomeText {
    margin-top: 8px;
    color: #0f0f60;
    font-size: 14px;
  }
  &__positiveBtn {
    align-items: flex-center;
    margin-top: 16px !important;
    width: 160px;
    border: none !important;
    height: 30px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    background-color: #84d830 !important;
  }

  &__aboutHighlight {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
    // &__high {
    //   margin-bottom: 8px;
    //   // width: 190%;
    // }
  }
  &__aboutHeading {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  &__shortDescription {
    margin-top: 16px;
    color: #0f0f60;
    font-weight: bold !important;
    font-size: 14px !important;
    &__shortInput {
      width: 100px;
    }
  }

  &__highLights {
    &__text {
      font-size: 14px !important;
      color: #0f0f60;
      margin-left: 16px !important;
      margin: 4px;
    }
  }

  &__description {
    &__Addtags {
      font-weight: bold;
    }
  }

  &__reviewsInfo {
    color: #fe943b !important;
    font-size: 12px !important;
    font-weight: bold !important;
    &__count {
      color: #a7b3d9 !important;
      font-size: 12px !important;
    }
  }

  &__languageCategory {
    display: flex !important;
    width: 124%;

    &__heading {
      width: 40%;
      color: #a7b3d9;
      font-size: 14px;
      font-weight: bold !important;
      margin-bottom: 0px !important;
      &__cate {
        margin-left: 4px;
      }
    }
    &__value {
      width: 40%;
      font-size: 12px;
      color: #0f0f60;
      margin-right: 4px;
      &__categoryDropdown {
        font-weight: bold;
      }
      &__languageDefault {
        font-weight: bold;
      }
      &__tagColor {
        font-weight: bold;
      }
      &__P-tag {
        font-weight: bold;
      }
      .ant-input:placeholder-shown {
        border-radius: 4px;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 4px;
      }
    }
  }
  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  &__comparePackage {
    color: #0f0f60 !important;
    margin-top: 32px;
    font-weight: bold !important;
    font-size: 12px !important;
    &__Package {
      margin-top: 500px;
    }
  }
  // .ant-carousel {
  //   width: 600px !important;
  //   min-height: 0;
  //   min-width: 0;
  //   margin: 0 auto !important;
  //   margin-top: 12px !important;
  // }

  &__customAvatar {
    &__avatar {
      height: 55px !important;
      width: 55px !important;
      font-size: 28px !important;
      font-weight: bold !important;
      border: 1px solid #a7b3a7 !important;
      color: white !important;
      background-color: rgb(77, 28, 237);
    }
  }

  .ant-input-affix-wrapper {
    height: 34px !important;
    max-height: 34px !important;
    border-radius: 4px !important;
    color: #0f0f60 !important;
    width: 100%;
    border-color: #a7b3d9;
    font-size: 14px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: 24px !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    border-color: #a7b3d9 !important;
    color: white !important;
    font-size: 14px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #56a5ff !important;
  }

  .ant-select-selection-item {
    // background-color: #56a5ff !important;
    color: black !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    min-width: 120px;
    text-align: left;
    label {
      font-size: 12px;
      color: #0f0f60 !important;
    }
  }
  .ant-input {
    height: 34px !important;
    border: 1px solid #a7b3d9;
    border-radius: 4px;
    max-width: 100%;
  }

  &__categoryDropdown {
    .ant-select-selector {
      border-color: #a7b3d9 !important;
      color: #0f0f60 !important;
      font-size: 14px !important;
      height: 34px !important;
    }
    .ant-select-arrow {
      color: #0f0f60 !important;
    }
    label {
      color: #0f0f60 !important;
      font-size: 12px !important;
    }
    .ant-select-selection-item {
      background-color: white !important;
      color: #0f0f60 !important;
    }
    .ant-select-selection-item-remove {
      color: white !important;
    }
  }
}
.primaryTag {
  .ant-select-selection-item {
    background-color: white !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    border-color: #a7b3d9 !important;
    font-size: 12px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #0f0f60 !important;
  }
}
