.saveDatasetDetails {
  width: 100% !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 0px !important;
  margin-top: 16px !important;
  margin-left: 8px !important;
  cursor: pointer !important;
  margin-right: 8px !important;
  margin-top: 11px !important;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }

  &-image {
    width: 20px;
    height: 20px;
  }

  &-mainHeading {
    flex: 1;
    font-family: Lato;
    font-weight: bold;
    font-size: 12pt;
    text-align: left;
    margin: 16px 0px 0px 8px;
  }

  &-textInput {
    border-radius: 8px !important;
    width: 100% !important;
    height: 40px !important;
    padding: 8px !important;
    margin-top: 8px !important;
    font-family: "Lato" !important;
    padding: 16px !important;
    font-size: 14px !important;
    color: #000000 !important;
    background-color: #eff4fa !important;
    border: 1px #e4e4e4 solid;
    resize: none; // prevent resizing
  }

  &-descriptionInput {
    border-radius: 8px !important;
    width: 100% !important;
    height: 200px !important;
    padding: 8px !important;
    margin-top: 8px !important;
    font-family: "Lato" !important;
    padding: 16px !important;
    font-size: 14px !important;
    color: #000000 !important;
    background-color: #eff4fa !important;
    border: 1px #e4e4e4 solid;
    resize: none;

    &::placeholder {
      color: #717171 !important;
    }
  }

  &__button {
    margin: 16px 0 0 0 !important;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 40px !important;
    width: 150px !important;
    border-radius: 18px !important;
    background-color: #1c74d4 !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    float: right !important;
  }

  &-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
}

.ant-input {
  color: #000000 !important;
  vertical-align: top !important;
}
