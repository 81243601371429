.addMetadata {
  height: 100%;

  &__heading {
    color: #0f0f60;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  &__subHeading {
    color: #0f0f60;
    font-size: 12px;
    margin-bottom: 12px;
  }

  .ant-form-item {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    min-width: 120px;
    text-align: left;
    label {
      font-size: 12px;
      font-weight: 500;
      color: #0f0f60 !important;
    }
  }
  .ant-input {
    height: 32px !important;
    border: 1px solid #0f0f5c !important;
    border-radius: 0px !important;
    max-width: 300px !important;
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .ant-picker {
    width: 100% !important;
    max-width: 300px !important;
    height: 32px !important;
    border: 1px solid #0f0f5c !important;
    border-radius: 0px !important;
    input {
      font-size: 12px !important;
      font-weight: bold !important;
    }
  }

  .ant-select-selector {
    border: 1px solid #0f0f5c !important;
    border-radius: 0px !important;
    background-color: white !important;
    color: #0f0f60 !important;
    font-size: 13px !important;
    font-weight: bold !important;
    .ant-select-selection-item {
      font-size: 13px !important;
      font-weight: bold !important;
    }
  }
  .ant-select-auto-complete {
    .ant-select-selection-search {
      input {
        font-size: 13px !important;
        font-weight: bold !important;
      }
    }
  }

  &__divider {
    background-color: #a7b3d9;
    margin-top: 0px !important;
    margin-bottom: 17px !important;
  }

  &__tags {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    &__para {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    &__customTags {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      max-height: 60px;
      overflow-y: auto;
      &__tags {
        display: inline;
        max-width: 100%;
      }
    }
    &__row {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      &__headerLeftContent {
        min-width: 290px;
      }
      &__headerLeftContent,
      &__headerRightContent {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        color: #a7b3d9 !important;
      }
      &__tagsLeftContent {
        min-width: 290px;
        display: inline-flex;
        &__key {
          text-align: left;
          padding: 4px 8px;
          font-size: 12px;
          font-weight: bold;
          color: #0f0f60 !important;
          background-color: #f0f2fb;
          display: inline-flex;
          flex: 1;
        }

        &__colon {
          background-color: white !important;
          width: 10px;
          display: inline-flex;
          padding-left: 3px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    &__container {
      max-height: 100px;
      overflow-y: auto;
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__error {
      color: #ff0000;
      font-size: 12px;
      line-height: 15px;
    }
    &__continueBtn {
      background-color: #98d650 !important;
      width: 127px !important;
      height: 30px !important;
      border: none !important;
      text-align: center;
      border-radius: 15px !important;
      font-size: 12px !important;
      font-weight: bold !important;
      float: right;
      color: white !important;
      outline: none !important;
      margin-top: 8px;
    }
  }

  .ant-tag {
    display: inline-flex;
    align-items: center;
    height: 26px !important;
    border-radius: 13px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 26px !important;
    padding: 4px 16px 4px 16px !important;
    margin: 4px !important;
    background-color: #56a5ff !important;
    color: white !important;
    border: none !important;
  }
  .ant-tag-close-icon {
    color: white !important;
    font-size: 12px;
    font-weight: bold;
    margin-left: 8px;
    margin-right: -8px;
  }

  &__searchIcon {
    color: #a7b3d9 !important;
    font-size: 16px !important;
  }
}

.fieldLabelWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 100px;

  &__label {
    width: 150px;
    color: #0f0f60;
    font-size: 14px;
    font-weight: bold;
  }
}
