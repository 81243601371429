.filterDatePicker {
  margin-bottom: 12px !important;

  .ant-picker {
    border-color: #cecece !important;
    color: #000000 !important;
    height: 34px !important;
    width: 100% !important;
    input {
      font-size: 12px !important;
    }
  }
  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
    width: 117px !important;
  }
}
