.switchRole {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;

  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  &__headerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    min-height: 60px !important;
    padding-left: 24px;
    padding-right: 16px;
    border-bottom: 1px solid #A7B3D9 !important;
  }
  &__header {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    height: 198px !important;
  }

  &__headerMobile {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    height: 198px !important;
    border: 1px solid #A7B3D9 !important;
    margin: 20px;
  }

  &__buyerProfileContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    margin-top: 19px;
    margin-left: 24px;
  }

  &__headerNameInfoDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 135px !important;
    border-bottom: 1px solid #A7B3D9 !important;
    padding-left: 24px;
  }

  &__radio{
    width: 18px !important;
    height: 18px !important;
    margin-left: 50px !important;
    cursor: pointer;
  }

  &__buyerActiveStatusDiv{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  &__sellerProfileContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    margin-top: 24px;
    margin-left: 24px;
  }

  &__sellerActiveStatusDiv{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  &__headerImg {
    float: right !important;
    height: 35px !important;
    width: 35px !important;
    cursor: pointer;
  }

  &__headerText {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #0f0f60;
  }
  
  &__profileText {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 15px !important;
    color: #A7B3D9 !important;
  }

  &__regularTextBlue {
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0F0F60 !important;
  }

  &__regularTextLightBlue {
    font-size: 12px !important;
    line-height: 14px !important;
    margin-top: 8px;
    margin-left: 24px;
    color: #56A5FF !important;
    cursor: pointer;
  }

  &__regularTextGrey {
    font-size: 12px !important;
    line-height: 15px !important;
    color: #A7B3D9 !important;
    margin-top: 4px !important;
  }
  
  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__content {
    min-height: "100%";
    height: "100%";
    background-color: #ffffff;
    overflow-y: scroll;
  }
  &__rightNav {
    background-color: #0f0f60;
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }

  &__menu {
    background: #100f5c;
  }
  
}
