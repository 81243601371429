.PreviewYourList {
  width: 100% !important;

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2px;
    }

    &-image {
      // margin-right: 8px;
      width: 24px !important;
      height: 24px !important;
    }

    &-headingContainer {
      display: flex;
      flex-direction: column;
      margin-left: 8px !important;
    }

    &-mainHeading {
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0;
    }

    &-subHeading {
      font-family: Lato;
      font-weight: normal;
      font-size: 10pt;
      text-align: left;
      margin-top: 8px !important;
      margin: 0 0 0 -30px;
    }
  }

  &__customCardContent {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    margin-top: 16px !important;
    margin-left: 8px !important;
    cursor: pointer !important;
    margin-right: 8px !important;
    margin-top: 11px !important;

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }

    &-image {
      width: 20px;
      height: 20px;
    }

    &-mainHeading {
      flex: 1;
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0px 0px 0px 8px;
    }

    &-subHeading {
      margin-top: 16px;
    }

    &-sheetIcon {
      margin-right: 8px;
      margin-top: -24px;
      margin-left: 8px;
      width: 20px;
      height: 20px;
    }
  }

  .no-data-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Adjust height as needed */
    padding: 10px;
    /* Spacing around the message */
    text-align: center;
  }

  .no-data-message p {
    color: #c2c3cc;
    /* Medium grey text color */
    font-size: 14px;
    /* Adjust font size as needed */
    margin: 0;
  }

  .ant-collapse-header {
    padding-right: 32px !important;
  }

  .ant-collapse-arrow {
    display: none !important; // Hide the expand icon
  }
}