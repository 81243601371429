.bottomNav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    
    .bottom-nav{
        cursor: pointer;
    }

    &__navText{
        font-size: 10px;
        color: #0F0F60;
    }
}