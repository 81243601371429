.wbPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-top: 40px;
  min-height: calc(100% - 166px);

  &__container {
    height: 100%;
  }

  &__signinFields {
    width: 273px;
  }

  &--withoutShadow {
    box-shadow: unset;
    padding: unset;
  }

  &__headerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__headerTitle {
    display: flex;
    font-size: 25px;
    text-align: center;
    justify-content: center;
    color: #0f0f60 !important;
  }

  &__footerText {
    font-weight: bold;
    color: white;
    background: white;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    font-weight: normal;
    justify-content: flex-end;
    width: 100vw;
    background-color: white !important;
    margin-top: 16px;
  }
  &__namesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 8px;
  }
  &__footer {
    display: flex;
    align-items: center;
    font-weight: normal;
    justify-content: center;
    width: 100vw;
  }
  &__inputField {
    min-width: 273px;
    height: 32px;
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
  }
  &__googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 32px;
  }

  &__continueButton {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    margin-top: 28px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #84d830 !important;
    color: white !important;
    font-size: 16px !important;
    text-align: center !important;
  }
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    min-width: 128px;
    margin-left: 12px;
    margin-right: 20px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #56a5ff !important;
    color: white !important;
    font-size: 16px !important;
    text-align: center !important;
  }
  &__footer {
    font-weight: normal;
  }
  &__linkButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
    height: 13px !important;
    padding-right: 0px !important;
    margin-top: 8px !important;
  }

  &__textDonthaveAccount {
    color: #a7b3d9 !important;
    font-size: 12px !important;
  }
  &__textNotYou {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
    color: #0f0f60 !important;
    font-size: 12px !important;
    margin-top: 4px;
    cursor: pointer;
  }
  &__textLightBlueFont12 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
    color: #56a5ff !important;
    font-size: 12px !important;
    margin-top: 4px;
    cursor: pointer;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 10px;
  }
}
