.selectYourDestination {
  width: 100% !important;

  &__button {
    margin-top: 16px !important;
    margin-bottom: 120px !important;
    margin-left: 200px !important;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;

    align-items: center !important;
    height: 40px !important;
    width: 150px !important;
    border-radius: 18px !important;
    background-color: #1c74d4 !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }

  &__customList {
    &-listItem {
      padding: 0 !important;
    }

    &-card {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
      margin-top: 12px !important;
      border-radius: 8px !important;
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      min-height: 80px !important;
    }

    &-cardContent {
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
    }

    &-radio .ant-radio-inner {
      margin-left: 16px !important;
      border-radius: 50% !important; /* Make the checkbox rounded */
      width: 17px !important; /* Adjust width */
      height: 17px !important; /* Adjust height */
      border-color: #5A8AE1 !important; /* Border color */

      &::after {
        background-color: #56a5ff !important;
      }
    }

    &-image {
      margin-left: 8px !important;
    }

    &-textContainer {
      margin-left: 32px !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      height: 100% !important;

      &--centered {
        justify-content: center !important;
      }
    }

    &-primaryText {
      margin: 0;
      font-family: Lato, sans-serif;
      font-weight: 400;
      font-size: 14pt;
      color: #212121;
    }

    &-secondaryText {
      margin: 0;
      font-family: Lato, sans-serif;
      font-weight: 300;
      font-size: 12pt;
      color: #07074b;
    }
  }

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2px;
    }

    &-image {
      // margin-right: 8px;
      width: 24px !important;
      height: 24px !important;
    }

    &-sideImage {
      height: 24px !important;
      width: 24px !important;
      margin-top: 0px !important;
      margin-right: 24px !important;
      margin-left: 110px !important;
    }

    &-headingContainer {
      display: flex;
      flex-direction: column;
      margin-left: 8px !important;
    }

    &-mainHeading {
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0;
    }

    &-subHeading {
      font-family: Lato;
      font-weight: normal;
      font-size: 10pt;
      text-align: left;
      margin-top: 8px !important;
      margin: 0 0 0 -30px;
    }
  }

  .ant-collapse-header {
    padding-right: 32px !important;
  }

  .ant-collapse-arrow {
    display: none !important; // Hide the expand icon
  }

  .ant-input {
    vertical-align: top !important;
    color: #000000 !important;
  }
}
