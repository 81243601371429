.LinkedIn {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 20px;
  margin-bottom: 10px;
  // height: 100vh !important;
  border: 1px solid #dcdee3;
  padding: 0px 10% 34px 10%;

  .ant-col-4 {
    margin-top: 6px;
    margin-left: -16px;
  }

  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
    border: 2px solid #2d64bc;
    background-color: #d8d9de;
    color: #2d64bc;
    border-radius: 20px;
    margin-left: 10%;
    font-size: 14px;
    padding-bottom: 26px;
  }

  span.ant-avatar.ant-avatar-circle.ant-avatar-image.LinkedIn__customAvatar__avatar {
    width: 45px !important;
    height: 45px !important;
    //   margin-right: 8px;
  }
  &__Row-1 {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  &__appHomeButton {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 16px 0px 16px;
  }
  &__appHomeMainHead {
    font-weight: bold;
    font-size: 14px;
    margin-left: 14px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #07074b;
    margin-right: 10px;
    margin-top: 0px;
    // width: 192px;
  }
  &__appHomeMainHead:hover {
    cursor: pointer;
  }
  &__appHomeSubHead {
    font-size: 12px !important;
    color: #a7b3d9;
    margin-left: 14px;
    line-height: 15px;
    font-size: 14px;
    // width: 200px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__appHomeSubHead:hover {
    cursor: pointer;
  }
  &__appDotsIcon {
    position: absolute;
    left: 196px;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: black !important;
    margin-top: 0px;
    // padding-left: 100px;
  }
  &__Description {
    font-size: 12px;
    margin-left: 2px;
    margin-bottom: -6px;
  }
  &__IMG1 {
    width: 100% !important;
    border: 1px solid #a9b3d6;
  }
  &__Grey {
    background-color: #f1f2f6;
    width: 100% !important;
    padding: 8px 8px 0.5px 8px;

    &__FirstContent {
      // font-weight: bold;
      font-size: 12px;
      // margin-left: 16px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      color: #818181;
      margin-right: 10px;
      margin-top: 0px;
    }
    &__SecondContent {
      font-weight: bold;
      font-size: 16px;
      line-height: 2;
      // margin-left: 16px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      color: #07074b;
      margin-right: 10px;
      margin-top: 0px;
    }
    &__Learn {
      margin-top: 0px;
    }
    &__Des {
      font-size: 12px;
      color: #818181;
      width: 300px;
    }
  }
  &__Create {
    position: absolute !important;
    right: 20%;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px;
    color: white !important;
    border: none !important;
  }
}

// _____________________________________________________________

.LinkedInViewbutton {
  // margin-left: 22%;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px;
  padding-top: 20px;
  &__DesktopButton {
    border: none !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__DesktopButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__DesktopButton:hover {
    color: white !important;
  }
  &__MobileButton {
    border: none !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__MobileButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__MobileButton:hover {
    color: white !important;
  }
}

// _____________________________________________________________

.LinkedInMobile {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid #dcdee3;
  padding: 0px 22px 34px 22px;
  .ant-col-4 {
    margin-top: 6px;
    margin-left: -16px;
  }

  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
    border: 2px solid #2d64bc;
    background-color: #d8d9de;
    color: #2d64bc;
    border-radius: 20px;
    // margin-left: 10%;
    font-size: 14px;
    padding-bottom: 26px;
  }

  span.ant-avatar.ant-avatar-circle.ant-avatar-image.LinkedIn__customAvatar__avatar {
    width: 45px !important;
    height: 45px !important;
    //   margin-right: 8px;
  }
  &__Row-1 {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  &__appHomeButton {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 16px 0px 16px;
  }
  &__appHomeMainHead {
    font-weight: bold;
    font-size: 14px;
    margin-left: 14px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #07074b;
    margin-right: 10px;
    margin-top: 0px;
    // width: 192px;
  }
  &__appHomeMainHead:hover {
    cursor: pointer;
  }
  &__appHomeSubHead {
    font-size: 12px !important;
    color: #a7b3d9;
    margin-left: 14px;
    line-height: 15px;
    font-size: 14px;
    // width: 200px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__appHomeSubHead:hover {
    cursor: pointer;
  }
  &__appDotsIcon {
    position: absolute;
    left: 152px;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: black !important;
    margin-top: 0px;
    // padding-left: 100px;
  }
  &__Description {
    font-size: 12px;
    margin-left: 2px;
    margin-bottom: -6px;
  }
  &__IMG1 {
    width: 100% !important;
    border: 1px solid #a9b3d6;
  }
  &__Grey {
    background-color: #f1f2f6;
    width: 100% !important;
    padding: 8px 8px 0.5px 8px;

    &__FirstContent {
      // font-weight: bold;
      font-size: 12px;
      // margin-left: 16px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      color: #818181;
      margin-right: 10px;
      margin-top: 0px;
    }
    &__SecondContent {
      font-weight: bold;
      font-size: 16px;
      // margin-left: 16px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      color: #07074b;
      margin-right: 10px;
      margin-top: 0px;
    }
    &__Learn {
      margin-top: 0px;
    }
    &__Des {
      font-size: 12px;
      color: #818181;
      width: 300px;
    }
  }
  &__Create {
    position: absolute !important;
    right: 20%;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px;
    color: white !important;
    border: none !important;
  }
}
