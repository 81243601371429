.appLeftNavMobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #eff1fb !important;

    .nav-menu {
        width: 378px;
      }

      &__dividerBorder{
        background: #a7b3d9;
        margin: 0 !important;
        height: 1px;
      }

      
}

.ant-drawer-body{
    padding: 0px !important;
  }
  
  .ant-drawer-header{
    padding: 0px !important;
    height: 0px;
    border-bottom: 10px #a7b3d9 solid;
  }