.recordDetailsPopup {
  width: 874px !important;
  height: 519px !important;
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 28px !important;

  .ant-modal-content {
    background-color: #fff !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .ant-modal-body {
    width: 100% !important;
    height: 100% !important;
    padding: 0px !important;
  }

  &__container {
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 12px 24px 12px 24px;
      border-bottom: 1px solid #f2f2f2;

      &__title {
        width: 80%;
        max-width: 80%;
        min-width: 80%;
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin-right: 16px;
      }

      &__close {
        cursor: pointer;
      }
    }

    &__list {
      width: 100% !important;
      padding: 0px 24px 12px 24px !important;
      overflow-y: scroll;

      &__row {
        padding: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--dark {
          background-color: #f2f2f2;
        }

        &__key {
          width: 158px;
          max-width: 158px;
          min-width: 158px;
          color: #cecece;
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
          margin-right: 24px;
          text-align: left;
        }
        &__value {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
        }
      }
    }
  }
}
