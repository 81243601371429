.ModelQueryBuilder {
  &__headerText {
    margin: 2% 0% 2% 3%;
    font-size: 15px;
    font-weight: bold;
  }

  &__title {
    margin-top: 4px;
    margin-left: 1%;
    color: #a7b3d9;
  }
  &__titleSelected {
    margin-top: 4px;
    color: #001f3f; /* Change to the desired color */
    margin-left: 1%;
  }
  &__wrapper {
    margin: 30px 3% !important;
    width: 878px;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  &__input {
    width: 100% !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 5px !important;
  }

  &__input2 {
    width: 100% !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 5px !important;
    pointer-events: none;
  }

  &__heading {
    text-align: start;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: -3px !important;
    font-weight: bold;
  }
  &__container {
    color: #0f0e60;
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-left: 28px;
    color: #0f0e60;
  }
  
  &__description {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 28px;
    color: #0f0e60;
  }

  &__wrapper {
    height: 45vh;
    width: 93.5%;
    margin-left: 28px;
    font-size: 14px;
    margin-top: 20px; /* Optional: Add margin-top for spacing */
    border: 1px solid #0f0e60; /* Add border style */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
    padding: 10px;
  }

 &__button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  
    /* Apply margin to the button */
    button {
      margin-right: 35px; /* Adjust the margin as needed */
      border-radius: 6px !important; /* Example color, change as needed */
      background-color: #3972ce !important;
      font-weight: bold !important;
      border-radius: 6px !important;
      color: white !important;
      border: 0px !important;
      font-size: 14px !important;
      line-height: 17px !important;
    }
    button:hover {
      background-color: #3972ce;
      color: white;
    }
  }

}

