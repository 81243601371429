.dashBoardPage {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  &__containerMobileView {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &__containerDesktopView {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  &__normalText {
    height: 32px;
    margin: 16px;

    color: white;
  }

  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__content {
    min-height: 100%;
    height: 100vh;
    margin: 8px 8px 8px 8px;
    background-color: #ffffff;
    overflow-y: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__contentMobile {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  &__contentPeople {
    display: flex;
    min-height: 100%;
    height: 100%;
    // margin: 12px 24px 48px 24px;
    background-color: #ffffff;
    border-radius: 0px;
  }

  &__rightNav {
    background-color: #F2F2F2;
    width: 60px;
    min-width: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 56px;
  }

  &__settingsRightNav {
    width: 234px;
    min-width: 234px;
    display: flex;
  }

  &__menu {
    background: #100f5c;
  }

  .ant-layout {
    background-color: #F2F2F2 !important;
  }

  .app-header {
    height: 84px !important;
    background-color: #F2F2F2 !important;
    font-size: 18px !important;
    color: #0f0f60 !important;
  }
}
