.topHeading {
  font-size: 20px;
  color: #0f0e60;
  font-weight: 700;
  display: flex;
  justify-content: center; /* Horizontally center */
}

.modelingMethodContainer {
  /* Styles for the container */
  height: 86px;
  background: rgb(243, 246, 255);
  padding: 20px;
  margin-top: 20px !important;
  max-width: 380px;
  width: 100%;
  margin: auto;
  border-radius: 20px;
  display: flex;
  gap: 2px; /* You can use 'gap' property if supported or 'justify-content' for spacing */
  border: 1px solid #ccc; /* Default border color */
  cursor: pointer;
}

.modelingMethodContainer.selected {
  border-color: #0f0e60; /* Border color when selected */
}

.modelingMethodContainer:hover {
  transform: scale(1.01); /* Increase size on hover */
  border-color: #0f0e60;
}

.iconContainer {
  line-height: 0%;
  margin-right: 12px;
  margin-top: 4px;
  /* Styles for the icon container */
  /* Define icon-specific styles */
}

.methodDescription {
  /* Styles for the method description */
  flex: 1;
  text-align: left;
}

.methodDescription h5 {
  /* Styles for the method title */
  /* You can define font size, color, etc. */
  font-size: 16px;
  color: #0f0e60;
}
.methodDescription p {
  /* Styles for the method description */
  /* You can define font size, color, margin, etc. */
  margin: 0;
  line-height: normal;
  font-size: 14px;
  color: #0f0e60;
}
.methodDescription h6 {
  /* Styles for the method description */
  /* You can define font size, color, margin, etc. */
  margin: 0;
  line-height: normal;
  font-size: 12px;
  color: #0f0e60;
}
.queryEditorContainer {
  text-align: left;
  margin-bottom: 20px;
  line-height: 0%;
}

.queryEditorTitle {
  font-size: 20px;
  font-weight: bold;
  margin-left: 28px;
  color: #0f0e60;
}

.queryEditorDescription {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 28px;
  color: #0f0e60;
}
.modelHeading {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 28px;
  color: #0f0e60;
}
.modelTitle {
  font-size: 20px;
  font-weight: bold;
  margin-left: 28px;
  color: #0f0e60;
}
.modelParagraph {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 28px;
  color: #0f0e60;
}
.uniqueIdentifierContainer {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  margin-top: 10px; /* Add some space between unique identifier input and the rest of the content */
  margin-left: 28px;
  //width: 500px;

  &__tableDiv{
    margin: 2% 4% 0% 2%;
}
&__buttonDiv{
  display: flex; /* Use flexbox */
  align-items: flex-end; 
  font-size: 14px !important;
  line-height: 17px !important;
}
}
/* Style for label */
.uniqueIdentifierContainer label {
  margin-right: 10px; /* Add some space between label and input */
  color: #0f0e60;
  font-weight: 550;
}

/* Style for input */
.uniqueIdentifierContainer input {
  //flex: 1; /* Allow input to grow and take remaining space */
  padding: 8px; /* Add padding to input */
  font-size: 14px; /* Set font size */
  width: 300px !important;
  border: 1px solid; /* Add border style */
  border-radius: 8px;
  box-sizing: border-box; /* Ensure padding and border are included in input width */
  transition: border-color 0.3s ease; /* Add transition for border color change */
  margin-left: 8px;
  margin-right: 40px !important;
}

/* Style for input when focused */
.uniqueIdentifierContainer input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #0f0e60;; /* Change border color when focused */
}
.editorWrapper {
  height: 45vh;
  width: 93.5%;
  margin-left: 28px;
  margin-top: 20px; /* Optional: Add margin-top for spacing */
  border: 1px solid #0f0e60; /* Add border style */
  border-radius: 8px; /* Optional: Add border radius for rounded corners */
  padding: 10px;
}
.modelInput input{
    width: 60% !important;
    //heigh: 30%;
    margin-top: 16px !important;
    margin-bottom: 160px;
    margin-left: 28px;
    border-radius: 6px !important;
    border: 1px solid; /* Add border style */
    border-radius: 8px;
    box-sizing: border-box; /* Ensure padding and border are included in input width */
    transition: border-color 0.3s ease; 
}
.stepsTitle{
  color: #0f0e60;
}

   
.FixedModel{
    width: 100% !important;
    height: 10% !important;
    background-color: white;
    padding: 10px 10px 10px 10px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    position: sticky !important;
    z-index: 99;
    margin-bottom: 12px;
    border-bottom: 10px solid #EFF2FC;
}


.FixedModel .ant-steps-item-title {
  font-size: 14px !important;
  color: #0f0e60 !important;
  /* Additional styles for step titles */
}

.FixedModel .ant-steps-item-icon {
  font-size: 14px !important;
  
  /* Additional styles for step icons */
}


.FixedModel .ant-btn-primary {
  color: #fff;
  border-color: #3972ce !important;
  background: #3972ce !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  width: 120px;
  height: 33px;
  border-radius: 15px !important;
  margin-right: 40px;
  /* Additional styles for primary buttons */
}
.FixedModel .buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;
}

.FixedModel .ant-btn {
  width: 120px;
  height: 33px;
  border-radius: 15px !important;
  /* Additional styles for all buttons */
}
.FixedModel .ant-btn-primary[disabled]{
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9 !important;
  background: lightgrey !important;
  text-shadow: none;
  box-shadow: none;
}
.FixedModel .custom-steps .ant-steps-item-process .ant-steps-item-icon {
  background: #0f0e60!important;
  border-color: #0f0e60; /* Change this to the color you desire */
}
.FixedModel .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: #0f0e60!important;
}
.FixedModel .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #0f0e60;
  /* border-color: #1890ff; */
}
.custom-steps .anticon svg {
  color:#0f0e60!important;
 }