.chatMainPage {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__leftdiv {
    display: flex;
    justify-content: center;
    width: calc(100% - 234px);
    height: 100% !important;
    flex-direction: column;
    background-color: #EFF2FC;
    padding-top: 7px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }

  &__chatUIDiv {
    display: flex;
    width: 100%;
    height: calc(100% - 88px);
    margin-top: 12px;
    background-color: white;
    border-radius: 8px;
  }

  &__chatUIDivMobile {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
  }

  &__headerDiv{
    display: flex;
    flex-direction: row;
    min-height: 60px !important;
    border-bottom: 1px solid #a7b3d9;
    align-items: center;
    justify-content: space-between;
  }

  &__headerText{
    display: flex;
    color: #0f0f60;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: bold;
  }

  &__searchDotsDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__imgMenu{
    display: flex;
    width: 16px;
    height: 4px;
    cursor: pointer;
  }
  &__imgSearch{
    display: flex;
    width: 14px !important;
    height: 14px !important;
    margin-right: 16px;
    cursor: pointer;
  }

  &__rightdiv {
    display: flex;
    flex-direction: column;
    width: 285px !important;
    height: 100%;
    border-left: 1px solid #EFF2FC;
    float: right;
  }

  &__rightDivCreateChat{
    display: flex;
    height: 83px !important;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid #a7b3d9;
    align-items: center;
    justify-content: flex-start;
  }

  &__chatSettingIcon {
    font-size: 17px;
    cursor: pointer;
    margin-right: 16px;
    color: #0f0f60 !important;
  }

  &__rightNav {
    display: flex;
    width: 100% !important;

    &__rightNavTab {
      display: flex;
      width: 100% !important;
    }

    .ant-tabs-tab-btn {
      color: #0f0f60 !important;
      font-size: 12px !important;
      padding-left: 16px;
      padding-right: 16px;
    }
    .ant-tabs-tab {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
    .ant-tabs-tab-active {
      border-top: 6px solid #0f0f60 !important;
    }
    .ant-tabs-tabpane {
      padding: 0px 0px;
    }
    .ant-tabs,
    .ant-tabs-content,
    .ant-tabs-tabpane {
      height: 100% !important;
      width: 100% !important;
    }
    .ant-tabs-content-holder {
      height: calc(100% - 58px) !important;
    }
  }
  &__addPhoneDiv{
    display: flex;
    flex-direction: column;
    border-bottom: 1.5px solid #eff2fc;
  }

  &__addPhoneInputDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 4px;
  }

  &__btnAddPhone{
    display: flex;
    height: 30px !important;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
  }

  &__addPhoneIcon{
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  
  &__conversationsListHeader {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px !important;
  }
  &__newConversationButton {
    width: 35px !important;
    height: 34px !important;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__conversationCellsList {
    height: 100%;
    max-height: calc(100% - 60px);
    flex-direction: column;
    overflow-y: auto;
  }
  &__conversationCellsListMobile {
    height: 100%;
    width: 100%;
    flex-direction: column;
    overflow-y: scroll;
  }
  &__conversationCell {
    height: 80px;
    border-bottom: 1.5px solid #eff2fc;
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
  }
  &__conversationCellActive {
    background-color: #eff2fc;
    border-left: 4px solid #56a5ff;
  }

  &__nameAndLastMsgDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 20px;
  }
  &__conversationName {
    width: 102%;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 14px !important;
    color: #0F0F60;
  }
  &__conversationNameActive {
    color: #56A5FF !important;
    font-size: 14px !important;
    font-weight: bold !important;
    
    
  }
  &__conversationLastMessage {
    color: #a7b3d9 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    margin-top: 4px;
  }
  &__unreadCountContainer {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 100%;
    background-color: #56a5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    margin-right: 7px;
  }
  &__unreadCount {
    color: #fff;
    font-weight: 500;
    font-size: 10px;
  }

  &__dropdown{
    width: 180px !important;
    height: 120px !important;
    border-radius: 4px !important;
    
  }  
  &__iconCreateChatMobile{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 90px;
    right: 30px;
    cursor: pointer;
  }

  &__mobileContainer{
    display: flex !important;
    flex: 1;
    flex-direction: column !important;
    justify-content: flex-start;
    width: 100% !important;
    min-height: 100% !important;
    max-height: 100%;
  }
  
}
.popOverContainer{
  width: 180px !important;
  p {
    height: 16px !important;
    font-size: 14px !important;
    color: #0f0f60 !important;
    cursor: pointer;
  }
  p:hover {
    font-size: 14px !important;
    color: #56a5ff !important;
    cursor: pointer;
  }
}
