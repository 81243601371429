.mySinglePlaybook {
  background-color: white !important;
  margin: 0px 12px 0px 12px !important;

  &__playbookAndSellerContainer {
    margin-top: 16px;
    display: flex !important;
    height: 100px;

    &__left {
      width: 60% !important;

      &__row1 {
        display: flex !important;
        width: 100% !important;
        &__title {
          font-size: 14px;
          width: 40% !important;
          color: #0f0f60;
          font-weight: bold;
          margin: 0px !important;
        }
        &__h1Tag {
          height: 20px;
          color: #fe943b;
          font-size: 12px;
          border: 2px solid #fe943b;
          margin-left: 40px;
          text-align: center !important;
          border-radius: 15px;
          padding: 2px 24px 20px 24px !important;
          display: inline-block !important;
          background-color: white !important;
        }
        &__h1TagBlue {
          height: 20px;
          color: #0f0f5c;
          font-size: 12px;
          border: 2px solid #0f0f5c;
          margin-left: 40px;
          text-align: center !important;
          border-radius: 15px;
          padding: 2px 24px 20px 24px !important;
          display: inline-block !important;
          background-color: white !important;
        }
        &__h1TagCyan {
          height: 20px;
          color: #6aa3f8;
          font-size: 12px;
          border: 2px solid #6aa3f8;
          margin-left: 40px;
          text-align: center !important;
          border-radius: 15px;
          padding: 2px 24px 20px 24px !important;
          display: inline-block !important;
          background-color: white !important;
        }
        &__h1TagGreen {
          height: 20px;
          color: #98d650;
          font-size: 12px;
          border: 2px solid #98d650;
          margin-left: 40px;
          text-align: center !important;
          border-radius: 15px;
          padding: 2px 24px 20px 24px !important;
          display: inline-block !important;
          background-color: white !important;
        }
      }

      &__shortDescription {
        font-size: 12px;
        width: 100%;
        color: #a7b3d9;
      }

      &__locationAndLink {
        display: flex;
        margin-top: 8px;
        &__image {
          height: 12px;
          margin-top: 2px;
          width: 12px;
        }
        &__text {
          margin-left: 4px;
          font-size: 12px;
          color: #a7b3d9;
        }
      }

      &__replyRate {
        font-size: 12px;
        color: #0f0f60;
      }
    }

    &__right {
      text-align: end;
      width: 40%;
    }
  }

  &__descriptionAndReadMoreContainer {
    margin-top: 8px;
    display: flex !important;
    &__left {
      width: 70% !important;
      &__description {
        font-size: 14px;
        width: 60%;
        margin: 0px !important;
        color: #0f0f60;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__right {
      text-align: end;
      width: 30%;
      &__readMoreBtn {
        color: #84d831 !important;
        background-color: white !important;
        border-radius: 24px !important;
        border: 2px solid #84d831 !important;
        align-items: flex-center;
        font-size: 14px !important;
        margin: 0 auto !important;
        font-weight: bold !important;
        width: 130px !important;
      }
    }
  }

  &__tagsAndActions {
    margin-top: 8px;
    display: flex !important;
    margin-bottom: 16px !important;

    &__left {
      margin-top: 8px;
      font-size: 12px;
      width: 70% !important;

      &__eachPill {
        border: 1px solid #a7b3d9 !important;
        text-align: center !important;
        border-radius: 15px;
        font-size: 10px !important;
        color: #a7b3d9 !important;
        margin-right: 8px;
        padding: 4px 16px 4px 16px !important;
        display: inline-block !important;
        background-color: white !important;
      }
    }

    &__right {
      text-align: end;
      width: 30%;

      &__actions {
        color: #56a5ff !important;
        background-color: white !important;
        border-radius: 24px !important;
        border: 2px solid #56a5ff !important;
        align-items: flex-center;
        font-size: 14px !important;
        margin: 0 auto !important;
        width: 130px !important;
        font-weight: bold !important;
      }
    }
  }

  &__divider {
    height: 1px !important;
    background: #a7b3d9 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}
