.DestinationCatalogList {
    &__headerText {
        margin: 2.1% 0% 2% 3%;
        font-size: 15px;
        font-weight: bold;
    }

    &__title {
        margin-left: 1%;
        margin-right: 1%;
        color: #a7b3d9;
    }

    &__slash {
        color: #a7b3d9;
    }

    &__mainTitle {
        margin-left: 1%;
        color: #0f0f60
    }

    &__sourceText {
        margin-left: 30px;
        margin-bottom: -18px;
        margin-top: 35px;
        padding: 0px;
        color: rgb(71, 77, 102);
        font-size: 1.4rem;
        line-height: 2.75rem;
        font-weight: 600;
        letter-spacing: -0.02em;
    }

    &__bar {
        display: flex;
        justify-content: flex-end;
        margin-right: 4%;
        margin-top: 20px;
    }

    &__input {
        width: 256px !important;
        /* Set the width */
        height: 36px !important;
        /* Set the height */
        margin-right: 1.5% !important;
        /* Add margin as needed */
        border-radius: 22px !important;
        /* Set the border-radius */
        background-color: #f2f2f2 !important;
        /* Set the background color */

        .ant-input {
            border-radius: 16px !important;
            /* Ensure the border-radius is applied to the input field */
            background-color: #f2f2f2 !important;
            /* Ensure the background color matches */
            color: #000000 !important;
            /* Set text color */
        }

        .ant-input-placeholder {
            color: #6b7a7e !important;
            /* Set placeholder text color */
        }
    }

    &__tagLink {
        width: 160px;
        margin-top: 0.5%;
    }

    &__link {
        font-size: 15px;
        border-bottom: 1px solid #1D74D4;
        color: #1D74D4;
    }

    &__newSource {
        margin: -1% 0% 0% 3%;
        font-size: 15px;
        font-weight: bold;
        color: #000000 !important;
    }

    &__newTextSpace {
        margin: 0% 0% 0% 3%;
        font-size: 15px;
        font-weight: bold;
    }

    &__exploreBar {
        display: flex;
        justify-content: space-between;
    }

    &__exploreText {
        margin: 0% 0% 0% 3%;
        font-size: 14px;
        // font-weight: bold;
        color: #6E7A7D;
    }

    &__exploreLink {
        font-size: 13px;
        border-bottom: 1px solid #1D74D4;
        color: #1D74D4;
        margin-right: 4%;
    }

    &__singleFlexBox {
        display: flex;
        width: 226px;
        height: 124px;
        padding: 37px 30px;
        margin: 1%;
        cursor: pointer;
        border: 1px solid #DFDFDF;
        border-radius: 8px;
        opacity: 1;
    }

    &__allFlexBox {
        display: flex;
        flex-wrap: wrap;
        margin: 2% 2% 2% 2%;
    }

    &__image {
        height: 33px !important;
        width: 33px !important;
        //margin: 100% 0% 0% 0%;
    }

    &__listText {
        margin-left: 16px;

        &__text1 {
            font-size: 14px;
            //font-weight: bold;
            // font-weight: 510;
            line-height: 1.3;
            color: #212121 !important;
        }

        &__text2 {
            font-size: 12px;
            color: #A3A5A8;
            line-height: 1.6;
        }
    }
}

.DestinationCatalogList .ant-spin-dot-item {
    background-color: #3872cd !important; /* Set the desired color */
  }
.DestinationCatalogList .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 90px;
  }

.DestinationCatalogList__singleFlexBox:hover {
    cursor: pointer;
    border: 1.5px solid #d4e9ff;
    background-color: #e6f7ff; /* Lighter background color on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transform: scale(1.02); /* Slightly increase size */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  