.desktop-select-source{
  width: 100% !important;
  background-color: #eff2fc !important;
}
.desktop-select-source .custom-radio .ant-radio-inner {
  margin-left: 16px;
  border-color: #56a5ff;
}
.desktop-select-source .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.desktop-select-source .ant-spin-dot-item {
  background-color: #3872cd !important; /* Set the desired color */
}
.custom-radio .ant-radio-inner::after {
}
