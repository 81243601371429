.ApiConnectorDetails {

    &__headerText {
        margin: 2% 0% 2% 3%;
        font-size: 15px;
        font-weight: bold;
    }

    &__title {
        margin-left: 1%;
        margin-right: 1%;
        color: #a7b3d9;
    }

    &__slash {
        color: #a7b3d9;
    }

    &__mainTitle {
        margin-left: 1%;
        color: #0f0f60
    }

    &__wrapper {
        margin: 30px 3% !important;
        width: 878px;
        display: flex !important;
        flex-wrap: wrap !important;
    }

    &__input {
        width: 50% !important;
        padding: 4px 10px 4px 10px !important;
        border-radius: 5px !important;
    }

    &__input2 {
        width: 100% !important;
        padding: 4px 10px 4px 10px !important;
        border-radius: 5px !important;
        pointer-events: none;
    }

    &__heading {
        text-align: start;
        font-size: 14px;
        margin-bottom: 6px;
        margin-top: 0px;
        font-weight: bold;
    }

    &__form {
        margin-left: 30px;
        margin-top: 20px;
        margin-right: 160px;
    }

    &__div {
        margin: 0px 20px 0px 0px;
    }

    &__para {
        margin-top: 0px;
        font-size: 12px;
        text-align: start;
        color: gray;
    }

    &__error {
        margin-top: 0px;
        color: red;
    }

    &__mainHeading {
        margin-left: 30px;
        padding: 0px;
        font-size: 1.4rem;
        line-height: 2.75rem;
        letter-spacing: -0.02em;
        text-align: start;
        margin-bottom: 0px;
        margin-top: 8px !important;
        font-weight: bold;
    }
     

    &__mainHeading2 {
        font-size: 15px;
        margin: 2% 0px 2% 3%;
        font-weight: bold;
    }

    &__component {
        width: 100% !important;
        border-bottom: 10px solid #EFF2FC;
        border-top: 10px solid #EFF2FC;
    }

    &__buttonsDiv {
        display: flex;
        margin-top: 30px;
        padding-bottom: 4%;
        margin-left: 30px;

    }

    &__revokeButton {
        display: inline-flex;
        flex-direction: row;
        align-items: center !important;
        height: 33px !important;
        width: 120px;
        border-radius: 6px !important;
        margin-left: 10px;
        background-color: #E3E3E3 !important;
        border: 0px !important;
        color: #6B7A7E !important;
        font-size: 14px !important;
        margin-bottom: 8px;
        font-weight: bold !important;
        line-height: 17px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    }

    &__copyButton {
        display: inline-flex;
        flex-direction: row;
        align-items: center !important;
        height: 33px !important;
        width: 120px;
        border-radius: 6px !important;
        background-color: #1c74d4 !important;
        color: white;
        border: 0px !important;
        font-size: 14px !important;
        margin-left: 16px;
        margin-bottom: 8px;
        font-weight: bold !important;
        line-height: 17px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    }

    &__button {
        display: inline-flex;
        flex-direction: row;
        align-items: center !important;
        height: 35px !important;
        width: 145px;
        font-weight: bold !important;
        border-radius: 6px !important;
        background-color: #1c74d4 !important;
        color: white;
        border: 0px !important;
        font-size: 14px !important;
        line-height: 17px !important;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
        box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    }


}

.fixedBar {
    margin: 0% 0px 1% 3%;
}

.Fixed {
    width: 100% !important;
    background-color: white;
    padding: 12px 0px 0px 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    position: sticky !important;
    top: 0;
    z-index: 99;
    margin-bottom: 12px;
    border-bottom: 10px solid #EFF2FC;

    &__title {
        margin-right: 1%;
        color: #A7B3D9;
    }

    &__slash {
        color: #A7B3D9;
    }

    &__mainTitle {
        margin-left: 1%;
    }


}

.ApiConnectorDetails__revokeButton .anticon {
    margin-right: 5px !important;
}

.ApiConnectorDetails__copyButton .anticon {
    margin-right: 5px !important;
}

.Title {
    font-weight: bold;
    font-size: 15px;
}