.signinPage {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 0px;
  min-height: calc(100% - 180px);
  

  &__containerSpinner {
    height: 100%;
    width: 100%;
  }

  &__spinnerBox{
    height: 100%;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  &__container {
    height: 100%;
  }

  &__signinFields {
    width: 273px;
  }

  &--withoutShadow {
    box-shadow: unset;
    padding: unset;
  }

  &__headerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-right: 14px;
  }

  &__logo {
    display: flex;
    width: 80px;
    height: 80px;
  }

  &__headerTitle {
    display: inline-block;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: bold !important;
    text-align: center;
    justify-content: center;
    color: #000000 !important;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  &__footerText {
    font-weight: bold;
    color: white;
    background: white;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    font-weight: normal;
    justify-content: flex-end;
    width: 100vw;
    background-color: white !important;
    margin-top: 63px;
  }
  &__namesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: 8px;
  }
  &__footer {
    display: flex;
    align-items: center;
    font-weight: normal;
    justify-content: center;
    width: 100vw;
  }
  &__inputField {
    min-width: 273px;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    border: 1px solid #E4E4E4; /* Add border with primary color */
    border-radius: 8px !important; /* Adjust as needed for rounded corners */
    padding: 4px; /* Space between the border and input */

    input {
        font-size: 14px !important;
        line-height: 13px !important;
        font-weight: 500 !important;
        color:#000000 !important;
        border: none; /* Remove default input border */
        outline: none; /* Remove outline for focus state */
        width: 100%; /* Ensure input fills the container */
        background-color: transparent; /* Match the input background */
    }
}
  &__inputFieldWithMargin {
    min-width: 273px;
    height: 32px;
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 12px;
  }
  &__googleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 32px;
  }

  &__googleButtonDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 32px;
    border-radius: 16px !important;
    background-color: #4771C8 !important;
  }

  &__googleIconBg{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 1px;
    margin-left: 1px;
    border-radius: 15px !important;
    background-color: white !important;
  }

  &__continueWithGoogleDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-left: -26px;
  }

  &__textContinueWithGoogle{
    display: flex;
    font-size: 14px;
    font-weight: bold !important;
    line-height: 17px;
    font-weight: 500;
    color: white !important;
  }

  &__continueButton {
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px !important;
    border-radius: 24px !important;
    border: 1px solid #E4E4E4 !important;
    background-color: #F8FBFF !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    margin-top: 16px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__button {
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    min-width: 128px;
    margin-right: 43px;
    height: 32px !important;
    border-radius: 24px !important;
    background-color: #4771C8 !important;
    color: white;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
    border-color: #4771C8 !important;
  }
  &__footer {
    font-weight: normal;
  }
  &__linkButton {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    float: right;
    color: #4771C8 !important;
    padding: 0px !important;
    border: 0px !important;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    height: 13px !important;
    margin-top: -4px !important;
  }

  &__textDonthaveAccount {
    color: #8B8B8B !important;
    font-size: 14px !important;
    line-height: 15px !important;
    margin-right: 8px;
  }
  &__divider{
    margin-top: 16px;
    font-size: 12px !important;
    line-height: 12px !important;
    font-weight: 500 !important;
    color: #0F0F60 !important;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 8px;
  }

  .ant-btn-primary {
    border: 0px;
  }

  &__errorMessageContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-top: 4px;
    margin-bottom: 16px;
    background-color: #FDECEB;
  }

  &__errorContainerCrossImage{
    width: 20px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
  }

  &__textErrorMessage{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    padding-left: 6px;
  }
  &__authHeaderTitle {
    display: flex;
    font-size: 14px !important;
    font-weight: normal !important;
    text-align: center;
    justify-content: center;
    color: #8B8B8B !important;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 14px;
  }

}
.custom-label {
  font-weight: bold;
  color: #000000; /* Optional: Makes label text uppercase */
}
