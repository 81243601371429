.appRightNavMobile {
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff !important;

  .nav-menu {
    width: 300px;
  }

  &__dividerBorder {
    background: #a7b3d9;
    margin: 0 !important;
    height: 1px;
  }

  &__marketplace {
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-header {
  padding: 0px !important;
  height: 0px;
  border-bottom: 10px #a7b3d9 solid;
}

.ant-drawer-header-close-only {
  margin-top: -6px;
}