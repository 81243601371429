.saveButton {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  background-color: #3872cd !important;
  width: 105px !important;
  color: #f0f5fb !important;
  font-size: 11pt !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  margin-top: 6px !important;
  float: right !important;
  border: none !important;
  height: 32px !important;
}
.disabled{
  background-color: #7A9BF4 !important;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  width: 105px !important;
  color: #f0f5fb !important;
  font-size: 11pt !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  margin-top: 6px !important;
  float: right !important;
  border: none !important;
  height: 32px !important;
}
.desktop-select-destination{
  width: 100% !important;
  background-color: #eff2fc !important;
}
.desktop-select-destination .custom-radio .ant-radio-inner {
  margin-left: 16px;
  border-color: #56a5ff;
}
.desktop-select-destination .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.desktop-select-destination .ant-spin-dot-item {
  background-color: #3872cd !important; /* Set the desired color */
}
.custom-radio .ant-radio-inner::after {
}
.custom-checkbox .ant-checkbox-inner {
  border-radius: 50%; /* Make the checkbox rounded */
  width: 17px; /* Adjust width */
  height: 17px; /* Adjust height */
  border-color: #5A8AE1; /* Border color */
}

.custom-checkbox .ant-checkbox-input:checked + .ant-checkbox-inner {
  background-color: #5A8AE1; /* Background color when checked */
  border-color: #5A8AE1; /* Border color when checked */
}

/* Style for the Ant Design Select component */
.custom-select .ant-select-selector {
  border-radius: 8px !important; /* Make the select box rounded */
  border: 1px solid #5A8AE1; /* Border color of the select box */
  padding: 8px 12px; /* Adjust padding for better alignment */
  background-color: #ffffff; /* White background */
  font-size: 14px; /* Font size */
  color: #000000 !important;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.custom-select .ant-select-selector:hover,
.custom-select .ant-select-selector:focus {
  border-color: #5A8AE1; /* Border color on hover/focus */
  box-shadow: 0 0 0 2px rgba(90, 138, 225, 0.2); /* Subtle shadow */
}

.custom-select .ant-select-dropdown {
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #d9d9d9; /* Light border */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  background-color: #ffffff; /* White background */
}

.custom-select .ant-select-item-option-content {
  font-size: 14px; /* Font size */
  color: #000000 !important;
}

.custom-select .ant-select-item-option-selected {
  background-color: #5A8AE1 !important; /* Background color for selected item */
  color: #ffffff !important; /* Font color for selected item */
}

.custom-select .ant-select-item-option:hover {
  background-color: #e6f7ff; /* Light background on hover */
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;

}
.create-new-extension {
  font-weight: bold;
  font-size: 14px;
  margin: 8px 0; /* Margin around the text */
  padding: 8px; /* Padding */
  cursor: pointer;
  border-top: 1px solid #d9d9d9; /* Border on top */
  background-color: #e6f7ff; /* Light background for the button */
  color: #000000 !important;
  border-radius: 4px; /* Rounded corners */
  text-align: center; /* Center text */
}

.custom-modal .ant-modal {
  border-radius: 18px !important; /* Rounded corners for the modal */
}

.custom-modal .ant-modal-header {
  border-radius: 8px 8px 0 0; /* Rounded corners only on top */
}


.custom-modal .ant-modal-footer {
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
}

/* Style for the Ant Design Input */
.custom-modal .ant-input {
  border-radius: 4px; /* Rounded corners for the input */
  border: 1px solid #d9d9d9; /* Light border */
  padding: 8px; /* Padding inside the input */
}

/* Style for the Ant Design Button */
.custom-modal .ant-btn-primary {
  border-radius: 14px; /* Rounded corners for the button */
  background-color: #3872cd !important;
  color: #ffffff; /* Text color */
  font-weight: bold; /* Bold text */
  // padding: 8px 16px; /* Padding inside the button */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
}

.custom-modal .ant-btn-default {
  border-radius: 14px; /* Rounded corners for the button */

  font-weight: bold; /* Bold text */
  // padding: 8px 16px; /* Padding inside the button */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
}
.redirectBtn{
  margin: 16px 0 0 0 !important;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  background-color: #3872cd !important;
  width: 135px !important;
  color: #f0f5fb !important;
  font-size: 11pt !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  margin-top: 12px !important;
  // float: right !important;
  border: none !important;
  height: 32px !important;
}


