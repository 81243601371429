.settingsRightNav {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  &__rightNavParentContainerMobile{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    height: 100% !important;
  }

  &__rightNavParentContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 234px !important;
    height: 100% !important;
  }

  &__rightNavContainer{
    display: flex;
    flex-direction: column;
  }

  &__headerIconDiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 60px !important;
  }
  &__imgLogo {
    width: 29px !important;
    height: 29px !important;
    margin-right: 19px;
    margin-top: 15px;
  }

  &__listParentDiv{
    display: flex;
    width: 234px !important;
    height: 100%;
  }

  &__listParentDivMobile{
    display: flex;
    width: 100% !important;
    height: 100%;
  }

  &__listItem {
    display: flex;
    height: 80px;
    width: 100% !important;
    border-bottom: 1.5px solid #eff2fc !important;
    cursor: pointer;
  }

  &__listItemMobile {
    display: flex;
    align-items: center;
    height: 90px !important;
    width: 100% !important;
    border-bottom: 1.5px solid #eff2fc !important;
    cursor: pointer;
  }

  &__listItemActive {
    display: flex;
    background-color: #EFF2FC !important;
    border-left: 3px solid #56A5FF;
    width: 100% !important;
    
  }

  &__listItemIcon {
    margin-left: 14px;
    margin-top: 12px;
    height: 15px !important;
    width: 15px !important;
  }

  &__listItemIconMobile {
    margin-left: 40px;
    margin-top: 12px;
    height: 19px !important;
    width: 19px !important;
  }

  &__headerTitle {
    display: flex;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: bold !important;
    margin-left: 20px;
    margin-top: 10px;
    width: 182px;
    text-align: start;
    justify-content: flex-start;
    color: #0f0f60 !important;
  }

  &__headerTitleMobile {
    display: flex;
    font-size: 16px !important;
    line-height: 17px !important;
    font-weight: bold !important;
    margin-left: 20px;
    margin-top: 10px;
    width: 182px;
    text-align: start;
    justify-content: flex-start;
    color: #0f0f60 !important;
  }

  &__headerTitleActive {
    display: flex;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: bold !important;
    margin-left: 20px;
    margin-top: 10px;
    width: 182px;
    text-align: start;
    justify-content: flex-start;
    color: #56A5FF !important;
  }
  &__description {
    display: flex;
    font-size: 14px !important;
    line-height: 17px !important;
    margin-left: 21px;
    width: 182px;
    text-align: start;
    justify-content: flex-start;
    color: #a7b3d9 !important;
  }

  &__descriptionMobile {
    display: flex;
    font-size: 14px !important;
    margin-left: 18px;
    width: 250px;
    text-align: start;
    justify-content: flex-start;
    color: #a7b3d9 !important;
  }

  &__conversationCell {
    height: 61px;
    border-bottom: 1.5px solid #eff2fc;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
  }

  &__conversationCellActive {
    background-color: #eff2fc;
    border-left: 3px solid #56a5ff;
  }
  &__divider{
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  &__switchRoleDiv{
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: center;
    align-items: center;
  }

  &__buyerText{
    font-size: 12px !important;
    font-weight: bold !important;
    margin-right: 12px;
    color: #84D830 !important;
  }
  &__buyerTextEnabled{
    font-size: 12px !important;
    font-weight: bold !important;
    margin-right: 12px;
    color: #A7B3D9 !important;
  }

  &__sellerText{
    font-size: 12px !important;
    font-weight: bold !important;
    margin-left: 12px;
    color: #A7B3D9 !important;
  }
  &__sellerTextEnabled{
    font-size: 12px !important;
    font-weight: bold !important;
    margin-left: 12px;
    color: #56A5FF !important;
  }
}
