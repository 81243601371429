.dayContainer{
    display: flex;
    width: 100%;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;

  &__dayText {
    padding: 8px;
    color: #5A6365;
    border-radius: 9px;
    border: 1px solid #A9B3D6;
    font-size: 10px !important;
    font-weight: bold;
  }
}