.ConnectFacebookAds {
  &__wrapper {
    margin-left: 35px !important;
    //width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  &__input {
    width: 450px !important;
    border-radius: 2px !important;
    padding: 4px 10px 4px 10px !important;
    border: 1px solid #d9d9d9;
  }

  &__heading {
    text-align: start;
    font-size: 14px;
    margin-bottom: 6px;
    margin-top: 1px;
    font-weight: bold;
  }

  &__iconsDiv {
    display: flex !important;
    margin-top: 20px !important;
    justify-content: center !important;
    align-items: center !important;
  }

  &__icons {
    display: block;
    width: 200px;
  }

  &__img1 {
    height: 65px;
    width: 65px;
  }

  &__img2 {
    float: right;
    height: 65px;
    width: 65px;
  }

  &__div {
    margin: 30px 0 0 35px;
  }

  &__para {
    margin-top: 0px;
    font-size: 12px;
    text-align: start;
    color: gray;
    width: 350px;
  }

  &__mainHeading {
    margin-left: 35px;
    margin-bottom: 30px !important;
    margin-top: 25px;
    padding: 0px;
    color: rgb(71, 77, 102);
    font-size: 1.4rem;
    line-height: 2.75rem;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  &__component {
    width: 100% !important;
    // border-bottom: 10px solid #EFF2FC;
    border-top: 10px solid #eff2fc;
  }

  &__buttonsDiv {
    margin: 2px 35px 35px 0;
    //padding-bottom: 4%;
  }

  &__button {
    height: 35px !important;
    width: 115px !important;
    background-color: #1c74d4 !important;
    color: white !important;
    border: 0px !important;
    border-radius: 5px;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
}

.fixedBar {
  margin: 10px 0% 1% 3% !important;
}

.fixedBar span {
  margin-right: 15px;
  /* Adjust the value as needed */
}

.Fixed {
  width: 100% !important;
  height: 12% !important;
  background-color: white;
  padding: 12px 0px 0px 0px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  position: sticky !important;
  //top: % !important;
  z-index: 99;
  margin-bottom: 12px;
  border-bottom: 10px solid #eff2fc;
}

.Title {
  font-weight: bold;
  font-size: 15px;
  margin-left: -4px;
  color: #0f0f60;
}

/* In your CSS or SCSS file */
.custom-spin .ant-spin-dot {
  color: red;
  /* Adjust the color as needed */
}
