.packageDetails {
  height: 100%;
  padding: 0px 0px;
  border-radius: 10px;

  &__evenRow {
    background-color: #eff2fc;

    .ant-table-cell-row-hover {
      background-color: #eff2fc !important;
    }
  }
  &__oddRow {
    background-color: #fff;
    .ant-table-cell-row-hover {
      background-color: #fff !important;
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fff !important;
      padding: 0px;
      height: 30px !important;
      color: #0f0f60;
      font-size: 12px;
      font-weight: bold;
    }
    .ant-table-cell::before {
      background-color: unset !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 10px;
      color: #0f0f60;
      font-size: 11px;
    }
  }

  .ant-btn {
    border-radius: 15px !important;
  }
  // .ant-table-cell-row-hover {
  //   color: #56a5ff !important;
  //   cursor: pointer;
  // }
}
