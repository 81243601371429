.headerBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 84px;
  max-height: 84px;
  background: #eff1fb;

  &__backArrowMobileNew {
    width: 24px;
    height: 24px;
    padding: 7px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  &__headerElementsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 32px;
    align-items: center;
    margin-left: 36px;
    margin-right: 36px;
    cursor: pointer;
  }

  &__headerNameText {
    display: flex;
    height: 32px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #0f0f60 !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  &__appSettingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-size: 30px;
    cursor: pointer;
    color: #a7b3d9 !important;
  }
}
