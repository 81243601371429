.custom-sync-recurrence {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-sizing: border-box;
  padding: 20px;
  &__heading {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #8b8b8b;
    margin-top: 23px;
    margin-left: 27px;
  }
  &__separator {
    width: 100%;
    height: 1px;
    background-color: #d0d1d3;
    margin-top: 16px;
  }
  &__radio-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .custom-sync-recurrence__radio-text {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-weight: bold;
        margin-left: 8px;
        margin-right: 8px;
      }
      .custom-sync-recurrence__numeric-field,
      .custom-sync-recurrence__time-field,
      .custom-sync-recurrence__dropdown,
      .custom-sync-recurrence__time-dropdown,
      .custom-sync-recurrence__date-field,
      .custom-sync-recurrence__occurrences-field {
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        background-color: #fafafa;
        height: 41px;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        padding: 0 10px;
        margin-right: 8px;
        margin-top: 10px;
      }
      .custom-sync-recurrence__numeric-field {
        width: 92px;
      }
      .custom-sync-recurrence__dropdown {
        width: 146px;
      }
      .custom-sync-recurrence__time-field {
        width: 72px;
      }
      .custom-sync-recurrence__time-dropdown {
        width: 100px;
      }
      .custom-sync-recurrence__date-field {
        width: 186px;
        margin-left: 116px;
      }
      .custom-sync-recurrence__occurrences-field {
        width: 186px;
        margin-left: 102px;
      }
    }
  }
  &__sub-heading {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #07074b;
    margin-top: 70px;
    margin-bottom: 10px;
  }
  &__days-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
  }
  &__day-box {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
    background-color: #fafafa;
    border: 1px solid #e4e4e4;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    &--selected {
      background-color: #1d74d4;
      color: #ffffff;
    }
  }
  &__occurrences-text {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #212121;
  }
  &__button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    margin-top: 15px;
  }
  &__button {
    width: 145px !important;
    height: 36px !important;
    border-radius: 18px !important;
    font-family: "Lato", sans-serif !important;
    font-size: 14px !important;
    font-weight: bold !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    border: #d9d9d9 !important;
    &--cancel-button {
      background-color: #d9d9d9 !important;
      color: #717171 !important;
    }
    &--done-button {
      background-color: #3872cd !important;
      color: #ffffff !important;
    }
  }
}
