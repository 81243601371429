.marketPlace {
  display: none;
.customAvatar__avatar{
  box-shadow: 0px 3px 3px #00000029;
}
  &__workspaceIcon {
    padding: 2px !important;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 50%;
  }

  &__workspaceIconSelected {
    padding: 2px !important;
    cursor: pointer;
    border: 2px solid #3972CD;
    opacity: 1;
    border-radius: 50%;
  }
  &__avatar {
    height: 30px !important;
    width: 30px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    background-color: rgb(149, 113, 113) !important;
    color: rgb(8, 6, 6) !important;
  }

  &__listOfWorkplaces {
    margin-top: 12px !important;
    max-height: calc(100vh - 84px);
    overflow-y: scroll;
  }

  &__marketPlaceRightNavContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__marketplaceNameIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  &__marketplaceNameText {
    width: 180px;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #07074b !important;
    padding-left: 0px;
    padding-right: 2px;
  }

  &__rightNavHeader {
    background: #eff1fb;
    display: flex;
    flex-direction: row;
    width: 300px;
    align-items: center;
    min-height: 89px;
    max-height: 89px;
  }

  &__dividerBorder {
    background: #eff1fb;
    margin: 0 !important;
    height: 1px;
  }

  &__appCloseLeftNavIcon {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 32px;
    color: #07074b !important;
  }

  &__marketplaceText {
    width: 300px;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    color: #07074b !important;
    padding-left: 0px;
    padding-right: 2px;
  }

  &__rightNavFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    min-height: 84px;
    max-height: 84px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__addMarketplaceText {
    width: 180px;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    color: #07074b !important;
    padding-left: 0px;
    padding-right: 2px;
  }
  
}