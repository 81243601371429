.dataTable {
  height: 100%;
  padding: 0px 16px;

  &__content {
    margin: 10px !important;

    .ant-table-thead > tr > th {
      background-color: #f5f5f5;
      font-weight: bold;
      border-bottom: none;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-word;

      &:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }
      &:last-child {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }

    .ant-table-tbody > tr > td {
      padding: 16px;
      font-size: 14px;
      background-color: #fafafa;
    }
  }

  .ant-table-tbody-wrapper {
    margin-top: 10px; // Create space between the header and the body
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 10px;
      color: #000000;
      font-size: 12px;
      border-bottom: none;
      background-color: #d0f0c0; // Light green for table content
    }
  }
}
