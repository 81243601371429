.filterFieldsContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 21px;
  border-bottom: 1px solid #cecece;

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &__label {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      padding-bottom: 0px;
    }
    &__clear {
      color: #56a5ff !important;
      font-size: 12px !important;
      font-weight: bold !important;
      cursor: pointer;
    }
  }
  &__fieldsContainer{
    border-radius: 8px !important;
    .filterDropdown{
      border-radius: 8px !important;
    }
  }
}
