.editMarketplace {
  height: 96%;
  overflow-y: scroll;
  //background-color: #eff2fc !important;

  &__headingAndImageContainer {
    display: flex;
    flex-direction: row;

    &__headingContainer {
      width: 70%;
    }

    &__imageContainer {
      width: 30%;
      &__image {
        float: right;
        &__headerImgDiv {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 84px;
          height: 84px;
        }
        &__headerImg {
          float: right !important;
          height: 50px !important;
          width: 50px !important;
          cursor: pointer;
        }
        &__cameraIcon {
          width: 34px !important;
          height: 34px !important;
          margin-left: -12px;
          margin-bottom: -32px;
          cursor: pointer;
        }
      }
    }
  }
  input {
    border-radius: 4px !important;
    color: #0f0f60 !important;
    font-size: 14px !important;
    height: 28px !important;
  }

  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
    width: 100% !important;
  }

  .ant-input-affix-wrapper {
    height: 34px !important;
    max-height: 34px !important;
    border-radius: 4px !important;
    color: #0f0f60 !important;
    border-color: #a7b3d9;
    font-size: 14px !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: 24px !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    border-color: #a7b3d9 !important;
    color: white !important;
    font-size: 14px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #56a5ff !important;
  }

  .ant-select-selection-item {
    background-color: #56a5ff !important;
    color: white !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    min-width: 120px;
    text-align: left;
    label {
      font-size: 12px;
      color: #0f0f60 !important;
    }
  }
  .ant-input {
    height: 34px !important;
    border: 1px solid #a7b3d9;
    border-radius: 4px;
    max-width: 100%;
  }

  &__mainContainer {
    background-color: #eff2fc;
  }

  &__addGeneralDetails {
    background-color: white !important;
    padding: 16px;
    border-radius: 8px;
    &__heading {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
    }

    &__subHeading {
      color: #a7b3d9;
      font-size: 12px;
    }
    &__divider {
      height: 1px !important;
      background: #eff2fc !important;
      margin-top: 12px !important;
    }

    &__titleRoleContainer {
      display: flex !important;
      &__role {
        width: 20%;
        margin-left: 20px !important;
      }

      &__title {
        width: 80% !important;
      }
    }

    &__tagsContainer {
      display: flex !important;
      &__primary {
        width: 20%;
      }
      &__colorSelection {
        margin-left: 20px !important;
        width: 20%;
        &__row {
          display: flex;
          flex-direction: row !important;
          align-items: center !important;
          justify-content: space-between;
          &__text {
            color: #0f0f60;
            font-size: 14px;
            cursor: none;
          }
          &__color {
            width: 12px;
            align-items: center;
            height: 12px;
          }
        }
      }

      &__custom {
        margin-left: 20px !important;
        width: 60% !important;
        font-size: 14px !important;
        font-weight: bold !important;
      }
    }

    &__categoryLanguageContainer {
      display: flex !important;
      flex-direction: space-between !important;

      &__category {
        width: calc(100% - 10px) !important;
        margin-right: 10px !important;
      }
      &__location {
        width: calc(100% - 10px) !important;
        margin-right: 10px !important;
      }

      &__language {
        width: 100% !important;
      }
    }
  }

  &__addDescriptionContainer {
    background-color: white !important;
    margin-top: 16px !important;
    padding: 16px;
    border-radius: 8px;
    font-weight: bold !important;
  }
  &__button{
    display: flex;
    float: right;
  &__saveSourceButton {
    width: 120px;
    border: none !important;
    height: 32px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin-top: 24px;
    margin-right: 15px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    //float: right;
    margin-right: 30px;
    color: white !important;
    background-color: #84d830 !important;
  }
  &__discardSourceButton {
    width: 120px;
    border: none !important;
    height: 32px !important;
    text-align: center !important;
    border-radius: 15px !important;
    border: 1px solid #84d830 !important;
    margin-top: 24px;
    margin-right: 20px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    //float: right;
    color: #84d830 !important;
    background-color: white !important;
  }
  }
}

.primaryTag {
  .ant-select-selection-item {
    background-color: white !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }

  .ant-select-selector {
    border-radius: 4px !important;
    border-color: #a7b3d9 !important;
    font-size: 14px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #0f0f60 !important;
  }
}

.dropdown {
  .ant-select-selector {
    border-color: #a7b3d9 !important;
    color: #0f0f60 !important;
    font-size: 12px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #a7b3d9 !important;
  }
  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
    width: 117px !important;
  }
  .ant-select-selection-item {
    background-color: #56a5ff !important;
    color: white !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }
}

.categoryDropdown {
  margin-top: 10px !important;
  .ant-select-selector {
    border-color: #a7b3d9 !important;
    color: #0f0f60 !important;
    font-size: 14px !important;
    height: 34px !important;
  }
  .ant-select-arrow {
    color: #0f0f60 !important;
  }
  label {
    color: #0f0f60 !important;
    font-size: 12px !important;
  }
  .ant-select-selection-item {
    background-color: white !important;
    color: #0f0f60 !important;
  }
  .ant-select-selection-item-remove {
    color: white !important;
  }
}

:root {
  --dynamicColor: black;
}
$dynamicColor: var(--dynamicColor);

.primaryInput {
  border-radius: 4px !important;
  font-size: 14px !important;
  height: 28px !important;

  .ant-input {
    color: #0f0f60 !important;
  }
}
