.identity-fields-container {
  padding: 20px;
  background-color: #f9f9f9;

  .warning {
    color: #d9534f;
    p {
      font-size: 16px;
      font-weight: bold;
    }
    span {
      display: block;
      margin-bottom: 20px;
    }
  }

  .identity-table {
    margin-top: 20px;

    .ant-table-thead > tr > th {
      background-color: #f5f5f5;
      font-weight: bold;
      text-align: left;
    }

    .ant-table-tbody > tr > td {
      padding: 16px;
    }

    .ant-input {
      background-color: #f5f5f5;
      border: none;
      color: #999;
    }

    .ant-select {
      width: 100%;
    }
  }

  .update-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
