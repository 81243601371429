.DestinationOverview {
  .th.ant-table-cell {
      font-weight: bold !important;
      border-color: gray !important; 
      //background-color: #eff1fb !important;
  }
  th.ant-table-cell {
      background:#eff1fb !important;
      font-weight: bold !important;
  }
  &__headerText {
    margin: 2% 0% 0% 3%;
    font-size: 15px;
    font-weight: bold;
  }
  &__title {
    margin-top: 4px;
    margin-left: 1%;
    color: #a7b3d9;
  }
  &__titleSelected {
    margin-top: 4px;
    color: #001f3f; /* Change to the desired color */
    margin-left: 1%;
}
  &__sourceText {
      margin: 3% 0% 2% 3%;
      font-size: 15px;
      font-weight: bold;
  }
  &__bar {
      display: flex;
      justify-content: flex-end;
      margin-right: 4%;
  }
  &__input {
      width: 230px !important;
      border-radius: 5px !important;
      margin-right: 1% !important;
  }
  &__parentButton {
    display: flex;
    justify-content: flex-end;
  }
  &__button {
    margin-bottom: 8px;
    justify-content: center;
    align-items: center !important;
    height: 35px !important;
    border-radius: 6px !important;
    background-color: #3972ce !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__tableDiv{
      margin: 0% 4% 0% 3%;
  }
  &__btnImage{
      margin-left: -28%;
  }

  &__details-button {
      cursor: pointer;
  }
  &__details-button,
  &__details-button:active,
  &__details-button:hover,
  &__details-button:focus {
    border: none !important;
    height: 30px !important;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    float: left !important;
    margin-right: 0px !important;
    color: white !important;
    background-color: #98d650 !important;
  }
}
.statusFlex{
  display: flex;
}
.Status{
  width: 11px !important;
  height: 11px !important;
  background-color: #84D830;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%; 
}
.InActiveStatus{
  width: 11px !important;
  height: 11px !important;
  background-color: #cccccc!important;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%; 
}
.nameFlex{
  display: flex;
  justify-content: space-between;
}
.nameText{
  width: 75%;
}
.hover-pointer:hover {
  cursor: pointer;
}
.DestinationOverview__headerText .ant-tabs-top > .ant-tabs-nav,
.DestinationOverview__headerText .ant-tabs-bottom > .ant-tabs-nav,
.DestinationOverview__headerText .ant-tabs-top > div > .ant-tabs-nav,
.DestinationOverview__headerText .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px -18px;
}