.header {
  display: flex;
  align-items: center;
  margin: 0px 8px 0px 8px !important;
  background-color: white;

  &__text {
    display: flex;
    align-items: center;
    color: #0f0f60;
    margin-left: 16px !important;
    font-size: 14px;
    background-color: white;
    font-weight: bold !important;
    min-height: 60px !important;
    max-height: 60px !important;
    margin-bottom: 0px;
  }
}
