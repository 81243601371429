.desktopSaveDatasetDetails {
  width: 100% !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 0px !important;
  color: #394399 !important;
  margin-top: 16px !important;
  margin-left: 8px !important;
  cursor: pointer !important;
  margin-right: 11px !important;
  margin-top: 11px !important;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }

  &-image {
    width: 20px;
    height: 20px;
  }

  &-mainHeading {
    flex: 1;
    // font-family: Lato;
    color: #000000 !important;
    font-weight: bold;
    font-size: 14px !important;
    text-align: left;
    margin: 16px 0px 0px 8px;
  }

  &-textInput {
    border-radius: 8px !important;
    width: 100% !important;
    height: 40px !important;
    padding: 8px !important;
    margin-top: 8px !important;
    font-family: "Lato" !important;
    padding: 10px !important;
    font-size: 14px !important;
    color: #000000 !important;
    background-color: #eff4fa !important;
    border: 1px #e4e4e4 solid;
    resize: none; // prevent resizing

    &::placeholder {
      color: #717171 !important;
    }
  }

  &-descriptionInput {
    border-radius: 8px !important;
    width: 100% !important;
    height: 200px !important;
    padding: 8px !important;
    margin-top: 8px !important;
    font-family: "Lato" !important;
    padding: 10px !important;
    font-size: 14px !important;
    color: #000000 !important;
    background-color: #eff4fa !important;
    border: 1px #e4e4e4 solid;
    resize: none;

    &::placeholder {
      color: #717171 !important;
    }
  }

  &__button {
    margin: 16px 0 0 0 !important;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    background-color: #3872cd !important;
    width: 105px !important;
    color: #f0f5fb !important;
    font-size: 11pt !important;
    font-family: "Lato", sans-serif !important;
    font-weight: bold !important;
    border-radius: 15px !important;
    margin-top: 12px !important;
    float: right !important;
    border: none !important;
    height: 32px !important;
  }
  &__button:disabled {
    margin: 16px 0 0 0 !important;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    background-color: #7A9BF4 !important;
    width: 105px !important;
    color: #f0f5fb !important;
    font-size: 11pt !important;
    font-family: "Lato", sans-serif !important;
    font-weight: bold !important;
    border-radius: 15px !important;
    margin-top: 12px !important;
    float: right !important;
    border: none !important;
    height: 32px !important;
  }
}

.ant-input {
  color: #000000 !important;
  vertical-align: top !important;
}

.ant-card.ant-card-bordered.desktopSaveDatasetDetails {
  width: 97.5% !important;
}