.companyPage {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;

  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  &__headerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    min-height: 60px !important;
    padding-left: 24px;
    padding-right: 16px;
    border-bottom: 1px solid #A7B3D9 !important;
  }
  &__headerCredentialsInfoDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 80px !important;
    padding-left: 24px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    min-height: 206px !important;
    padding-bottom: 20px;
  }
  &__headerMiddle {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    margin-top: 10px;
    background-color: white;
    min-height: 206px !important;
    padding-bottom: 20px;
  }
  &__headerCMMInfoDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 80px !important;
    padding-left: 24px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 8px !important;
    background-color: white;
    min-height: 410px !important;
    padding-bottom: 20px;
  }
  &__loremDiv{
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 110px !important;
    border-bottom: 1px solid #A7B3D9 !important;
    padding-left: 24px;
  }
  &__managementDiv{
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 130px !important;
    border-bottom: 1px solid #A7B3D9 !important;
    padding-left: 24px;
  }
  &__ctoParentDiv {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    padding-right: 17px;
  }
  &__ctoDiv {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
  }
  &__headerImg {
    float: right !important;
    height: 35px !important;
    width: 35px !important;
  }
  &__headerText {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #0f0f60;
  }
  &__normalTextBoldFirst {
    font-size: 12px !important;
    font-weight: bold;
    line-height: 15px !important;
    margin-top: 12px;
    color: #a7b3d9 !important;
  }
  &__normalTextBold {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 15px !important;
    color: #a7b3d9 !important;
    margin-top: 10px;
  }
  &__normalText {
    font-size: 12px !important;
    line-height: 15px !important;
    color: #0f0f60 !important;
  }
  &__normalTextLorem {
    font-size: 12px !important;
    line-height: 15px !important;
    width: 400px;
    height: 90px;
    color: #0f0f60 !important;
  }
  &__iconEdit {
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
  }
  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__content {
    min-height: "100%";
    height: "100%";
    background-color: #ffffff;
    overflow-y: scroll;
  }
  &__rightNav {
    background-color: #0f0f60;
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }

  &__menu {
    background: #100f5c;
  }
  &__divider {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
}
