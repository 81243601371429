.changePassword {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  &__headerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    height: 60px !important;
    padding-left: 24px;
    padding-right: 16px;
    border-bottom: 1px solid #A7B3D9 !important;
  }
  &__header {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    min-height: 331px !important;
  }

  &__headerMobile {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    min-height: 331px !important;
    border: 1px solid #A7B3D9 !important;
    margin: 20px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 8px !important;
    background-color: white;
    min-height: 210px !important;
    padding-bottom: 20px;
  }
  &__ctoParentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding-right: 17px;
  }
  &__ctoDiv {
    display: flex;
    flex-direction: column;
  }
  &__headerImg {
    float: right !important;
    height: 35px !important;
    width: 35px !important;
  }
  &__headerText {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #0f0f60;
  }
  &__normalTextBoldFirst {
    font-size: 9px;
    margin-left: 23px;
    color: #a7b3d9 !important;
  }
  &__normalTextBold {
    font-size: 9px;
    margin-left: 23px;
    color: #a7b3d9 !important;
    margin-top: 6px;
  }
  &__normalText {
    font-size: 9px;
    margin-left: 23px;
    color: #0f0f60 !important;
  }
  &__iconEdit {
    width: 24px !important;
    height: 24px !important;
  }
  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__content {
    min-height: "100%";
    height: "100%";
    background-color: #ffffff;
    overflow-y: scroll;
  }
  &__rightNav {
    background-color: #0f0f60;
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }
  &__inputFieldsContainer {
    display: flex;
    flex-direction: column;
    width: 331px !important;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 20px;
  }
  &__inputField {
    display: flex;
    height: 32px;
    width: 329px !important;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;

    input{
      font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    }
  }

  &__menu {
    background: #100f5c;
  }
  &__buttonsDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &__buttonsDivMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &__saveChangesButtonMobile {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 40px !important;
    width: 190px;
    border-radius: 25px !important;
    background-color: #84d830 !important;
    color: white;
    border: 0px !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__cancelButtonMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 40px !important;
    width: 190px;
    margin-top: 16px;
    border-radius: 25px !important;
    background-color: white !important;
    color: #84d830 !important;
    border: 1px solid #84d830 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;;
  }

  &__saveChangesButton {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 130px;
    border-radius: 16px !important;
    background-color: #84d830 !important;
    color: white;
    border: 0px !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__cancelButton {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 110px;
    border-radius: 16px !important;
    background-color: white !important;
    color: #84d830 !important;
    margin-right: 10px;
    border: 1px solid #84d830 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;;
  }
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 8px;
  }

  .ant-btn-primary {
    border: 0px;
  }
}
