.verifyPhone {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  &__mainContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 400px;
    width: 400px;
  }

  &__header {
    display: flex;
    width: 400px;
    height: 60px;
    border-bottom: 1px solid #a7b3d9;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
  }
  &__headerTitle {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    color: #414c7b;
  }

  &__formDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px !important;
    padding-top: 40px !important;
  }

  &__inputField {
    min-width: 300px !important;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;

    input{
      font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    }
  }

  &__codeInputDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0px;
  }

  &__codeInputField {
    min-width: 300px !important;
    height: 32px;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;

    input{
      font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    }
  }

  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 0px;
  }

  .ant-btn-primary {
    border: 0px;
  }

  .ant-modal-content {
    border-radius: 8px !important;
  }

  &__linkButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    float: right;
    padding: 0px !important;
    border: 0px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    height: 13px !important;
    margin-top: 4px !important;
    margin-right: -240px !important;
  }

  &__buttonsContainer {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
    margin-top: 40px;
  }

  &__buttonsDiv {
    display: flex;
    justify-content: flex-end;
  }

  &__saveChangesButton {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 110px;
    border-radius: 16px !important;
    background-color: #56A5FF !important;
    color: white;
    border: 0px !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__cancelButton {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 110px;
    border-radius: 16px !important;
    background-color: white !important;
    color: #56A5FF !important;
    margin-right: 10px;
    border: 1px solid #56A5FF !important;
    font-size: 14px !important;
    line-height: 19px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;;
  }

}
