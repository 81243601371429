.avatar {
  display: flex;

  &__container {
    border-radius: 100%;
    position: relative;
  }
  &__img {
    border-radius: 100%;
    height: 34px;
    width: 34px;
  }
  &__onlineBadge {
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #84d830;
  }

  &__offlineBadge {
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #808080;
  }
}
