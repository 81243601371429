.ConnectorOverview {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);

  .ant-tabs-nav {
    border-bottom: none !important; // Removes the bottom border
  }
  &__tabsWrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff; // Ensure background is the same as your container
    margin-left: 13px !important;
    font-size: 15px !important;
    font-weight: bold !important;
  }

  .ConnectorOverview__tabsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__content {
    flex: 1;
    overflow: auto;
  }
  .th.ant-table-cell {
    font-weight: bold !important;
    border-color: gray !important;
    //background-color: #eff1fb !important;
  }
  th.ant-table-cell {
    background: #eff1fb !important;
    font-weight: bold !important;
  }
  &__headerText {
    margin: 0% 0% 0% 0%;
  }
  &__title {
    margin-top: 4px;
    margin-left: 1%;
    color: #a7b3d9;
  }
  &__titleSelected {
    margin-top: 4px;
    color: #001f3f; /* Change to the desired color */
    margin-left: 1%;
  }
  &__sourceText {
    margin: 3% 0% 2% 3%;
    font-size: 15px;
    font-weight: bold;
  }
  &__bar {
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
  }
  &__input {
    width: 230px !important;
    border-radius: 5px !important;
    margin-right: 1% !important;
  }
  &__parentButton {
    display: flex;
    justify-content: flex-end;
  }
  &__button {
    margin-bottom: 8px;
    justify-content: center;
    align-items: center !important;
    height: 34px !important;
    border-radius: 20px !important;
    background-color: #3972ce !important;
    color: white !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__tableDiv {
    margin: 0% 4% 0% 3%;
  }
  &__btnImage {
    margin-left: -28%;
  }

  &__details-button {
    cursor: pointer;
  }
  &__details-button,
  &__details-button:active,
  &__details-button:hover,
  &__details-button:focus {
    border: none !important;
    height: 30px !important;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center !important;
    border-radius: 8px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    float: left !important;
    margin-right: 0px !important;
    color: white !important;
    background-color: #98d650 !important;
  }
}
.statusFlex {
  display: flex;
}
.Status {
  width: 11px !important;
  height: 11px !important;
  background-color: #84d830;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%;
}
.InActiveStatus {
  width: 11px !important;
  height: 11px !important;
  background-color: #cccccc !important;
  border-radius: 50%;
  margin-top: 5%;
  margin-right: 7%;
}
.nameFlex {
  display: flex;
  justify-content: space-between;
}
.nameText {
  width: 75%;
}
.hover-pointer:hover {
  cursor: pointer;
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100% !important;
  height: 500px !important;
}
.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px !important;
  margin-left: auto;

  &__button {
    margin-right: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;

    &--search {
      background-color: #fff !important;
      border: 1px solid #e0e0e0 !important;
      width: 32px !important;
      height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-radius: 50% !important;
    }

    &--share {
      background-color: #f5f5f5 !important;
      border: 1px solid #e0e0e0 !important;
      border-radius: 20px !important;
      padding: 0 12px !important;
      height: 32px !important;

      .toolbar__text {
        color: #000000 !important;
        opacity: 1 !important;
        margin-left: 8px !important;
        margin-right: 4px !important;
        &:hover {
          color: #007bff !important;
          opacity: 0.8 !important;
        }
      }
    }

    &--add-files {
      background-color: #3872cd !important;
      border-radius: 20px !important;
      padding: 0 16px !important;
      height: 32px !important;
      color: white !important;
      border: none !important;

      .toolbar__text {
        margin-left: 4px !important;
        margin-right: 4px !important;
        margin-top: 2px !important;
        font-weight: bold;
      }
    }
  }
  &__input {
    margin-right: 8px !important;
    height: 32px !important;
    border: 1px solid #e0e0e0 !important;
    padding: 4px 12px !important;
    border-radius: 16px !important;
    margin-bottom: 16px !important;
    font-size: 14px !important;
  }
  &__separator {
    position: absolute;
    bottom: 0;
    width: 500px;
    height: 1px;
    background-color: #f5f0ec;
    margin-bottom: 16px;
  }
}

.csv-warning {
  .ant-alert {
    background-color: #fdf7f2;
    border: none;

    .ant-alert-icon {
      color: #a54f22;
    }

    .ant-alert-message {
      color: #7d4832;
      font-size: 14px;
      font-weight: bold;
    }

    .ant-alert-description {
      color: #7d4832;
      font-size: 12px;
    }
  }
}
