.processingSteps {
  height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    height: 60px;
    padding-left: 17px;
    padding-right: 24px;
    border-bottom: 1px solid #a7b3d9;

    &--hidden {
      display: none;
    }

    &__leftContent {
      width: 34px;
      padding-bottom: 4px;

      &__backBtn {
        cursor: pointer;
      }
    }
    &__middleContent {
      display: flex;
      flex: 1;

      &__h1Text {
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        color: #0f0f60 !important;
      }

      .ant-steps-item-icon {
        border: 3px solid #0f0f60 !important;

        .ant-steps-icon {
          font-family: Lato !important;
          font-size: 14px !important;
          font-weight: bold;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .ant-steps-item-title {
        color: #0f0f60 !important;
        font-size: 12px;
        font-weight: bold;
        &::after {
          background-color: #0f0f60 !important;
          height: 2px !important;
        }
      }
      .ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: #0f0f60 !important;
        }
        .ant-steps-icon {
          color: #ffffff !important ;
        }
      }
      .ant-steps-item-process {
        .ant-steps-item-icon {
          background-color: #0f0f60 !important;
          .ant-steps-icon {
            color: #ffffff !important;
          }
        }
      }
      .ant-steps-item-wait {
        .ant-steps-item-icon {
          background-color: #ffffff !important;
          .ant-steps-icon {
            color: #0f0f60 !important;
          }
        }
      }
    }

    &__rightContent {
      width: 116px;
      display: flex;
      justify-content: flex-end;

      &__uploadBtn {
        cursor: pointer;
      }
    }
  }

  &__content {
    height: calc(100% - 84px);
    padding-left: 24px;
    padding-right: 24px;

    &--fullWidth {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
