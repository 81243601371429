.addSourceMainContainer {
  height: 100%;
}
.sourceCreateOrAdd {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  margin-bottom: 12px;

  &__button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #84d830 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white;
    border-radius: 15px !important;
    margin: 0 !important;
    height: 32px !important;
    border: none !important;
    outline: none !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__para {
    font-weight: bold;
    font-size: 14px;
    margin-left: 8px;
    color: #07074b;
    margin-bottom: 0px;
  }
}
.addSource {
  height: calc(100% - 44px);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 17px;
    margin-bottom: 12px;
    padding-left: 24px;

    &__source {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
    }
    &__status {
      width: 82px;
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__list {
    height: calc(100% - 30px);
    overflow-y: scroll;

    &__row {
      background-color: #eff2fc;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #a7b3d9;

      &--roundedTop {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &--roundedBottom {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-bottom: none;
      }

      &__image {
        width: 54px !important;
        height: 38px !important;
      }

      &__leftContent {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        flex: 1;

        &__sourceName {
          font-size: 14px;
          font-weight: bold;
          color: #5c99df;
          margin-bottom: 0px;
        }
        &__instructions {
          font-size: 12px;
          color: #0f0f60;
          margin-bottom: 0px;

          &__link {
            color: #56a5ff;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &__tags {
          margin-top: 2px;
          &--title {
            font-size: 12px;
            font-weight: bold;
            color: #5c99df;
            margin-right: 8px;
          }
          &--value {
            display: inline-flex;
            background-color: #56a5ff;
            color: #fff;
            font-size: 12px;
            line-height: 23px;
            margin-right: 12px;
            border-radius: 12px;
            padding: 0px 16px;
            text-align: center;
          }
        }
      }

      &__rightContent {
        width: 82px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__match {
          font-size: 12px;
          font-weight: bold;
          color: #0f0f60;
        }
        &__matched {
          font-size: 12px;
          font-weight: bold;
          color: #84d830;
        }
      }
    }
  }
}
