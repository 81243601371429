.idValue {
  text-decoration: underline;
  color: #0f0f60;
}

.nameValue {
  color: #0f0f60;
}
.idHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__tooltip {
    margin-left: 4px;
    width: 16px !important;
  }
}
