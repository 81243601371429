.systemFields {
  &__heading {
    color: #0f0f60;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  &__subHeading {
    color: #0f0f60;
    font-size: 12px;
    margin-bottom: 12px;
    max-width: 733px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    height: 55px;
    border-bottom: 1px solid #a7b3d9;

    &--header {
      background-color: #eff2fc;
      border-bottom: none;
      border-radius: 8px;
    }

    &__left {
      display: flex;
      flex: 1;
      height: 54px;
      justify-content: flex-start;
      align-items: center;
      color: #0f0f60;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 0px;

      &__columnWithTooltip {
        display: flex;
        flex: 1;
        height: 54px;
        justify-content: flex-start;
        align-items: center;
        color: #0f0f60;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 0px;

        &__columnName {
          color: #0f0f60;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 0px;
          margin-right: 24px;
        }
      }
    }
    &__right {
      display: flex;
      flex: 1;
      height: 54px;
      justify-content: flex-end;
      align-items: center;
      color: #0f0f60;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 0px;

      &__label {
        min-width: 178px;
      }
    }
  }
  form {
    max-height: calc(100% - 101px);
  }

  &__list {
    max-height: 280px !important;
    overflow-y: scroll !important;

    &__optionalFieldHeader {
      color: #0f0f60;
      background-color: #eff2fc;
      font-size: 14px;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 12px;
      margin-left: 12px;
      width: fit-content;
      padding: 8px;
      border-radius: 9px;
    }
  }

  &__footer {
    height: 36px;
    margin-top: 24px !important;

    &__error {
      color: #ff0000;
      font-size: 12px;
      line-height: 15px;
    }

    &__continueButton,
    &__continueButton:active,
    &__continueButton:hover,
    &__continueButton:focus {
      width: 120px !important;
      border: none !important;
      height: 30px !important;
      text-align: center !important;
      border-radius: 15px !important;
      margin-bottom: 8px !important;
      font-size: 12px !important;
      font-weight: bold !important;
      float: right !important;
      margin-right: 0px !important;
      color: white !important;
      background-color: #98d650 !important;
    }
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .ant-select-selector {
    border: 1px solid #0f0f5c !important;
    width: 178px !important;
    border-radius: 0px !important;
    background-color: white !important;
    color: #0f0f60 !important;
    font-size: 13px !important;
    font-weight: bold !important;
    .ant-select-selection-item {
      font-size: 13px !important;
      font-weight: bold !important;
    }
  }
  .ant-select-auto-complete {
    width: unset !important;
    .ant-select-selection-search {
      input {
        font-size: 13px !important;
        font-weight: bold !important;
      }
    }
  }
}
