.desktop_steps {
  width: 90% !important;
  margin-top: 10px !important;

  &__scrollable {
    max-height: 82vh; // Adjust the height as needed
    overflow-y: auto;
  }

  &__allBtn {
    width: 85px !important;
    height: 20px;
    color: #6aa3f8;
    font-size: 12px;
    border: 1px solid #e2e2e2 !important;
    margin-left: 5px !important;
    text-align: center !important;
    margin-top: 20px !important;
    margin-left: 18px !important;
    border-radius: 16px !important;
    background-color: #f2f2f2 !important;
  }
}

.ant-collapse-content-active {
  border-radius: 16px !important;
  background-color: #eff2fc !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: -32px !important;
  z-index: -999px !important;
}

.ant-card-bordered {
  border-radius: 8px;
  padding: 0px;
  margin-top: 16px;
}

.ant-card-body {
  padding: 8px !important;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: 0px !important;
  margin-top: -2px !important;
}

.ant-collapse {
  border: 0px !important;
  background-color: white !important;
}

.custom-drawer .ant-drawer-content {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.custom-drawer .ant-drawer-wrapper-body {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  overflow: hidden;
}

.custom-menu {
  width: 150% !important; // Set the width of the menu
  border-bottom: none;


  .ant-menu-item {
    color: #394399 !important;
    font-family: "Lato", sans-serif; // Font family for menu items
    font-weight: bold; // Font weight for menu items
    font-size: 14px !important; // Font size for menu items
    padding: 0 16px; // Padding for menu items
    margin-right: 100px !important; // Add gap between menu items

    &:hover {
      // Optional: Add hover styles if needed
    }
  }

  // Ensure only one border for the selected menu item
  // .ant-menu-item.ant-menu-item-selected {
  //   color: #394399 !important; // Text color for selected menu item
  //   border-bottom: 2px solid #394399 !important; // Bottom border for selected menu item
  // }

  // Remove extra styling from submenu if necessary
  .ant-menu-submenu-selected::before {
    content: none; // Remove any extra lines created by ::before
  }

  .ant-menu-horizontal {
    border-bottom: none;
    display: flex;
    justify-content: center; // Center the menu items
  }
}


.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  color: #394399 !important; // Correct color value syntax
  font-size: 15px !important;
  font-weight: bolder !important;
}

// Change color when collapse item is active (expanded)

.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow svg {
  color: white !important; // Correct color value syntax
  font-size: 15px !important;
  font-weight: bolder !important;
}


.custom-card-buttons {
  display: flex;

  // justify-content: space-between;

  margin-top: 8px;

  .custom-button {
    background-color: #d3d3d3;
    border-color: #f2f2f2;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: bold;
    margin-right: 15px !important;
    border-radius: 26px !important;
    transition: transform 0.2s;
  }

  .custom-button:hover {
    background-color: #d3d3d3;
    border-color: #f2f2f2;
    color: #000000 !important;
    margin-right: 15px !important;
    border-radius: 26px !important;
    transform: scale(1.05);
  }

  .ant-spin-dot-item {
    background-color: #3872cd !important;
    /* Set the desired color */
  }

  .center-spin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .center-spin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    /* Adjust height as needed */
  }

  .no-data-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Adjust height as needed */
    padding: 10px;
    /* Spacing around the message */
    text-align: center;
  }

  .no-data-message p {
    color: #c2c3cc;
    /* Medium grey text color */
    font-size: 14px;
    /* Adjust font size as needed */
    margin: 0;
  }

  .AlysonGreetingCard {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: white;
    z-index: 1000;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(100%);
    opacity: 0;
  }

  .AlysonGreetingCard.show {
    transform: translateY(0);
    opacity: 1;
  }

}