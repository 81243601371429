.searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  width: 40% !important;
  padding-right: 24px;
  margin-left: auto; 
  padding: 3px 24px;
  margin-bottom: 16px;

  &__field {
    display: flex;
    width: 100px !important; // Keep your existing styles
    flex: 1;
    align-items: center;
    border: 1px solid #B5B5B5;
    border-radius: 20px !important;
    height: 36px !important;
    background-color: #f2f2f2 !important;

    &--focused {
      border: 1px solid #41A9FF;
      // background-color: #eff2fc !important;
    }

    &__searchIcon {
      margin-left: 12px;
      color: #B5B5B5 !important;
    }
    &__addonAfter {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 28px;
      margin-right: 8px;
      cursor: pointer;

      &__allFilters {
        color: #B5B5B5;
        font-size: 12px;
        font-weight: bold;

        &--focused {
          color: #000000;
        }
      }
      &__filterIcon {
        margin-right: 8px !important;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .ant-input-group-addon {
      padding: 0px !important;
      border: none !important;
      background-color: transparent !important;
    }
    .ant-input,
    .ant-input:hover,
    .ant-input:active,
    .ant-input:focus {
      color: #000000 !important;
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-input::placeholder {
      color: #B5B5B5 !important;
    }
  }
}
