.selectEntityType {
  height: 100%;
  padding: 16px;

  &__divider {
    margin: 8px !important;
  }
  &__heading {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      color: #8b8b8b;
      font-size: 14px;
      margin-top: 16px;
      margin-left: 24px;
      font-weight: bold;
    }
  }
  &__list {
    margin-top: 20px;
  }

  &__list__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px; // Space between items
    margin-left: 8px !important;
    padding-left: 8px !important;

    .selectEntityType__list__item__selected {
      background-color: #dee2e6;
      border-radius: 24px;
    }
    &__title {
      color: #000000 !important;
      font-family: "Lato", sans-serif !important;
      font-weight: bold !important;
      font-size: 16px !important;
    }

    &__selected {
      background-color: #aac1d8;
      border-radius: 8px;
    }
  }

  &__scrollableContent {
    height: calc(100% - 120px);
    overflow-y: scroll;
  }
}

// .ant-divider-horizontal {
//   margin: 0px !important;
// }
