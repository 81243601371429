.newHeader {
  display: flex;
  align-items: center;
  margin: 0px 6px 0px 8px !important;
  background-color: white;

  &__text {
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 10px;
    font-size: 15px;
    background-color: white;
    font-weight: bold;
    min-height: 60px;
    max-height: 60px;
    margin-bottom: 0;
    font-family: "Lato", sans-serif;
  }
  &__icon {
    width: 40px;
    height: 40px;
    background: var(--bg-icons-shade) 0% 0% no-repeat padding-box;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
}
.newHeader__icon.clickable {
  cursor: pointer; /* Changes the cursor to pointer for clickable icons */
}
