.BuildList {
  width: 100% !important;
  display: flex;
  flex-direction: column;

  &-customCardContent {
    border-radius: 8px !important;
    background-color: #56a5ff !important;
  }
  &-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    margin-top: 12px !important;
    border-radius: 8px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    min-height: 80px !important;
  }

  &-cardContent {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
  }

  &-radio .ant-radio-inner {
    margin-left: 16px !important;
    border-color: #56a5ff !important;

    &::after {
      background-color: #56a5ff !important;
    }
  }

  &-image {
    margin-left: 8px !important;
  }

  &__customPanelHeader {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    padding: 0px !important;
    cursor: pointer !important;
    margin-right: 8px !important;

    &-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2px;
    }

    &-image {
      // margin-right: 8px;
      width: 24px !important;
      height: 24px !important;
    }

    &-sideImage {
      height: 24px !important;
      width: 24px !important;
      margin-top: 0px !important;
      margin-right: 24px !important;
      margin-left: 110px !important;
    }

    &-headingContainer {
      display: flex;
      flex-direction: column;
      margin-left: 8px !important;
    }

    &-mainHeading {
      font-family: Lato;
      font-weight: bold;
      font-size: 12pt;
      text-align: left;
      margin: 0;
    }

    &-subHeading {
      font-family: Lato;
      font-weight: normal;
      font-size: 10pt;
      text-align: left;
      margin-top: 8px !important;
      margin: 0 0 0 -30px;
    }
  }

  .ant-collapse-header {
    padding-right: 32px !important;
  }

  .ant-collapse-arrow {
    display: none !important; // Hide the expand icon
  }

  .ant-drawer-body {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    overflow: hidden !important;
  }

  .ant-drawer-wrapper-body {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    overflow: hidden !important;
  }

  .ant-drawer-content {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    overflow: hidden !important;
  }

  .ant-drawer-content-wrapper {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    overflow: hidden !important;
  }
}
.ant-card-body {
  border-radius: 0px !important;
}
