.categoryPlaybooks {
  &__list {
    margin-left: 16px;
    margin-right: 16px;
    width: 100% !important;
    background-color: white;

    .ant-list-items {
      display: flex !important;
      flex-wrap: wrap !important;
    }
    .ant-list-items .categoryPlaybooks__datas {
      width: 33% !important;
    }
  }

  &__header {
    background-color: white !important;
    &__header {
      width: 100%;
      // ########################
      background-color: white;
      padding-top: 4px;
      // ########################
    }

    &__text {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
      margin-top: -15px !important;
      margin-left: 16px !important;
      margin-bottom: 1px !important;
      display: flex;
      justify-content: space-between;
      // ##########################
      background-color: #eff2fc;
      margin-right: 16px;
      padding: 8px;
      // ##########################
    }
    &__divider {
      height: 1px !important;
      background-color: #a7b3d9 !important;
      width: calc(100%) !important;
      margin: 8px 0px 0px 0px !important;
    }
  }
}

@media (max-width: 650px) {
  .categoryPlaybooks {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .categoryPlaybooks__datas {
        width: 100% !important;
      }
    }

    &__filterAndResults {
      &__filters {
        position: absolute;
        z-index: 99;
        width: 100%;
        box-shadow: 0px 0px 10px grey !important;
        border-right: 0px solid #eff2fc !important;
        border-top-right-radius: 24px !important;
        border-top-left-radius: 24px !important;
      }
    }
  }
}
