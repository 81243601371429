.SecondPlusColumn {
  &__mainContainer {
    background-color: white;
  }

  &__selectedMainContainer {
    background-color: white;
    pointer-events: none;
    border-top: 2px solid #a7b3d9 !important;
    padding: 0px !important;
  }

  &__heading2 {
    margin-top: 16px !important;
    color: #0f0f60;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 4px !important;
    font-weight: bold;
  }

  &__price {
    color: #0f0f60;
    font-size: 12px;
    cursor: pointer;
    margin-top: 0px;
    font-weight: bold;
  }
  &__description {
    width: 100%;
    color: #a7b3d9;
    overflow: scroll;
    height: 24px !important;
    cursor: pointer;
    font-size: 12px !important;
  }

  td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  &__selectedColumn {
    // border: 1px solid #0f0f60 !important;
    padding: 0px !important;
    border-left: 2px solid #a7b3d9 !important;
    border-right: 2px solid #a7b3d9 !important;
  }

  &__unSelectedColumn {
    padding: 0px !important;
  }

  &__selectedButton {
    align-items: flex-center;
    border: none !important;
    width: 70px !important;
    height: 30px !important;
    pointer-events: none;
    text-align: center !important;
    border-radius: 15px !important;
    margin: 16px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    color: white !important;
    background-color: #84d830 !important;
  }

  &__selectedButtonBG {
    border-bottom: 2px solid #a7b3d9 !important;
  }

  &__unselectedButton {
    align-items: flex-center;
    border: 1px solid #84d830 !important;
    height: 30px !important;
    width: 70px !important;
    text-align: center !important;
    border-radius: 15px !important;
    margin: 16px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    color: #84d830 !important;
    background-color: white !important;
  }
}
