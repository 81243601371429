.CategoriesPreview {
  margin-left: -17px;
  &__list {
    margin-left: 16px;
    margin-right: 16px;
    width: 100% !important;
    background-color: white;

    .ant-list-items {
      display: flex !important;
      flex-wrap: wrap !important;
    }
    .ant-list-items .CategoriesPreview__datas {
      width: 33% !important;
    }
  }
  &__textViewAll {
    color: #07074b;
    font-size: 14px !important;
    margin-right: 6px !important;
    margin-bottom: 0px !important;
    cursor: pointer;
  }

  &__header {
    background-color: white !important;
    &__header {
      width: 100%;
      // position: sticky;
      // top: 60px;
      // z-index: 9;
      padding-top: 1px;
      background-color: white;
    }

    &__text {
      color: #0f0f60;
      font-size: 14px;
      font-weight: bold;
      margin-top: 8px !important;
      margin-left: 16px !important;
      margin-bottom: 0px !important;
      display: flex;
      justify-content: space-between;
      border: 1px solid #eff1fb;
      background-color: #eff1fb;
      margin-right: 16px;
      padding: 8px 0px 8px 8px;
    }
    &__divider {
      height: 1px !important;
      background-color: #a7b3d9 !important;
      width: calc(100%) !important;
      margin: 8px 0px 0px 0px !important;
    }
  }
  &__Create {
    position: absolute !important;
    right: 15% !important;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px !important;
    color: white !important;
    border: none !important;
  }
}

.CategoriesPreviewViewbutton {
  // margin-left: 22%;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  background-color: white;
  &__DesktopButton {
    border: none !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__DesktopButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__DesktopButton:hover {
    color: white !important;
  }
  &__MobileButton {
    border: none !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding: 6px 10px 6px 10px !important;
    background-color: #e2e3e3 !important;
  }
  &__MobileButton:focus {
    background-color: #737373 !important;
    color: white !important;
  }
  &__MobileButton:hover {
    color: white !important;
  }
}

@media (max-width: 650px) {
  .CategoriesPreview {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
      }
      .ant-list-items .CategoriesPreview__datas {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 1550px) {
  .CategoriesPreviewMobile {
    &__list {
      margin-left: 16px;
      margin-right: 16px;
      width: 100% !important;
      background-color: white;

      .ant-list-items {
        display: flex !important;
        flex-wrap: wrap !important;
        border: 1px solid #dcdee3;
        border-radius: 10px;
        margin: 0px 32%;
        padding: 2% 0px 0% 0px;
      }
      .ant-list-items .CategoriesPreview__datas {
        width: 100% !important;
      }
    }
  }
}

.CategoriesPreviewMobile {
  &__Create {
    position: absolute !important;
    right: 15% !important;
    background-color: #56a5ff !important;
    border-radius: 20px !important;
    width: 120px !important;
    color: white !important;
    border: none !important;
  }
}
