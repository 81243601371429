.userManagement {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  &__mainContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 338px;
  }

  &__mainContainerMobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }

  &__triger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  &__headerDiv {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    height: 60px !important;
    padding-left: 16px;
    padding-right: 17px;
    border-bottom: 1px solid #A7B3D9 !important;
  }

  &__headerDivMobileContainer {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    border-bottom: 1px solid #A7B3D9 !important;
  }

  &__headerDivMobile {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    height: 100px !important;
    padding-left: 16px;
    padding-right: 17px;
    
  }

  &__headerFreeInvitesDiv {
    display: flex;
    flex-direction: column;
    padding-right: 34px;
    height: 60px;
  }

  &__headerFreeInvitesDivMobile {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-right: 15px;
    margin-bottom: 4px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    background-color: white;
    width: 100% !important;
    height: 144px !important;
  }

  &__headerMobile {
    display: flex;
    flex-direction: column;
    min-height: 370px;
    border-radius: 8px !important;
    background-color: white;
    border: 1px solid #A7B3D9 !important;
    margin: 20px 20px 0px 20px;
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    border-radius: 8px !important;
    background-color: white;
    min-height: 186px !important;
    padding-left: 15px;
    
  }

  &__footerContainerMobile{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__footerMobile {
    display: flex;
    border: 1px solid #A7B3D9 !important;
    margin: 20px;
    border-radius: 8px !important;
    background-color: white;
    min-height: 229px !important;
    padding-left: 15px;
    
  }

  &__inputForm {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__inputFormMobile {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__inputFormItem {
    //display: flex;
    width: 100%;
    margin-right: 15px !important;
  }
  &__inputFormItemMobile {
    width: 100%;
  }
  &__inviteBtnContainer{
    display: flex;
    justify-content: center;
  }
  &__invitesList {
    display: flex;
    background-color: #eff2fc !important;
    max-height: 100% !important;
    margin-right: 17px !important;
    margin-top: 16px !important;
    overflow-y: scroll !important;
    .ant-spin-nested-loading {
      width: 100% !important;
      min-height: 76px !important;
    }
    .ant-list-empty-text{
      display: flex;
      justify-content: flex-start;
      align-self: flex-start;
      padding-left: 8px;
      padding-top: 8px;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
  &__listItem {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    width: 100% !important;
    height: 38px !important;
    border-bottom: 1px solid #a7b3d9 !important;
  }
  &__listItemMobile {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    width: 100% !important;
    height: 50px !important;
    border-bottom: 1px solid #a7b3d9 !important;
  }
  &__listItemTitlesDiv {
    display: flex;
    flex-direction: column;
  justify-content: center;  
}

&__listItemInviteStatusDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
}

  &__listItemIcon {
    margin-left: 22px;
    margin-right: 17px;
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
  }

  &__listItemIconEdit {
    margin-right: 17px;
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
  }

  &__listItemIconClose {
    margin-right: 12px;
    height: 14px !important;
    width: 14px !important;
    padding: 2px;
    cursor: pointer;
  }
  &__listItemIconCloseDisabled {
    margin-right: 12px;
    height: 14px !important;
    width: 14px !important;
    border: 0px !important;
    background: transparent;
    background-color: transparent;
  }
  &__listIconNamesDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__featuresList {
    display: flex;
    margin-right: 40px !important;
    margin-top: 16px !important;
    overflow-y: scroll !important;
    width: 98%;
  }
  &__featuresListMobile {
    display: flex;
    margin-right: 40px !important;
    overflow-y: scroll !important;
    width: 98%;
    min-height: 120px;
  }
  &__featuresListItem {
    display: flex;
    justify-content: flex-start !important;
    flex-direction: row;
    width: 100% !important;
    height: 15px !important;
    border-color: white !important;
  }
  &__featuresListItemIcon {
    height: 6px !important;
    width: 6px !important;
    margin-right: 10px;
    background-color: #56a5ff;
    border-radius: 3px !important;
  }
  &__ctoParentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding-right: 17px;
  }
  &__workspaceMembersParentDivLeft {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  &__workspaceMembersTitlesDiv {
    display: flex;
    flex-direction: row;
  }
  &__workspaceMembersParentDivRight {
    display: flex;
    flex-direction: column;
    padding-left: 16px !important;
    border-left: 1px solid #A7B3D9;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 364px !important;
  }

  &__workspaceMembersParentDivRightMobile {
    display: flex;
    flex-direction: column;
    padding-left: 36px !important;
    margin-bottom: 16px;
    width: 364px !important;
  }

  &__headerRightDiv {
    display: flex;
    float: right !important;
    height: 60px !important;
    align-items: center;
    justify-content: center;
  }
  &__headerImg {
    display: flex;
    align-self: center;
    height: 35px !important;
    width: 35px !important;
  }
  &__headerText {
    font-size: 14px !important;
    line-height: 17px !important;
    margin-top: 19px;
    font-weight: bold !important;
    color: #0f0f60 !important;
  }
  &__headerTextListItem {
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 4px;
    font-weight: bold !important;
    color: #0f0f60 !important;
  }

  &__headerTextRegular12 {
    font-size: 12px !important;
    color: #a7b3d9 !important;
    margin-top: 6px;
  }
  &__headerTextRegularMarginTop18 {
    font-size: 12px !important;
    color: #A7B3D9 !important;
    line-height: 15px !important;
    margin-top: 18px;
    margin-right: 40px;
  }
  &__headerTextBold12 {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 15px !important;
    color: #a7b3d9 !important;
    margin-top: 15px;
  }
  &__headerTextBold12Mobile {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 15px !important;
    color: #a7b3d9 !important;
  }
  &__headerTextBoldFeaturesList {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 15px !important;
    color: #a7b3d9 !important;
  }
  &__headerTextMedium10 {
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: 700 !important;
    margin-top: 8px;
    color: #0f0f60 !important;
  }
  &__headerTextMedium {
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: 700 !important;
    color: #0f0f60 !important;
  }
  &__headerTextRegular12 {
    font-size: 12px !important;
    line-height: 15px;
    margin-top: 2px;
    color: #a7b3d9 !important;
  }

  &__headerTextRegular10Mobile {
    font-size: 10px !important;
    line-height: 12px;
    margin-top: 2px;
    color: #a7b3d9 !important;
  }
  &__headerTextRegular {
    font-size: 12px !important;
    line-height: 15px !important;
    margin-top: 8px;
    margin-left: 8px;
    color: #a7b3d9 !important;
  }
  &__headerTextRegularNormal {
    font-size: 12px !important;
    line-height: 15px !important;
    margin-right: 3px;
    color: #a7b3d9 !important;
  }
  &__headerTextRegularNormalOtherStatus {
    font-size: 12px !important;
    line-height: 15px !important;
    margin-right: 29px;
    color: #a7b3d9 !important;
  }
  &__normalTextBoldFirst {
    font-size: 9px;
    margin-left: 23px;
    color: #a7b3d9 !important;
  }
  &__normalTextBold {
    font-size: 9px;
    margin-left: 23px;
    color: #a7b3d9 !important;
    margin-top: 6px;
  }
  &__normalText {
    font-size: 9px;
    margin-left: 23px;
    color: #0f0f60 !important;
  }
  &__iconEdit {
    width: 24px !important;
    height: 24px !important;
  }
  &__button {
    float: right;
  }

  &__siteLayout {
    background: #fff;
  }

  &__content {
    min-height: "100%";
    height: "100%";
    background-color: #ffffff;
    overflow-y: scroll;
  }
  &__rightNav {
    background-color: #0f0f60;
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }
  &__inputFieldsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    height: 84px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
  }
  &__inputFieldsContainerMobile {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 18px;
    margin-bottom: 14px;
  }
  &__inputField {
    display: flex;
    height: 33px;
    width: 100% !important;
    margin-left: 16px;
    border: 1px solid #A7B3D9 !important;
    border-top-left-radius: 17px !important;
    border-bottom-left-radius: 17px !important;
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 5px !important;

    input{
    font-size: 12px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    margin-left: 20px !important;
    }
  }

  &__inputFieldMobile {
    display: flex;
    height: 40px;
    width: 100% !important;
    border: 1px solid #A7B3D9 !important;
    border-radius: 25px !important;
    font-size: 16px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    padding-left: 24px !important;
    padding-right: 24px !important;

    input{
      font-size: 16px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    color: #0f0f60 !important;
    margin-left: 0px !important;
    }
  }

  &__selectRole {
    
    .ant-select-selector {
      border: 1px solid #a7b3d9 !important;
      width: 139px !important;
      height: 33.2px !important;
      border-radius: 0px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
    }
    
  }

  &__selectRoleMobile {
    
    .ant-select-selector {
      flex-direction: row;
      align-items: center;
      border: 1px solid #a7b3d9 !important;
      width: 100% !important;
      height: 40px !important;
      border-radius: 25px !important;
      font-size: 16px !important;
      font-weight: 500 !important;
    }
    
  }
  &__divider {
    display: flex;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  &__menu {
    background: #100f5c;
  }
  &__buttonsDiv {
    display: flex;
    justify-content: flex-end;
  }
  &__saveChangesButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    height: 32px !important;
    width: 110px;
    border-radius: 16px !important;
    background-color: #84d830 !important;
    color: white;
    font-size: 12px !important;
  }
  &__inviteButton {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    float: right;
    height: 33px !important;
    width: 104px;
    border-top-right-radius: 17px !important;
    border-bottom-right-radius: 17px !important;
    margin-left: -1px;
    background-color: #EFF2FC !important;
    color: #56A5FF !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: 1px solid #a7b3d9 !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__inviteButtonEnabled {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    float: right;
    height: 33px !important;
    width: 104px;
    border-top-right-radius: 17px !important;
    border-bottom-right-radius: 17px !important;
    margin-left: -1px;
    background-color: #56A5FF !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }

  &__inviteButtonMobile {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    width: 191px;
    border-radius: 25px !important;
    background-color: #EFF2FC !important;
    color: #56A5FF !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: 1px solid #a7b3d9 !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }
  &__inviteButtonEnabledMobile {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    width: 191px;
    border-radius: 25px !important;
    background-color: #56A5FF !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 17px !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 0%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 0%) !important;
  }

  &__textButton {
    display: flex;
    color: #56a5ff !important;
    height: 12px;
    font-size: 10px !important;
    align-self: flex-end;
    margin-top: 17px;
    font-size: 12px !important;
    font-weight: bold;
    line-height: 15px !important;
  }
  &__textButtonMobile {
    display: flex;
    color: #56a5ff !important;
    min-height: 52px;
    font-size: 10px !important;
    align-self: flex-end;
    margin-bottom: 28px;
    font-size: 12px !important;
    font-weight: bold;
    line-height: 15px !important;
  }
  
  .ant-form-item-label > label {
    color: #0f0f60 !important;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 0px;
  }

  .ant-btn-primary {
    border: 0px;
  }
}
