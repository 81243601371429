.inprogressUploads {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-height: 100%;
  z-index: 1000;
  min-width: 285px;
  width: 285px;
  max-width: 285px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #0f0f60;
    padding-right: 12px;
    min-width: 285px;
    width: 285px;
    max-width: 285px;

    &__title {
      color: white;
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
      margin-bottom: 0px;
      display: flex;
      flex: 1;
      padding-left: 12px;
      height: 100%;
    }
    &__arrowIcon,
    &__closeIcon {
      color: white !important;
      &:focus,
      &:hover,
      &:active {
        color: white !important;
      }
    }
  }

  &__timeRemaining {
    background-color: #fff;
    height: 20px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid #f0f2fb;
    color: #0f0f60;
    font-size: 12px;
    line-height: 15px;
  }

  &__list {
    max-height: 280px;
    overflow-y: scroll;
    background-color: white;

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: 1px solid #f0f2fb;

      &__fileIcon {
        color: #56a5ff !important;
        font-size: 14px;
      }
      &__fileName {
        color: #0f0f60;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0px;
        width: 185px;
        margin-right: 8px;
        margin-left: 8px;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }
  }
}
